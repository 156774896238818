// Estilos por defecto que tiene la base de un selector.
.base-select {
    position: relative;
  
    .list-wrapper {
      top: 100%;
      left: 0;
      width: 100%;
      transition: opacity .3s, visibility .3s, transform .3s, max-height .3s;
      overflow-x: hidden;
      overflow-y: auto;
  
      &.relative {
        position: relative;
      }
  
      &.absolute {
        position: absolute;
      }
    }
  
    .item {
      width: 100%;
    }
  
    &.expanded>.list-wrapper {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
    }
  
    &.collapsed>.list-wrapper {
      opacity: 0;
      visibility: hidden;
      transform: scaleY(1);
      max-height: 0 !important;
    }
  }
  
  // Clase usada por el componente DefaultSelect que extiende del Componente BaseSelect.
  .default-select {
    .select-icon{
      font-size: pxToEm(22);
      transition: transform .3s;
    }
    .base-select{
      &.expanded .select-icon{
        transform: rotate(180deg);
      }
      &.collapsed .select-icon{
        transform: rotate(0deg);
      }
    }
    .list-wrapper {
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, .5);
      border-bottom-right-radius: .5rem;
      border-bottom-left-radius: .5rem;
      background-color: var(--background-color);
    }
  
    .default-list {
      list-style: none;
      padding: 0;
      margin: 0;
      background-color: var(--background-color);
  
      .default-item {
        padding: .5rem;
        color: var(--onbackground-primary);
        cursor: pointer;
  
        &.selected {
          background-color: var(--secondary-inverse);
          color: var(--onsecondary-inverse);
        }
  
        &:not(.selected):hover {
          color: var(--onsecondary-inverse);
        }
      }
  
      .label{
        margin: .5rem;
        font-weight: 800;
        text-transform: uppercase;
      }
      .separator{
        background-color: var(--onsecondary-inverse);
        width: 100%;
        height: 2px;
      }
    }
  }