/*=====================================================
  Individual
  En este archivo se encuentran los estilos del 
  home individual.

  > **NOTA:** Se deben revisar las clases de ese 
  archivo ya que pertenecen al HOME INDIVIDUAL y 
  este tiene una modificación de actualización
 ====================================================== */

@import "../abstracts/variables.scss";

.individual-header,
.individual-books {
  background-blend-mode: multiply;
}
.individual-header {
  background: #8cc63f url("/img/background.png") center/100% repeat-y;
  box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.35);
  position: relative;
  padding-top: 60px;

  $themes:(
    "alto-contraste":(
      background:var(--background)
    )
  );
  @include ThemesProperties($themes);
  
  
  &::after {
    background-color: #8cc63f;
    content: "";
    height: 30px;
    position: absolute;
    top: -10px;
    /* width: 100vw; */
    z-index: -10;
    @include ThemesProperties($themes);
  }
}

.list-footer p {
  font-weight: 700;
}

.dl-menuwrapper {
  visibility: hidden !important;
}
.individual-books {
  background: #f2d042 url("/img/background.png") 50% / cover fixed repeat-y;
  padding: 60px 0;
  $themes:(
    "alto-contraste":(
      background:var(--background),
      border-top:solid 2px var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);
  
}
.container-individual-books,
.individual-header-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
}

.individual-header-container {
  font-weight: 400;
  font-size: 2.5em;
  line-height: 1.3;
  color: #fff;
}

.individual-header-description,
.individual-book-des {
  font-family: $secondary-font;
}

.individual-header-description {
  padding: 50px;
  font-weight: 700;
  width: 50%;
  line-height: 1.2;
  $themes:(
    "alto-contraste":(
      color:var(--onbackground-primary),
    )
  );
  @include ThemesProperties($themes);
  span{
    @include ThemesProperties($themes);
  }
}

.individual-header-course {
  color: #000;
}

.individual-header-image {
  width: 30%;
  align-self: flex-end;
}

.individual-book {
  width: 50%;
  padding: 40px;
}

.individual-book-cover {
  position: relative;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}

.individual-book img {
  width: 100%;
  border-radius: 20px;
}

.individual-book-des {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  top: 0px;
  left: 0px;
  border-radius: 20px;
  padding: 40px;
  color: #4d4d4d;
  line-height: 1.4;
  $themes:(
    "alto-contraste":(
      color:var(--onbackground-primary),
      background-color:var(--background-color),
      border:solid 2px var(--onbackground-primary),
    )
  );
  @include ThemesProperties($themes);
}

.tag-genero {
  display: table;
  margin: auto;
  margin-right: 0;
  border-radius: 10px;
}

.tag-genero {
  background-color: #5b5bc2;
  color: #fff;
  font-size: 0.8em;
  margin-bottom: 20px;
  padding: 10px;
  $themes:(
    "alto-contraste":(
      color:var(--background-color),
      background-color:var(--onbackground-primary),
      border:solid 2px var(--onbackground-primary),
    )
  );
  @include ThemesProperties($themes);
}

/*///////////FICHA//////////////////////////*/
.clickArea {
  cursor: pointer;
}

.individual-book-desc {
  height: 85% !important;
}

.rotateficha {
  transform: rotateY(180deg);
}

.side-a,
.side-b {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.side-b {
  transform: rotateY(-180deg);
  z-index: 200;
}

.individual-book-title {
  font-family: $secondary-font;
  color: #000;
  font-size: 1.4em;
  text-align: center;
  margin-top: 20px;

  $themes:(
    "alto-contraste":(
      color:var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);
}

.book-information h3 {
  font-weight: 700;
  font-family: "Quicksand" !important;
}

.book-information p {
  text-transform: uppercase;
  font-size: 0.7em;
  font-family: "Quicksand" !important;
}

.book-description {
  margin-top: 20px;
  height: 44%;
}

.read-button {
  background-color: #f2d042;
  font-size: 1.5em;
  font-weight: 700;
  padding: 10px 25px;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.4s;
  $themes:(
    "alto-contraste":(
      color:var(--background-color),
      background-color:var(--onbackground-primary),
      border:solid 2px var(--onbackground-primary),
    )
  );
  @include ThemesProperties($themes);
}

.read-button:hover {
  background-color: #5b5bc2;
  color: #fff;
  transform: scale(1.008);
  $themes:(
    "alto-contraste":(
      color:var(--onbackground-primary),
      background-color:var(--background-color),
      border:solid 2px var(--onbackground-primary),
    )
  );
  @include ThemesProperties($themes);
}

@media screen and (max-width: 992px) {
  .individual-book {
    padding: 3%;
  }
  .book-description {
    height: 34%;
  }
  .individual-header-description {
    width: 90%;
    padding: 5%;
  }
  .individual-header-image {
    display: none;
  }
  .individual-book-des {
    padding: 10px 20px;
  }
}

.img-side-a{
  width: 100%;
  max-width: 280px;
  height: 460px;
}

@media screen and (max-width: 576px) {
  .individual-book {
    width: 100%;
    padding: 10%;
  }
  
  .individual-header-description {
    font-size: 0.8em;
  }
  .book-information {
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
}

@media screen and (max-width: 420px) {
  .individual-header-description {
    font-size: 0.6em;
  }
}
