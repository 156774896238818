/*=====================================================
                     BUSQUEDA
 ====================================================== */

.busqueda-container {
  position: relative;

  .container {
    min-height: 800px;
    background-color: var(--surface-color);
  }

  .main-content{
    padding-top: 0 !important;
  }

  .busqueda-demo{
    box-shadow: 0px -5px 10px 2px rgba(0, 0, 0, 0.4);
  }

  .layout{
    position: relative;
    display: grid;
    width: 100%;
    min-height: 100%;
    grid-template-columns: min-content 1fr; 
    grid-template-areas: "filtro seccion-libros";
  }

  .search-menu{
    padding-right: 1rem;
    overflow:hidden;
  }

  .filtro-container{
    background-color: var(--surface-color);
    grid-area: filtro;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    min-width: 240px;
    max-width: 250px;
    z-index: 200;
    margin-top: .5rem;

    .mobile-button{
      display: none;
      width: 100%;
      height: 50px;
      text-align: center;
      justify-content: space-between;
      align-items: center;
    }
    .menu{
      display: block;
      width: 100%;
      height: 100%;
      padding-top: 2rem;
      padding-left: .5rem;
      padding-right: .5rem;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .seccion-libros{
    grid-area: seccion-libros;
    display:flex;
    min-width:100% !important;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }

  @media screen and (max-width:800px){
    .layout{
    grid-template-columns:1fr; 
      grid-template-areas: 
      "filtro"
      "seccion-libros";
    }
    
    .filtro-container{
      width: 100%;
      max-width: 100%;
      height: 50px;
    box-shadow: 0 0 10px 5px rgba(0,0,0,.5);


      &.show{
        height: calc(100vh - 100px);
      }

      &.hide{
        height: 50px !important;
        overflow: hidden;
        .search-button{
          display: none;
        }
        .menu{
          display: none;
        }
      }
  
      .mobile-button{
        display: flex;
      }
    }

  }
}

.dropbtn{
  $themes:(
    "clasico":(
      background: #e0e0f5
    ),
    "alto-contraste":(
      background: var(--surface-color),
      color:var(--onbackground-primary),
      border: solid 2px var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);
  border-radius: 5px;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: .25rem;
  font-weight: bold;
}

.dropbtn,
.eliminarF {
  cursor: pointer;
  border: none;
  width: 100%;
  text-align: left;
  z-index: 99;

  i {
    float: right;
  }
}

.eliminarF {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  background: transparent;

}

.drop-content {
  transition: all 0.4s ease;
  height: 0;
  //opacity: 0;
  overflow: hidden;

  .dplay {
    display: none;
  }
}

.show {
  height: auto;
  opacity: 1;

  .dplay {
    display: block;
  }
}

.boton-filtro {
  z-index: 5000;
  .btn {
    width: 100%;
  }
}

.rotatebtn {
  h3 {
    i {
      transition: all 0.5s ease;
      transform: rotate(0deg);
    }
  }
}

.rotate {
  h3 {
    i {
      transition: all 0.5s ease;
      transform: rotate(180deg);
    }
  }
}

.search-item {
  position: relative;
  padding-left: 35px;
  padding-top: .25rem;
  padding-bottom: .25rem;
  border: none !important;
  color:var(--onbackground-primary);

  label{
    margin: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    height: 20px;
    width: 20px;
    cursor: pointer;
    z-index: 21;
    left: 0;
    top: 4px;
    margin-right: 20px;
  }

  .checkmark {
    position: absolute;
    left: 0;
    top: 50%;
    height: 20px;
    width: 20px;
    transform: translateY(-50%);

    z-index: 20;
    border-style: solid;
    border-width: 1.8px;

    border-radius: 4px;
    $themes:(
      "clasico":(
        background-color: var(--surface-color),
        border: solid 2px #000
      ),
      "alto-contraste":(
        background-color: var(--onbackground-primary),
        border: solid 2px var(--onbackground-primary)
      )
    );
    @include ThemesProperties($themes);
  }
}

.search-item:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.search-item input:checked ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.search-item input:checked ~ .checkmark:after {
  display: block;
}

.search-item .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transform: rotate(45deg);
  transform: rotate(42deg);
}

.container-search {
  height: 0;
  opacity: 0;
  display: none;
}

.search-movil {
  &:hover {
    background-color: var(--primary-color) !important;
    color: #000 !important;
  }

  .flecha {
    float: right;
  }
}

.drop {
  display: block;
  height: auto;
  opacity: 1;
}

.search-item--bar {
  border: 2px solid var(--secondary-inverse) !important;
  background-color: var(--surface-color) !important;
}

.search-group {
  padding: .2rem;
  ul {
    li {
      overflow: visible;
      cursor: pointer;
    }
  }
}

.divBusqueda {
  z-index: 100;
  padding: 0;
  background-color: var(--surface-color) !important;
}

.nav-fixed {
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: var(--surface-color);
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: var(--surface-color);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--secondary-inverse);
  }
}

.scrollBar {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: var(--surface-color);
  }

  &::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: var(--surface-color);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--secondary-inverse);
  }
}

/* .cajaprueba{
  position: relative;
  background-color: #6666cc;
  height: 1500px;
}

.cajaSticky{
  position: sticky;
  top: 10px;
  padding: 50px;
  background-color: #b0eb29;
} */

.skeleton-libro {
  left: 0;
  height: 100%;
}

@media only screen and (min-width: 992px) {
  .container-search {
    height: auto;
    opacity: 1;
    display: block;
  }

  .divBusqueda {
    position: relative;
  }
  .nav-fixed {
    height: 80vh;
    top: 120px;
    position: sticky !important;
  }

  .search-movil {
    display: none !important;
  }
}

.current-filter-removable-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 150px;
  padding: .25rem;
  padding-left: .5rem;
  padding-right: .5rem;
  border-radius: .5rem;
  text-overflow: ellipsis;
  white-space:  nowrap;
  margin: .25rem;
  overflow: hidden;
  justify-self: self-start;
  background-color: var(--secondary-light-inverse);

  >p {
    width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-grow: 1;
    white-space:  nowrap;
  }

  >button {
    justify-self: end;
    align-self: flex-end;
  }
}
