/*===========================================================================================
    Alto contraste
    En este archivo se modifican las variables de color. En caso de que la etiqueta body
    en su atributo data-theme tenga como valor "alto-contraste".
 ============================================================================================ */

body[data-theme="alto-contraste"] {
    --background-color: #000; // se utiliza principalmente para los fondos y algunas veces como bordes.
    --surface-color: #000; // se utiliza como superficie, está generalmente encima del background principal.
    --onbackground-primary: #ffff00; // se usa para textos y bordes
    --onbackground-secondary: #ffff00; // se usa para textos y bordes
    --onbackground-third: #ffff00; // se usa para textos
    --onbackground-fourth: #ffff00; // se utiliza para textos pero más que todo para bordes.
    --primary-color: #000;
    --onprimary-color: #ffff00;
    --primary-inverse: #ffff00;
    --onprimary-inverse: #000000;
    --secondary-color: #000;
    --onsecondary-color: #ffff00;
    --secondary-inverse: #ffff00;
    --onsecondary-inverse: #000000;
    --secondary-light-color: #000;
    --onsecondary-light-color: #ffff00;
    --secondary-light-inverse: #ffff00;
    --onsecondary-light-inverse: #000000;
    --third-color: #000;
    --onthird-color: #ffff00;
    --third-inverse: #ffff00;
    --onthird-inverse: #000000;
    --red-color: #000;
    --onred-color: #ffff00;
    --red-inverse: #ffff00;
    --onred-inverse: #000000;
    --green-color: #000;
    --ongreen-color: #ffff00;
    --green-inverse: #ffff00;
    --ongreen-inverse: #000000;
    --blue-color: #000;
    --onblue-color: #ffff00;
    --blue-inverse: #ffff00;
    --onblue-inverse: #000000;
    --disabled-color: #faf096;
    --ondisabled-color: #000;
    
    // variables del calendario
    --violet-color:#000;
    --onviolet-color:#ffff00;
    --violet-inverse:#ffff00;
    --onviolet-inverse:#000;
    --scarlet-color:#000;
    --onscarlet-color:#ffff00;
    --scarlet-inverse:#ffff00;
    --onscarlet-inverse:#000;

    //variables del calendario
    --calendario-primary-purple: #ffff00;
    --calendario-secondary-purple: #ffff00;
    --calendario-shadow-purple: #ffff00;
    --calendario-today-purple: #ffff00;
    --calendario-hover-purple: #ffff00;
    --calendario-deselect-purple: #ffff00;
}
