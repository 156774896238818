/*=====================================================
                      404
 ====================================================== */
.container-404 {
  background: var(--primary-color) url("/img/background.png") center/cover fixed repeat;
  background-blend-mode: multiply;
  height: 100%;
  font-weight: 700;
  .error-number {
    font-size: 10rem;
    letter-spacing: -10px;
    margin-left: -10px;
    line-height: 1;
    color: var(--onsecondary-color);
  }
  .error-description {
    font-size: 1.3em;
    line-height: 1.3;
    color: var(--onbackground-third);
  }
}

.personaje {
  margin-left: -100px;
}
