/**
*En este archivo se encuentran los estilos de la página de Blog y entrada del blog
*/

/*-------------------------------------------*\
Estilos de la página principal del blog
\*-------------------------------------------*/

.makeBlog {
  font-family: $secondary-font;

  .search-input {
    border: 2px solid var(--secondary-inverse) !important;
    padding: 18px 15px !important;
    border-radius: 25px;
  }

  .blog-contenido {
    background: url("/img/background.png") center/cover fixed repeat;

    .blog-container {
      background: var(--surface-color);
      padding: 2em;
      min-height: 400px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);

      $themes: (
        "clasico":(
          border: none
        ),
        "alto-contraste":(
          border: 1px solid var(--onsecondary-color)
        )
      );
      @include ThemesProperties($themes);

      .blog-input-wrapper {
        position: relative;

        .btn {
          position: absolute;
          top: 5px;
          left: 10px;
        }

        input {
          padding-left: 44px;
          font-family: var(--primary-font);
          font-size: 1em;
        }
      }

      .blog-select-wrapper {
        width: 100%;
        height: 100%;
        min-height: 41px;
        background: var(--surface-color);
        position: relative;

        select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          position: absolute;

          width: 100%;
          height: 100%;
          border-radius: 0 !important;
          border: 1px solid var(--secondary-inverse) !important;
          background: #bb3e3e00;
          color: var(--secondary-inverse);
          font-family: var(--primary-font), sans-serif;
          font-weight: 600;
          padding: 0 10px;
          font-size: 1em;

          &::-ms-expand {
            display: none !important;
          }

          option {
            font-weight: 600;
            font-family: var(--primary-font), 'Raleway', sans-serif;
            color: var(--secondary-inverse);
          }

        }

        i {
          position: absolute;
          top: 12px;
          right: 10px;
          color: var(--secondary-inverse);
        }

        &:hover {
          i {
            margin-top: 3px;
            transition: all 0.25s ease;
          }
        }

      }

      .btn-blog {
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        color: var(--onprimary-color);
        font-family: var(--primary-font);
        font-size: 1em;
        font-weight: 600;

        &:hover {
          background-color: var(--secondary-inverse);
          color: var(--onsecondary-inverse);
        }

        $themes: (
          border: 1px solid var(--onsecondary-color)
        );
        @include ThemesProperties($themes);
      }

      .btn-ver-entradas{
        background-color: var(--red-color);
        color: var(--onred-color);
        font-family: var(--primary-font);
        font-size: 1em;
        font-weight: 600;

        &:hover {
          background-color: var(--secondary-inverse);
          color: var(--onsecondary-inverse);
        }

        $themes: (
          border: 1px solid var(--onsecondary-color)  
        );
        @include ThemesProperties($themes);
      }

  .blog-swiper {
    .blog-swiper-slide {
      .blog-tag {
        width: 100%;
        background-color: var(--primary-color);
        color: var(--onprimary-color);
        font-family: var(--primary-font);
        font-weight: 500;
        border-radius: 100px;
        text-align: center;
        padding: 5px 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: .8em;
        cursor: pointer;

        &.activeMode {
          background-color: var(--secondary-inverse);
          color: var(--onsecondary-inverse);
        }

        &:hover {
          background-color: var(--secondary-inverse);
          color: var(--onsecondary-inverse);
        }

        $themes: (
          "alto-contraste":(
            border: 1px solid var(--onsecondary-color)
          )
        );
        @include ThemesProperties($themes);
      }
    }
  }

.blog-swiper-button-prev,
.blog-swiper-button-next {
  border-radius: 100%;
  width: 35px !important;
  height: 35px;
  color: var(--primary-color);
  background-color: var(--secondary-inverse);
  font-size: 1em;
  top: 26px !important;
}

.blog-card {
  height: 100%;
  background-color: var(--background-color);
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px #00000040;

  p {
    display: -webkit-box;
    display: -moz-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3 !important;
    text-overflow: ellipsis;

    @media (min-width: 768px) {
      -webkit-line-clamp: none !important;
      text-overflow: clip;
    }
  }

  .blog-card-img {
    width: 100%;
    height: 100%;
    border-radius: 20px 0 0 20px;
    overflow: hidden;

    .img {
      width: 100%;
      max-width: 200px;
    }

    @media (min-width: 768px) {
      max-width: auto;
      margin: 0;
     
    }
  }

  .blog-btn-card {
    width: 100%;
    border-radius: 5px;
    padding: 5px 0;
    font-weight: 700;
    background-color: var(--secondary-color);
    color: var(--onsecondary-color);
    transition: all .3s;

    $themes: (
      "alto-contraste":(border: 1px solid var(--onsecondary-color))
    );
  @include ThemesProperties($themes);

  &:hover {
    background-color: var(--primary-inverse);
    color: var(--onprimary-inverse);
  }

}
}
}

.newsletter-cta {
  h2 {
    font-size: 1.2em;
  }

  $themes: (
    "clasico": (background-color: #e3e3f9),
    "alto-contraste": (background-color: var(--background-color))
  );

@include ThemesProperties($themes);

.shape {
  background-image: url("/img/que-es/home/newsletter/newsletter-speaker.png");
  background-position: 0%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
  margin: auto;

  @media (min-width: 992px) {
    width: 465px;
    height: 332px;
  }

  @media (min-width: 1200px) {
    width: 555px;
    background-size: cover;
    background-position: 5% -10%;
  }

  @media (min-width: 1500px) {
    width: 670px;
    height: 266px;
    background-size: 75%;
    background-position: 5% 12%;
  }
}
}
}
}


.blog-entrada {
  a {
    display: block;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 10px;

    img {
      min-height: 401px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      $themes: (
        "clasico":(border: 1px solid aliceblue),
        "alto-contraste":(border: 1px solid var(--primary-inverse)),
      );

    @include ThemesProperties($themes);
  }

  &:hover {
    $themes: (
      "clasico":(color: var(--blue-color)),
      "alto-contraste":(color: var(--onblue-color)),
    );

  @include ThemesProperties($themes);

}
}

.btn-secondary {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
}

.date {
  font-size: 0.9em;
  color: var(--onbackground-secondary);
}
}

/*-------------------------------------------*\
Estilos de la entrada seleccionada
\*-------------------------------------------*/
.blog-entrada-container {
  font-family: $secondary-font !important;
  background-color: var(--background-color);
  color: var(--onbackground-primary);
}




.entrada-title {
  font-size: 2.4em;
  line-height: 1;
}

.entrada-description {
  color: var(--onbackground-primary);
  font-size: 1.2em;
  border-bottom: 1px solid var(--onbackground-secondary);
}