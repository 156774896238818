/*===========================================================================================
  Micromodal
  Este archivo define los estilos de las ventanas modales que se usan en el proyecto.
 ============================================================================================ */

/**************************\
  Basic Modal Styles
\**************************/

.modal {
  font-family: $secondary-font;

  h2 {
    color: var(--red-inverse);
  }

  .primary-success {
    color: var(--green-inverse) !important;
  }
}

.modal-navegador-recomendado {
  max-width: 800px !important;
  width: 90%;
  height: auto;
  max-height: 90vh;
}

.modal__overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-recomendados {
  font-weight: 700;
  font-family: var(--secondary-font);
  color: var(--onbackground-primary);
}

.close-button {
  outline: none;
  border: none;
  background-color: transparent;

  &:hover {
    color: var(--secondary-color);
  }

  &:active,
  &:focus {
    outline: none;
  }
}

%container_modal {
  border-radius: 9px;
  box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.25);

  padding: 20px;
  max-height: 90vh;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  box-sizing: border-box !important;

  $themes: (
    "clasico":(background: #f1f1ff,
    ),
    "alto-contraste":(background: var(--background-color),
    ),
  );

@include ThemesProperties($themes);
}

.modal__container_instalacion {
  width: 90vw;
  max-width: 700px;
  @extend %container_modal;
}

.modal__container_recomendados {
  width: 90vw;
  max-width: 500px;
  @extend %container_modal;
}

.modal__container {
  min-width: 100px;
  min-height: 250px;
  max-width: 90vw;
  @extend %container_modal;
}

.img-instalacion {
  width: 100px;
  margin: auto;
  display: block;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  background: transparent;
  border: 0;
}

.modal__header .modal__close:before {
  content: "\2715";
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.modal__btn:focus,
.modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

.titulo-modal-navegador {
  color: var(--red-color);

  img {
    width: 50px;
    margin-right: 10px;
  }
}

.link-dudas {
  color: var(--secondary-color);
}

/**************************\
  Club MakeMake
\**************************/

%container_modal_club {
  border: 5px solid var(--scarlet-inverse);
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto !important;
  box-sizing: border-box !important;

  $themes: (
    "clasico":(background: rgba(239, 239, 239, 0.9),
    ),
    "alto-contraste":(background: var(--background-color),
    ),
  );

@include ThemesProperties($themes);
}


.modal__container_club {
  width: 90vw;
  max-width: 1400px;
  //padding-top: 40px;
  //position: relative;
  @extend %container_modal_club;
}

.icono-minus {
  color: var(--onsecondary-color);
  font-size: 40px;
}

.icono-page {
  color: var(--scarlet-inverse);
  font-size: 60px;
}

/**************************\
  Comunidad
\**************************/

%container_modal_comunidad {
  border-radius: 15px;
  border: 6px solid var(--morado-inverse);
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto !important;
  box-sizing: border-box !important;

  $themes: (
    "clasico":(background: rgba(239, 239, 239, 0.9),
    ),
    "alto-contraste":(background: var(--background-color),
    ),
  );

@include ThemesProperties($themes);
}


.modal__container_comunidad {
  width: 90vw;
  max-width: 650px;
  //padding-top: 40px;
  //position: relative;
  @extend %container_modal_comunidad;
}


/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide-eliminar {
  border-radius: 15px;
  max-width: 700px !important;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

@media only screen and (min-width: 576px) {
  ._2p3a {
    width: 100px !important;
  }
}

@media only screen and (min-width: 768px) {
  .img-instalacion {
    width: 100%;
  }
}