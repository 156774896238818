/*=====================================================
    SOLICITUD DE DEMO

    En este archivo se definen los estilos del formulario 
    de solicitud de demo
 ====================================================== */

.solicitud-demo {
    width: 100%;
   
    .container{
        background-color: var(--surface-color) !important;
        min-height: auto !important;

        $themes: (
            "alto-contraste":(
                border: 1px solid var(--onsecondary-color)
            )
        );
        @include ThemesProperties($themes);

        .form-demo{
            transition: all .3s;
            opacity: .5;
            scale: .9; 

            &.anim-demo{
                scale: 1; 
                opacity: 1;
            }
        }
        
    }
    
    hr{
        width: 5%;
        height: 2px;
        background-color: var(--secondary-inverse);
        border-color: var(--secondary-inverse);
    }

    .btn-solicitud-demo-leer{
        width: 90%;
        margin: auto;
        cursor: pointer;
        padding: 10px;
        font-family: var(--primary-font);
        color: var(--onprimary-color);
        background: var(--primary-color);
        border-radius: 5px;
        font-weight: 600;
        transition: all .3s;
        text-align: center;

        &:hover{
            background: var(--secondary-color);
            color: var(--onsecondary-color);
        }

        $themes: (
            "alto-contraste":(
                border: 2px solid var(--secondary-inverse)
            )
        );
        @include ThemesProperties($themes);
    }

    .btn-solicitud-demo{
        width: 90%;
        margin: auto;
        cursor: pointer;
        padding: 10px;
        font-family: var(--primary-font);
        color: var(--secondary-color);
        background: var(--onsecondary-color);
        border-radius: 5px;
        border: 2px solid var(--secondary-inverse);
        font-weight: 600;
        transition: all .3s;
        text-align: center;

        &:hover{
            background: var(--secondary-color);
            color: var(--onsecondary-color);
        }
    }

    .demo-form{
        position: relative;
        width: 100%;

        .img-form{
            position: relative;
            width: 100%;
            height: 160px;
            background-repeat: no-repeat;
            background-size: 250px;
            background-position: center bottom;
        }

        .margin-form{
            height: 30px;
        }

        .content-form{
            position: relative;
            width: 100%;
            background-color: var(--secondary-color);
            border-radius: 20px 20px 0 0;

            .shape-form{
                position: absolute;
                display: none;
                height: 60px;
                width: 58%;
                top: -60px;
                right: 0;
                background-color: var(--secondary-color);
                border-top: 10px solid var(--secondary-color);
                border-left: 10px solid var(--secondary-color);
                border-radius: 20px 20px 0 0;

                &::before{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: -30px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    box-shadow: 10px 10px 0 var(--secondary-color);
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        hr{
            width: 15%;
        }
    
        .btn-solicitud-demo-leer,
        .btn-solicitud-demo{
            width: 70%;
        }
    }

    @media screen and (min-width: 992px) {
        hr{
            width: 30%;
        }

        .btn-solicitud-demo-leer{
            width: 200px;
            padding: 10px 12px;
            margin: 0 20px;
        }

        .btn-solicitud-demo{
            width: 420px;
            padding: 10px 12px;
            margin: 0 20px;
        }
        
        .demo-form{
            .img-form{
                background-position: 10% bottom;
            }
            .margin-form{
                height: 100px;
            }
            .content-form{
                border-radius: 20px 0 0 0;
                .shape-form{
                    display: block;
                }
                
            }
        }
    }
}