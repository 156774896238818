/*===========================================================================================
 Ficha editorial
 En este archivo se establecen los estilos para la ficha de editorial.
 ============================================================================================ */

.fichaeditorial{
  font-weight: 400;
  .tab-active {
    max-height: 400px;
    overflow-y: auto;
  }
  p{
    margin-bottom: 20px;
  }

  .web-page{
    $themes:(
      "alto-contraste":(
        background-color:var(--surface-color),
        color:var(--onbackground-primary),
        border:solid 2px var(--onbackground-primary),
      )
    );
    @include ThemesProperties($themes);
  }
}