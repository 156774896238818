/*-------------------------------------------*\
Este archivo genera los estilos de las medallas 
de la sección de estadísticas en mi panel. 
\*-------------------------------------------*/

.btn-descargar{  
  margin-top: 10px;
  font-size: 0.9em;
  margin: auto;
}

.badge-container{
  justify-content: space-between;
  position: relative;
  p{
    margin-bottom: 15px;
    line-height: 1.2;
  }

  img{
    &:hover{
      + .badges-trigger{    
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .badges-trigger{
    background: transparentize($color: black, $amount: 0.2);
    color: var(--onsecondary-color);    
    visibility: hidden;
    opacity: 0;
    transition: .2s ease-in;
    position: absolute;
    bottom: 100%;
    width: 90%;
    left: 0%;
    right: 0%;
    margin: auto;
    border-radius: 5px;

    $themes:(
      "clasico":(
        border: none,
        background: transparentize($color: black, $amount: 0.2)
      ),
      "alto-contraste":(
        border: 1px solid var(--onsecondary-color),
        background: var(--background-color)
      ),
    );

    @include ThemesProperties($themes);

    &::after{      
      content: "";
      position: absolute;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid transparentize($color: black, $amount: 0.2);
      left: 0;
      right: 0;
      bottom: -15px;

      $themes:(
      "clasico":(
        border-top: 15px solid transparentize($color: black, $amount: 0.2)
      ),
      "alto-contraste":(
        border-top: 15px solid  var(--onsecondary-color)
      ),
    );

    @include ThemesProperties($themes);
    }
  }
}

@media only screen and (min-width:992px) {
  .badge-container{
    .badges-trigger{
      width: 150%;
      left: -25%;
    }
  }
}