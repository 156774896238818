/*=====================================================
    SIN-CONEXION-POPUP
    En este archivo se definen los estilos del popUp
    de sin conexión
 ====================================================== */

.sin-conexion-popup {
    background: url("/img/sinconexion-screen.png") center top/cover no-repeat;
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
    .window {
        $themes:(
            "clasico":(
                background-color: rgb(44, 44, 44)
            ),
            "alto-contraste":(
                background-color: var(--background),
            )
        );
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 90vw;
        width: fit-content;
        padding: 1rem;
        height: 90vh;
        overflow-y: auto;
        @include ThemesProperties($themes);
        .image {
            max-width: 150px;
            width: min-content;
        }
        .title {
            color: var(--primary-inverse);
        }
        .description {
            $themes:(
                "clasico":(
                    color: #fff
                ),
                "alto-contraste":(
                    color: var(--onbackground-primary)
                )
            );
            @include ThemesProperties($themes);
        }
    }
}
