/*===========================================================================================
    Classics
    En este archivo se modifican las variables de color. En caso de que la etiqueta body
    en su atributo data-theme tenga como valor "clasico".
 ============================================================================================ */

body[data-theme="clasico"] {
    --background-color: #f1f1f1; // se utiliza principalmente para los fondos y algunas veces como bordes.
    --surface-color: #ffffff; // se utiliza como superficie, está generalmente encima del background principal.
    --onbackground-primary: #4d4d4d; // se usa para textos y bordes
    --onbackground-secondary: #989393; // se usa para textos y bordes
    --onbackground-third: #333333; // se usa para textos
    --onbackground-fourth: #b8b8b8; // se utiliza para textos pero más que todo para bordes.
    --primary-color: #ffd100;
    --onprimary-color: #000;
    --primary-inverse: #ffd100;
    --onprimary-inverse: #000;
    --secondary-color: #6666cc;
    --onsecondary-color: #fff;
    --secondary-inverse: #6666cc;
    --onsecondary-inverse: #fff;
    --secondary-light-color: #e0e0f5;
    --onsecondary-light-color: #6666cc;
    --secondary-light-inverse: #e0e0f5;
    --onsecondary-light-inverse: #6666cc;
    --third-color: #6680e3;
    --onthird-color: #fff;
    --third-inverse: #6680e3;
    --onthird-inverse: #fff;
    --red-color: #ff3548;
    --onred-color: #fff;
    --red-inverse: #ff3548;
    --onred-inverse: #fff;
    --green-color: #8cc63f;
    --ongreen-color: #fff;
    --green-inverse: #8cc63f;
    --ongreen-inverse: #fff;
    --blue-color: #0096d8;
    --onblue-color: #fff;
    --blue-inverse: #0096d8;
    --onblue-inverse: #fff;
    --disabled-color: #666;
    --ondisabled-color: #fff;
    --violet-color:#82559E;
    --onviolet-color:#fff;
    --violet-inverse:#82559E;
    --onviolet-inverse:#fff;
    --scarlet-color:#EB3D4D;
    --onscarlet-color:#fff;
    --scarlet-inverse:#EB3D4D;
    --onscarlet-inverse:#fff;
 

    // variables del calendario
    --calendario-secondary-purple: #3c3caf;
    --calendario-shadow-purple: #9797ff;
    --calendario-today-purple: #5454f5;
    --calendario-hover-purple: #9797ff;
    --calendario-deselect-purple: #6666CC;
}
