
body[data-font='dyslexic']{
    --primary-font:'openDyslexic';
    --secondary-font:'openDyslexic';
  }
  
  body[data-font='predeterminado']{
    --primary-font:'Raleway' !important;
    --secondary-font:'Quicksand' !important;
  }
  
  body[data-font='times-new-roman']{
    --primary-font:'Times New Roman';
    --secondary-font:'Times New Roman';
  }
  
  body[data-font='arial']{
    --primary-font:'Arial';
    --secondary-font:'Arial';
  }
  

  body[data-font='arial'], 
  body[data-font='times-new-roman'],
  body[data-font='dyslexic']{
    *:not(span):not(i){
        font-family: var(--primary-font);
    }
  }
  

  
  .secondary-font{
    font-family: var(--secondary-font);
}

.primary-font{
      font-family: var(--primary-font);
  }
  