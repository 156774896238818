/*=======================================
    Aquí se definen algunos estilos de la sección de mi cuenta
=========================================*/

.dropzone .dz-preview .dz-image {
  width: 600px;
  height: 220px;
}

.dashboard-progress--profile {
  border: 6px solid var(--onbackground-fourth);
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &::after {
    content: "";
    position: absolute;
    width: 118px;
    height: 139px;
    background: url("/img/insignia.png");
    bottom: -10px;
    right: -70px;
  }
}

.dashboard-progress--list {
  .progress-check {
    color: var(--onbackground-primary);
  }
  li {
    color: var(--onbackground-fourth);
    line-height: 1.2;

    .fa-check-circle {
      color: var(--red-inverse);
    }
    .fa-circle {
      color: var(--onbackground-fourth);
    }
  }
}

.progress {
  border-radius: 50px;

  $themes:(
    "clasico":(
    ),
    "alto-contraste":(
      background-color: var(--background-color),
      border: 1px solid var(--onbackground-primary),
    ),
  );

  @include ThemesProperties($themes);
}
.progress-bar {
  background-color: var(--secondary-inverse);
}

.modal {
  h2 {
    border: none;
  }
}

.dashboard-progress--message {
  border-bottom: 2px solid var(--onbackground-fourth);
  p {
    font-weight: 700;
    color: var(--onbackground-primary);
    line-height: 1.2;
    &:nth-child(1) {
      font-size: 3em;
      color: var(--secondary-inverse);
    }
  }
}

.progress-indicator {
  width: 20px;
  height: 20px;
  background-color: var(--secondary-inverse);
  position: relative;
  &::after {
    content: "";
    position: absolute;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--secondary-inverse);
    left: 0;
    right: 0;
    bottom: -10px;
  }
}

.dashboard-banners--contain {
  position: relative;
  div {
    height: 220px;
    width: 100%;
    &:nth-child(1) {
      height: 220px;
      background-size: cover;
      background-position: center;
    }
    &:nth-child(2) {
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(102, 128, 127, 0.4);
    }
  }
  span {
    @extend .center-absolute;
    width: 60px;
    height: 60px;
    background: var(--surface-color);
    cursor: pointer;
    i {
      line-height: 1.5 !important;
      width: 1.5em !important;
      color: var(--onbackground-primary);
      transition: 0.2s;
      &:hover {
        background-color: var(--onbackground-primary);
        color: var(--surface-color);
      }
    }
  }
}

.dashboard-importantzone {
  background: var(--background-color);

  $themes:(
    "clasico":(
      border: none,
    ),
    "alto-contraste":(
      border: 1px solid var(--onbackground-primary)
    ),
  );

  @include ThemesProperties($themes);
}

.dashboard-medallas {
  transition: 0.2s ease-in;
  &:hover {
    transform: scale(1.08);
  }
}
