/** En este archivo se encuentran los estilos de la entrada del blog*/

.BannerDemo {
    background: var(--primary-color);
    color: var(--onprimary-color);

    .btn-blog-demo {
        background-color: var(--secondary-color);
        color: var(--onsecondary-color);
        border-radius: 5px;
        padding: 12px 30px;
        font-weight: 600;
        transition: all .3s;

        &:hover {
            background-color: var(--red-inverse);
            color: var(--onred-inverse);
        }

        $themes: (
            "alto-contraste":(
                border: 1px solid var(--onsecondary-color)
            )
        );
        @include ThemesProperties($themes);
    }
}

.blog-post {
    background: url("/img/background.png") center/cover fixed repeat;

    .blog-image {
        overflow: hidden;
        z-index: 100;
        position: relative;
    }

    .blog-post-container {
        background: var(--surface-color);
        padding: 20px;
        min-height: 400px;
        box-shadow: 0px -12px 10px 2px rgba(0, 0, 0, 0.4);
        position: relative;

        $themes: (
            "clasico":(
                border: none
            ),
            "alto-contraste":(
                border: 1px solid var(--onsecondary-color)
            )
        );
        @include ThemesProperties($themes);

        @media screen and (min-width: 992px) {
            padding: 2em 3em;
        }
    }

    .btn-volver-blog{
        border: 1px solid var(--secondary-inverse);
        color: var(--secondary-inverse);
        border-radius: 5px;
        padding: 12px 30px;
        font-weight: 600;
        transition: all .3s;

        svg{
            fill: var(--secondary-inverse);
        }
        &:hover {
            background-color: var(--secondary-inverse);
            color: var(--onsecondary-inverse);
        }
    }

    .btn-blog-tag{
        color: var(--onprimary-color);
        border-radius: 100px;
        padding: 10px 20px;
        font-weight: 500;
        transition: all .3s;
        margin-bottom: 10px;

        &:hover {
            $themes: (
                "clasico":(
                    background-color:  var(--secondary-inverse),
                    color: var(--onsecondary-inverse)
                ),
                "alto-contraste":(
                    background-color:  var(--secondary-inverse),
                    color: var(--onsecondary-inverse)
                )
            );
            @include ThemesProperties($themes);            
        }

        $themes: (
            "clasico":(
                background-color: #E3E3F9
            ),
            "alto-contraste":(
                border: 1px solid var(--onsecondary-color)
            )
        );
        @include ThemesProperties($themes);
    }

    .line-blog{
        width: 100%;
        border: none !important;
        height: 1px;
        margin-top: 25px;
        background: var(--onprimary-color);
    }

    .blog-demo{
        box-shadow: 0px -5px 10px 2px rgba(0, 0, 0, 0.4);
        background-color: #E3E3F9 !important;
        
        $themes: (
            "clasico":(
                background-color: #E3E3F9
            ),
            "alto-contraste":(
                background-color: var(--onprimary-inverse)
            )
        );
        @include ThemesProperties($themes);
    }

    .gradient-blog-demo{
        height: 92px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;

        $themes: (
            "clasico":(
                background: linear-gradient(0deg, rgba(227,227,249,1) 0%, rgba(227,227,249,0.7973390039609594) 16%, rgba(227,227,249,0) 100%)
            ),
            "alto-contraste":(
                background: linear-gradient(0deg, rgb(0, 0, 5) 0%, rgba(0, 0, 0, 0.797) 16%, rgba(227,227,249,0) 100%)
            )
        );
        @include ThemesProperties($themes);

    }
}