/*-------------------------------------------*\
Aquí se encuentran varios estilos de las secciones de mi panel.
\*-------------------------------------------*/
.dashboard-contact {
  label {
    @extend .medium-primary;
  }
}

.dashboard-contact--title {
  color: var(--onbackground-primary);
  border-bottom: 2px solid var(--onbackground-fourth);
  h2 {
    border-bottom: none;
  }
  i {
    cursor: pointer;
  }
}

.dashboard-contact--message {
  font-weight: 700;
  color: var(--onbackground-secondary);
}

.optionEscogerCurso {
  visibility: hidden;
}
