.resources-center {
  .content-header {
    position: relative;
    $themes: (
      "clasico": (
        background: #b2ae1b
      ),
      "alto-contraste": (
        background: var(--surface-color)
      )
    );
    @include ThemesProperties($themes);

    .title,
    .description {
      $themes: (
        "clasico": (
          color: #000
        ),
        "alto-contraste": (
          color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);
    }

    .texture {
      position: absolute;
      left: 50%;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-50%);
      overflow: hidden !important;

      .texture1 {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .texture2 {
        position: absolute;
        top: 0;
        right: 0%;
        height: 100%;
      }
    }
  }
  .section-description {
    font-weight: 500;
    max-width: 700px;
  }
  .resources-list {
    background-image: url("/img/resources-center/list-texture.png");
    border-radius: 2rem;
    background-repeat: no-repeat;

    &.purple {
      background-color: var(--secondary-inverse);
      color: var(--onsecondary-inverse);
    }
    &.yellow {
      background-color: var(--primary-inverse);
      color: var(--onprimary-inverse);
    }
  }

  .resource-card {
    background-color: var(--surface-color);
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.4);
    transition:
      transform 0.3s,
      box-shadow 0.3s;
    &:hover {
      transform: translateY(1%);
      box-shadow: 0px 10px 8px 0 rgba(0, 0, 0, 0.3);
    }

    .lazy-image{
      aspect-ratio: 276/119;
      width: 100%;
      height: auto;
    }

    .button {
      font-weight: 600;
      border-radius: 0.5rem;
      transition:
        color 0.3s,
        background-color 0.3s;
      $themes: (
        "clasico": (
          background-color: #e3e3f9,
          color: #262626
        ),
        "alto-contraste": (
          background-color: var(--background-color),
          color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);
      &:hover {
        $themes: (
          "clasico": (
            background-color: var(--secondary-inverse),
            color: var(--onsecondary-inverse)
          ),
          "alto-contraste": (
            background-color: var(--secondary-inverse),
            color: var(--onsecondary-inverse)
          )
        );
        @include ThemesProperties($themes);
      }
    }
  }
}
