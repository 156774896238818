/*-------------------------------------------*\
Aquí se encuentran los estilos de la sección mi colección ubicada en mi panel.
\*-------------------------------------------*/
 @use "sass:map";
.cover-book {
  img {
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 1px 8px rgba(159, 159, 159, 0.6);
  }

  a {
    width: 100%;
    display: block;
    border-radius: 15px;
  }

  .cover-book-wrapper {
    position: relative;
    max-width: 257px;
    margin: auto;
  }

  p {
    font-family: $secondary-font;
    line-height: 1.2;
    color: var(--onbackground-primary);
    font-weight: 600;
  }

  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.02);
  }
}

.dashboard-coleccion-container {
  font-weight: 700;
  color: var(--onbackground-primary);
}

.dashboard-coleccion-dias {
  color: var(--onsecondary-color);
  text-align: center;

  $themes: ("clasico":(border: none),
    "alto-contraste":(border: 1px solid var(--onbackground-primary)),
  );

  @include ThemesProperties($themes);

  div {
    display: flex;

    img {
      max-width: 90px;
      margin-right: 20px;
    }

    p {
      font-size: 3em;
      line-height: 1em;
    }
  }
}

.dashboard-coleccion-dias--border {
  border-bottom: 4px solid var(--onsecondary-color);
}

.dasboard-coleccion-number {
  color: var(--secondary-inverse);
  font-size: 1.5em;
  line-height: 1em;

  span {
    font-size: 2em;
  }
}

.dashboard-coleccion-view {
  cursor: pointer;
  font-weight: 700;
  color: var(--onbackground-primary);
}

.focus-coleccion {
  &:focus-visible {
    outline: none !important;
    padding: 5px;

    .coleccion-book-container {
      outline: solid 2px var(--secondary-color);
    }
  }
}

.dashboard-coleccion-books {
  .swiper-button-prev {
    left: 1em !important;
  }

  img {
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .coleccion-book {
    &:hover {
      transform: scale(1);
    }
  }

  .swiper-pagination-bullets {
    bottom: 0 !important;
  }

  .swiper-pagination-bullet {
    color: var(--onsecondary-color);
    font-size: 15px;
    height: 25px !important;
    padding-top: 2px;
    margin-bottom: 10px;
    text-align: center;
    width: 25px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: var(--secondary-color) !important;
  }
}

.swiper-button-next {
  right: 1rem !important;
  border-radius: 0.15em;

  $themes: ("clasico":(border:none,
    ),
    "alto-contraste":(border: 1px solid var(--onsecondary-color),
    ));

  @include ThemesProperties($themes);

  &::after {
    //import fontawesome 5
    font-family: "Font awesome 5 Free";
    font-weight: 900;
    content: "\f105" !important;
    font-weight: 2em;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: var(--onsecondary-color);
  }
}

.swiper-button-prev {
  left: 1rem !important;
  border-radius: 0.15em;

  $themes: ("clasico":(border:none,
    ),
    "alto-contraste":(border: 1px solid var(--onsecondary-color),
    ));

  @include ThemesProperties($themes);

  &::after {
    //import fontawesome 5
    font-family: "Font awesome 5 Free";
    content: "\f104" !important;
    font-weight: 900;
    font-weight: 2em;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: var(--onsecondary-color);
  }
}

.swiper-button-next,
.swiper-button-prev {
  top: calc(100% - 44px) !important;
  background-color: var(--secondary-color);
  padding: 0 10px !important;
  width: calc(var(--swiper-navigation-size) / 48 * 27) !important;
  position: absolute;
}

// .swiper-wrapper{
//   width: 90%;
//   overflow-x: hidden;
// }

.dashboard-coleccion-indicator {
  border-bottom: 2px solid var(--onbackground-fourth);
}

.coleccion-book {
  .btn-primary {
    font-size: 1em;
    width: 100%;
  }
}

.coleccion-book-detail {
  position: relative;

  &:hover {
    .dashboard-coleccion-titles {
      display: flex;
    }
  }
}

.list-view {
  .swiper-lazy-preloader {
    display: none;
  }

  a {
    color: var(--third-inverse);
  }

  .coleccion-book {
    border-bottom: 2px solid var(--onbackground-fourth);
  }

  .coleccion-book-detail {
    display: flex;
  }

  .coleccion-book-detail--container {
    width: 100%;
    position: relative;
  }

  /*.book-name{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; 
  }*/
  .btn-selected {
    display: none;
  }

  p {
    line-height: 1.2;
  }
}

.cover-view {
  .coleccion-book {
    transition: all 0.2s ease-in;
    opacity: 1;
    height: auto;

    .coleccion-book-container {
      transition: all 0.2s ease-in;
      display: block;
    }
  }

  .coleccion-book-hidden {
    opacity: 0;
    height: 0 !important;
    width: 0 !important;
    overflow: hidden;
    flex: 0% !important;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;

    .coleccion-book-container {
      transition: all 0.2s ease-in;
      display: none;
    }
  }

  .coleccion-book-container {
    position: relative;

    &:hover {
      .coleccion-book-detail {
        display: block;
      }
    }
  }

  .author-name {
    display: none;
  }

  .coleccion-book-detail {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    color: var(--onsecondary-color);
    text-align: center;
    font-size: 20px;

    $themes: ("clasico":(background: rgba(102, 102, 204, 0.9),
      ),
      "alto-contraste":(background: var(--background-color),
        border: 1px solid var(--onbackground-fourth)));

    @include ThemesProperties($themes);

    div {
      max-width: 100% !important;
      position: relative;
    }

    h3 {
      line-height: 1.2;
    }

    a {
      color: var(--onsecondary-color);
      text-decoration: underline;
    }

    .coleccion-book-detail--container {
      position: relative;
      height: 100%;
    }

    .btn-add {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .btn-primary {
      width: 100%;
      border-radius: 0;

      &:hover {
        background-color: var(--onbackground-primary);
        color: var(--surface-color);
      }

      &:focus {
        background-color: var(--onbackground-primary);
        color: var(--surface-color);
      }

      &:active {
        background-color: var(--onbackground-primary);
        color: var(--surface-color);
      }
    }
  }

  .btn-selected {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .coleccion-book-container-selected {
    position: relative;

    img {
      filter: grayscale(1);
    }

    .btn-selected {
      display: block;
    }
  }
}

.btn-selected {
  display: none;
  width: 100%;
  border-radius: 0;
  cursor: auto;
  background-color: var(--onbackground-primary);
  color: var(--surface-color);
  font-size: 0.85em !important;
}

.addBook {
  &:focus {
    background-color: var(--primary-color) !important;
    color: var(--onbackground-third);
  }
}

.btn-remove {
  background-color: var(--red-inverse);
  color: var(--surface-color);

  &:focus {
    background-color: var(--red-inverse) !important;
  }
}

@media only screen and (min-width: 768px) {
  .dashboard-coleccion-container {
    margin-top: -1rem;
  }
}

@media only screen and (min-width: 1200px) {
  .cover-view {
    .coleccion-book-container {
      height: 224px;
    }
  }

  .dashboard-coleccion-container {
    margin-top: -3rem;
  }
}

@media only screen and (min-width: 1366px) {
  .cover-view {
    .coleccion-book-container {
      height: 265px;
    }
  }
}


// NEW ------------



// Suscription card

.suscription-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--secondary-inverse);
  color: var(--onsecondary-inverse);
  border-radius: .5rem;
  padding: 1rem;
  padding-top: 0;

  &.seleccionando-libros{
    background-color: var(--red-inverse);
  }

  &.seleccionando-libros{
     .warning-img{
      width: 60px;
    }
  }

  .days-remaining{
    color: var(--onsecondary-inverse);
    font-size: 3em;
    border: none;
    padding: 0;
  }

  hr{
    width: 100%;
    height: 2px;
    color: var(--onsecondary-inverse);
  }
}

//================================================================




.mi-coleccion {
   .books-main {
    display:grid;
    grid-template-areas:"filter content";
    grid-template-columns: max-content 1fr;


    > .books-section{
      grid-area: content;
      padding-left: 2rem;
    }
      > .filter{
        position: sticky;
        top: 60px;
        left: 0;
        height: calc(100vh - 150px);
        grid-area: filter;
        background-color: transparent;
        z-index: 50;
        .filter-mobile-button{
          display: none;
          width: 100%;
          background-color: var(--blue-inverse);
          color: var(--onblue-inverse);
          font-weight: bold;
          margin-top: .5rem;
          margin-bottom: .5rem;
          &:active, &:focus{
            outline: none;
            border: none;
            box-shadow: none;
          }

          &:hover{
            background-color: var(--secondary-inverse);
            color: var(--onsecondary-inverse);
          }
        } 

        .menu{
          display: flex;
          flex-direction: column;
          align-self: flex-start;
          background-color: inherit;
          top: 0;
          left: 0;
          min-width: 200px;
          max-width: 280px;
          border-radius: .5rem;
          overflow-x: hidden;
          overflow-y: auto;
          padding: .5rem;
          background-color: var(--surface-color);
          color: var(--onbackground-primary);
          box-shadow: 0 0 10px 1px rgba(0,0,0,.5);

          .remove-filters-button{
            color: var(--red-inverse);
            font-weight: bold;
            border-bottom: solid 1px var(--red-inverse);
          }
        }
      
      }
  }

  .checkbox-selector{

    .button{
      width: 100%;
      padding: .25rem;
      margin-bottom: .5rem;
      font-weight: bold;
      border-radius: .5rem;
      background-color: var(--secondary-light-color);
      color: var(--onsecondary-light-color);
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      flex-wrap: nowrap;

      > .icon{
        transition: transform .3s;
        &.collapsed{
          transform: rotate(0deg);
        }
        &.expanded{
          transform: rotate(180deg);
        }
      }
    }

    .list{
      width: 100%;
      margin: 0;
      padding: 0;
      padding-left: .5rem;
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      transition: max-height .3s, visibility .3s, opacity .3s;
      .item{
  
      }
    }


    &.expanded > .list{
       max-height: 400px;
      visibility: visible;
      opacity: 1;
    }
    &.collapsed > .list{
      max-height: 0px;
      visibility: hidden;
      opacity: 0;
    }
  }

  .confirmation-button{
    padding: .5rem;
    color: var(--onblue-inverse);
    background-color: var(--blue-inverse);
    border-radius: .5rem;
    font-weight: bold;
    transition: color .3s, background-color .3s;
    &:hover{
      color: var(--onsecondary-inverse);
      background-color: var(--secondary-inverse);
    }
  }
}


@media screen and (max-width:map.get($grid-breakpoints, "lg")) {
  .mi-coleccion{
    .books-main{
      grid-template-areas: 
      "filter"
      "content";
    grid-template-columns: 1fr;

    }

    .books-main > .books-section{
      padding-left: 0rem;
    }
    
   .books-main > .filter{
    width: 100%;
    top: 40px;
    height:fit-content;
    max-height: fit-content;

    .filter-mobile-button{
      display: block;
      .icon{
        transition: transform .3s;
        &.collapsed{
          transform: rotate(0deg);
        }
        &.expanded{
          transform: rotate(180deg);
        }
      }

    }
    .menu{
      position: absolute;
      left: 0;
      top: 90%;
      min-width: 100%;
      max-width: 100%;
      box-shadow: 0 10px 10px 1px rgba(0,0,0,.25);
      transition: max-height .3s;
      &.expanded{
        max-height: 400px;
        visibility: visible;
      }
      &.collapsed{
        max-height: 0px;
        visibility: hidden;
      }
    }
   }
  }
}

.books-info-bar{
  position: sticky;
  width: 100%;
  top: 50px;
  left: 0;
  padding: .5rem;
  background-color: var(--secondary-light-inverse);
  color: var(--onsecondary-light-inverse);
  font-weight: bold;
  z-index: 40;
}


@media screen and (max-width:map.get($grid-breakpoints, "lg")) {
  .books-info-bar{
    top: 85px;
  }
}


// COLLECTION STATS

.collection-stats-button{
  padding: .5rem;
  color: var(--onprimary-inverse);
  border: solid 2px var(--primary-inverse);
  background-color: var(--primary-inverse);
  border-radius: .5rem;
  font-weight: bold;
  transition: color .3s, background-color .3s;
  &:hover{
    border: solid 2px var(--secondary-inverse);
    color: var(--onsecondary-inverse);
    background-color: var(--secondary-inverse);
  }
  &.expanded{
    color: var(--onbackground-primary);
    background-color: var(--surface-color);
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;

  }
}

.collection-stats{
  position: relative;
  .category-buttons{
    display: flex;
    flex-direction: row;
    justify-content: start;
    .category-button{
      height: fit-content;
      padding: .25rem;
      margin: .5rem;
      border: solid 2px var(--secondary-inverse);
      border-radius: .5rem;
      color: var(--secondary-inverse);
      background-color: transparent;
      transition: color .3s, background-color .3s;
      &:hover, &.current{
        color: var(--onsecondary-inverse);
        background-color: var(--secondary-inverse);
      }
      &.current{
        font-weight: bold;
      }
    }
  }



  > .content{
    transition: max-height .3s, visibility .3s;
    overflow: auto;
    max-height: 300px;
    width: 100%;
    border: solid 2px var(--primary-inverse);
    background-color: var(--surface-color);
    &.expanded{
      visibility: 1;
      max-height: auto;
      opacity: 1;
    }
    
    &.collapsed{
      opacity: 0;
      visibility: 0;
      max-height: 0;
    }

    .items{
      display: grid;
      margin: 0;
      padding: 0;
      grid-template-columns: 1fr 1fr;
      .item{
        display: grid;
        grid-template-columns: min-content 1fr .5fr;
        border-bottom: solid 1px var(--onbackground-primary);
        margin: .5rem;
        .img{
          width: 60px;
          height: 60px;
          align-self: center;
          border-radius: .5rem;
          overflow: hidden;
          margin: .5rem;
        }
        .title{
          color: var(--onbackground-primary);
          align-self: center;
          padding-left: .5rem;
          padding-right: .5rem;
        }
        .percentage{
          color: var(--onbackground-secondary);
        }
        .books-length{
          color: var(--onbackground-third);
          font-size: .8em;
        }
      }
    }
  }
}

@media screen and (max-width:map.get($grid-breakpoints, "md")) {
  .collection-stats{
    .category-buttons{
      flex-direction: column;
    }
    > .content{
      .items{
        grid-template-columns: 1fr;
      }
    }
  }
}

.back-to-top-button{
  padding: .5rem;
  border-radius: 999999px;
  background-color: var(--secondary-inverse);
  color: var(--onsecondary-inverse);
  transition: color .3s, background-color .3s, transform .3s;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 30;
  margin: .5rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,.25);
  &:hover{
    background-color: var(--onsecondary-inverse);
    color: var(--secondary-inverse);
    transform: scale(1.1);
  }
}

