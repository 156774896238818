/*===========================================================================================
  plector-pagarestudiante

  >**NOTA:** se debe revisar las clases de este archivo ya que aparentemente no se usan. 
 ============================================================================================ */

.pagar-carnet {
  background-color: var(--background-color);
  background-image: url("/img/background.png");
  background-size: cover;
  background-blend-mode: multiply;
  border-radius: 5px;
  border: 2px solid var(--onbackground-fourth);
  p {
    font-weight: 600;
    margin-bottom: 0.15em;
  }
}

.carnet-student-name {
  font-size: 2em;
  font-weight: 700 !important;
  line-height: 1.15;
}

.carnet-student-code {
  background-color: var(--blue-color);
  color: #fff !important;
  border-radius: 5px;
  display: inline-block;
}

.btn-light {
  font-weight: 700 !important;
}
