/*===========================================================================================
  Tooltio
  Define los estilos del tooltip usado en el proyecto. 
 ============================================================================================ */

.tooltiptext {
  border-radius: 6px !important;
  /* bottom: 80px; */
  color: var(--onsecondary-color) !important;
  font-family: $font-family-sans-serif;
  font-size: 17px !important;
  font-weight: 300;
  left: 52px;
  line-height: 1.3;
  max-height: 200px;
  max-width: 400px;
  opacity: 0;
  overflow-y: auto;
  position: absolute;
  text-align: left;
  transition: opacity 0.3s;
  visibility: hidden;
  z-index: 1;
  -webkit-transition: opacity 0.3s;
  padding: 10px;

  $themes:(
    "clasico":(
      background-color: #555,
    ),
    "alto-contraste":(
      background-color: var(--primary-color),
      border-style: solid,
      border-width: 1px,
      border-color: var(--onprimary-color)
    )
  );

  @include ThemesProperties($themes);


  &::after {
    border-top-color: var(--onbackground-primary) !important;
    border-bottom-color: var(--onbackground-primary) !important;
  }
  &::before {
    border-bottom-color:var(--onbackground-primary) !important;
  }
}

.tooltip:hover, .tooltip:focus-visible .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-width: 526px) {
  .dba-tooltip {
    .tooltiptext {
      left: 127px;
      bottom: 40%;
    }

    &::after {
      left: 28%;
    }
  }
}

@media only screen and (min-width: 992px) {  
  .tooltiptext {
    overflow-y: hidden;
  }
}