/*===========================================================================================
  plector-profesor

  >**NOTA:** se debe revisar las clases de este archivo ya que aparentemente no se usan. 
 ============================================================================================ */

.btn-info {
  font-weight: 700;
  font-size: 1.2em;
  transition: 0.2s;
  background: var(--onbackground-fourth) !important;
  color: var(--onbackground-third);
  line-height: 1.2;
  @extend .noutline;
  &:hover {
    background-color: var(--secondary-color);
    color: white;
    @extend .noutline;
  }
  &:focus {
    @extend .noutline;
  }
  &:active {
    background: var(--secondary-color) !important;
    transform: scale(0.95);
    @extend .noutline;
    &:focus {
      box-shadow: none !important;
    }
  }
  &:visited {
    @extend .noutline;
  }
}

.profesor-cursos {
  .btn-primary {
    font-size: 1em;
  }
  .btn-secondary {
    font-size: 1em;
  }
}

.dashboard-profesor {
  .swiper-pagination-bullets {
    bottom: 2.5%;
  }

  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    color: #fff;
    background: var(--secondary-color) !important;
  }
}

.code {
  background-color: var(--background-color);
  border: 2px solid var(--onbackground-fourth);
  border-radius: 5px;
  .important-item {
    font-size: 2.5em;
  }
}

.resumen-curso {
  border: 1px solid var(--onbackground-fourth);
  background: var(--background-color);
  border-radius: 5px;
}

.profesor-profile {
  &::after {
    content: none;
  }
}

.nav-menu-plector {
  opacity: 0;
}

.profesor-message {
  border-bottom: 1px solid var(--red-color) !important;
  padding-bottom: 5px;
}

.profesor-ficha-libro {
  h1 {
    text-transform: initial;
  }
  .fichalibro-data--link {
    font-size: 1.2em;
    color: #4d4d4d;
    font-weight: 700;
    display: inline-block;
    padding-bottom: 0 !important;
    background: transparent linear-gradient(90deg, #ffd100 -10px, #ffd100) -10px 100%/100% 4px repeat-x;
  }
  .fichalibro-data--name {
    font-weight: 700;
  }
}
