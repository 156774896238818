/*=====================================================
    SPINNER
 ====================================================== */

.spinner-rotation{
    animation: spinner-animation 1s linear infinite;
}


@keyframes spinner-animation{
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    } 
}