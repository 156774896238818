
$outline-size: 3px;

.focusable{
  @include focusable(solid, #{$outline-size} , var(--onbackground-primary) );
}

.focusable{
    &-primary{
        @include focusable(solid, #{$outline-size} , var(--primary-color));
    }
    &-secondary{
        @include focusable(solid, #{$outline-size} , var(--secondary-color));
    }
    &-red{
        @include focusable(solid, #{$outline-size} , var(--red-color));
    }
    &-green{
        @include focusable(solid, #{$outline-size} , var(--green-color));
    }
    &-blue{
        @include focusable(solid, #{$outline-size} , var(--blue-color));
    }
}

.focusable-by-children{
  @include focusable(solid, #{$outline-size} , var(--onbackground-primary) );
}

.focusable-by-children{
    &-primary{
        @include focusableByChildren(solid, #{$outline-size} , var(--primary-color));
    }
    &-secondary{
        @include focusableByChildren(solid, #{$outline-size} , var(--secondary-color));
    }
    &-red{
        @include focusableByChildren(solid, #{$outline-size} , var(--red-color));
    }
    &-green{
        @include focusableByChildren(solid, #{$outline-size} , var(--green-color));
    }
    &-blue{
        @include focusableByChildren(solid, #{$outline-size} , var(--blue-color));
    }
}



