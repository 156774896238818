/*===========================================================================================
  plector-crearcuenta
  En este archivo se establece el estilo de un contenedor de demo-ingresar-datos
 ============================================================================================ */

.pl-content-container{  
  background: #fff;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  min-height: 400px;
}

.confirm-code{
  display: none;
  opacity: 0;
  transition: .4s ease-in;
}

.resend-codigo{
  &:hover{
    text-decoration: none;
  }
  &:active{
    text-decoration: none;
  }
}