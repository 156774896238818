/*=====================================================
                      MARKETING
 ====================================================== */
.dashboard-marketing-image {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("/img/banner1.jpg") center/cover no-repeat;
  position: relative;
  div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
  }
  p {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5em;
    color: white;
    line-height: 1.2;
    position: relative;
    z-index: 2;
  }
}
.marketing-banner1 {
  background-color: var(--primary-color);
}
.marketing-banner2 {
  background-color: var(--green-color);
}
.marketing-banner3 {
  background-color: var(--red-color);
}
.marketing-banner4 {
  background-color: var(--secondary-color);
}
