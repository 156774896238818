* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

@import "./dyslexic";

/* body,
html {
  /*  overflow-x: hidden; *
  // height: 100%;
} */

body {
  &.fontSize16 {
    font-size: 16px;
  }
  &.fontSize18 {
    font-size: 18px;
  }
  &.fontSize20 {
    font-size: 20px;
  }

  &.fontSize22 {
    font-size: 22px;
  }

  &.fontSize24 {
    font-size: 24px;
  }

  &.fontSize26 {
    font-size: 26px;
  }

  &.fontSize28 {
    font-size: 28px;
  }

  &.fontSize30 {
    font-size: 30px;
  }

  &.fontSize32 {
    font-size: 32px;
  }

  // &.fontPredeterminado{
  //   font-family: "Raleway", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  // }
  // &.fontTimesRoman{font-family: 'Times New Roman', Times, serif !important;}
  // &.fontArial{font-family: Arial, Helvetica, sans-serif !important;}
  // &.fontDyslexic{font-family: 'OpenDyslexic' !important;}
  background-color: var(--surface-color);
  font-family: var(--primary-font);
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
sub,
ul,
li {
  margin: 0;
}

ul {
  list-style: none;
}

strong {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.noutline {
  outline: none;
  border: none;
  box-shadow: none;
}

.img-fluid {
  max-width: 100%;
}

.hide {
  display: none;
}

label{
	margin: 0 !important;
}

img {
  /*  image-rendering: -moz-crisp-edges; // Firefox */
  image-rendering: -o-crisp-edges; // Opera
  image-rendering: -webkit-optimize-contrast; // Webkit (non-standard naming)
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; // IE (non-standard property)
}

.pointer {
  cursor: pointer;
}


.btn:disabled, .btn:disabled:hover, .btn:disabled:active{
  background-color: var(--disabled-color) !important;
  color: var(--ondisabled-color) !important;
  transition: none;
  transform: none;
}




.btn-primary {
  font-weight: 700;
  font-size: 1.2em;
  transition: 0.2s;
  background: var(--primary-inverse);
  color: var(--onprimary-inverse);
  line-height: 1.2;
  @extend .noutline;

  $themes: ("clasico":(border: none,
    ),
    "alto-contraste":(border: 1px solid var(--onsecondary-color),
    ));

  @include ThemesProperties($themes);

  &:hover {
    background-color: var(--secondary-color);
    color: var(--onsecondary-color);
    transform: scale(1.01);
    @extend .noutline;
  }

  &:focus {
    @extend .noutline;
    background: var(--secondary-color) !important;
    color: var(--onsecondary-color);
  }

  &:active {
    background: var(--primary-color) !important;
    transform: scale(0.95);
    @extend .noutline;

    &:focus {
      box-shadow: none !important;
    }
  }

  &:visited {
    @extend .noutline;
  }
}

  // la clase se usa cuando el botón amarillo esta sobre un fondo de color morado, se cambia el hover a color rojo para que no se vea rara la transición de estado.
  .primary-onsecondary-button {
    @extend .btn-primary;

    &:hover {
      background-color: var(--red-color);
      color: var(--onred-color);
    }
  }



.btn-disabled {
  background: var(--onbackground-secondary);
  color: var(--onprimary-inverse);
}

.btn-primary--lower {
  font-size: 1em;
}

.overflow-hidden{
  overflow: hidden;
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: var(--onsecondary-color);
  font-weight: 700;

  $themes: ("clasico":(border-style: none,
    ),
    "alto-contraste":(border-style: solid,
      border-width: 1px,
      border-color: var(--onsecondary-color),
    ));

  @include ThemesProperties($themes);

  &:hover {
    background-color: var(--onbackground-primary);
    color: var(--surface-color);
    transform: scale(1.01);
    @extend .noutline;
  }

  &:focus {
    color: var(--surface-color);
    @extend .noutline;
  }

  &:active {
    color: var(--surface-color);
    @extend .noutline;
  }
}

.btn-third {
  background-color: var(--red-color);
  color: var(--onred-color);
  font-weight: 700;

  $themes: (
    "clasico":(
      border-style: none,
    ),
    "alto-contraste":(
      border-style: solid,
      border-width: 1px,
      border-color: var(--onsecondary-color),
    ));

  @include ThemesProperties($themes);

  &:hover {
    background-color: var(--secondary-inverse);
    color: var(--surface-color);
    transform: scale(1.01);
    @extend .noutline;
  }

  &:focus {
    color: var(--surface-color);
    @extend .noutline;
  }

  &:active {
    color: var(--surface-color);
    @extend .noutline;
  }
}

.btn-green {
  background-color: var(--green-color);
  color: var(--ongreen-color);
  font-weight: 700;
  transition: transform .3s;

  $themes: (
    "clasico":(
      border-style: none,
    ),
    "alto-contraste":(
      border-style: solid,
      border-width: 1px,
      border-color: var(--onsecondary-color),
    ));

  @include ThemesProperties($themes);
  &:hover, &:active, &:active{
    color: var(--ongreen-color);
    transform: scale(1.2);
  }
}


.btn-delete-message {
  color: var(--red-color);
  font-weight: 700;

  &:hover {
    color: var(--red-color);
    opacity: 0.5;
    @extend .noutline;
  }

  &:focus {
    @extend .noutline;
  }

  &:active {
    @extend .noutline;
  }
}

.btn-light {
  font-weight: 600;
}

a {
  color: var(--onbackground-primary);

  &:hover {
    text-decoration: none;
  }

  &:focus {
    @extend .noutline;
  }

  &:active {
    @extend .noutline;
  }

  &:visited {
    @extend .noutline;
  }
}

.boton-morado-activo{
  text-transform: capitalize;
  color: var(--onviolet-inverse);
  font-family: var(--primary-font);
  font-weight: 700;
  border-radius: 0.375rem;
  padding: 0.25rem 0.875rem;

  $themes: (
    "clasico":(
      background: #6666cc,
    ),
    "alto-contraste":(
      background: var(--violet-inverse),
    )
  );

  @include ThemesProperties($themes);

}

.boton-morado-desactivado{
  text-transform: capitalize;
  font-family: var(--primary-font);
  font-weight: 700;
  border-radius: 0.375rem;
  padding: 0.25rem 0.875rem;

  $themes: ("clasico":(border: none,
    background: #BBBBEA,
    color: #6666cc,
    ),
    "alto-contraste":(
      border: 1px solid var(--onsecondary-color),
      background: var(--violet-color),
      color: var(--violet-inverse),
    )
  );

  @include ThemesProperties($themes);

}

.important-background {
  background-color: var(--red-color);
}

.secondary-background {
  background-color: var(--secondary-color);
}

.relative {
  position: relative;
}

.terminos {
  p {
    margin-bottom: 20px;
  }
}

.terminos-intro{
  border: 1px solid var(--blue-inverse);
  border-radius: 25px;
  padding: 20px 30px;
  font-size: 0.9em;
  margin-bottom: 20px;
  text-align: justify;

  p{
    padding: 0;
    margin: 0
  }

  $themes:(
    "clasico":(
      background-color: rgba(0, 151, 216, 0.12)
    ),
    "alto-contraste":(
      background-color: var(--blue-color)
    )
  );
  @include ThemesProperties($themes);
}
.medium-primary {
  font-weight: 700;
  color: var(--onbackground-primary);
}

.main-container {
  font-family: $secondary-font  !important;
  background: url("/img/background.png") center/cover fixed repeat;

  // padding-top: 45px !important;
  h1 {
    /*margin-top: 60px;*/
    line-height: 1.1em;
    color: var(--onbackground-primary);
    text-transform: uppercase;
    font-weight: 600;
  }

  h2 {
    color: var(--onbackground-primary);
    border-bottom: 2px solid var(--onbackground-fourth);
    line-height: 1.1;
    padding-bottom: 10px;
    font-weight: 600;
  }

  h4 {
    border-radius: 5px;
    font-weight: 600;

    $themes: (
      "clasico":(
        background: #e3e3e3,
        color: var(--onbackground-primary)
      ),
      "alto-contraste":(
        background: var(--primary-color),
        border: 1px solid var(--primary-inverse),
        color: var(--primary-inverse)
      ),
    );

    @include ThemesProperties($themes);
  }
}

.table {
  color: var(--onbackground-third)
}



.dataTable-pagination {

  .active {
    a {
      $themes: (
        "clasico":(
          background-color: #d9d9d9
        ),
        "alto-contraste":(
          background-color: var(--background-color),
          border: 1px solid var(--onbackground-fourth)
        ),
      );

      @include ThemesProperties($themes);

    }
  }

  a {
    color: var(--secondary-inverse);

    &:hover {
      $themes: (
        "clasico":(
          background-color: #d9d9d9,
        ),
        "alto-contraste":(
          background-color: var(--background-color),
          border: 1px solid var(--onbackground-fourth)
        ),
      );

      @include ThemesProperties($themes);
    }


  }
}

.content {
  background: #fff;
}

.main-content {
  background: var(--surface-color);
  position: relative;
  padding-top: 3rem;
  min-height: 400px;
  // border-top: 3px solid var(--onbackground-fourth);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4);

  $themes: (
    "clasico":(
      border: none
    ),
    "alto-contraste":(
      border: 1px solid var(--onsecondary-color)
    ),
  );

  @include ThemesProperties($themes);
}

.main-content-2 {
  background: var(--surface-color);
  position: relative;
  padding-top: 3rem;
  min-height: 400px;
  box-shadow: 0px -13px 10px 2px rgba(0, 0, 0, 0.4);

  $themes: (
    "clasico":(
      border: none
    ),
    "alto-contraste":(
      border: 1px solid var(--onsecondary-color)
    ),
  );

  @include ThemesProperties($themes);
}

.center-absolute {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hide-lg {
  display: block;
}

.show-lg {
  display: none;
}

.show-md {
  display: none;
}

.no-lineheight {
  line-height: 1.2;
}

.breadcrumb {
  $themes: (
    "clasico":(
      background-color: #e9ecef
    ),
    "alto-contraste":(
      background-color: var(--background-color),
      border: 1px solid var(--onbackground-primary)
    ),
  );

  @include ThemesProperties($themes);

  li {
    font-weight: 600;
  }

  a{
    &:hover{
      $themes: (
        "clasico":(
          color: #0056b3
        ),
        "alto-contraste":(
          color: var(--onbackground-primary)
        ),
      );
      
      @include ThemesProperties($themes);
    }
  }
}

.primary-item {
  color: var(--red-inverse);
}

.primary-success {
  color: var(--green-color);
}

.secondary-item {
  color: var(--secondary-inverse) !important;
}



.modal-checkbox,
.demo-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-progress-appearance: none;
  visibility: hidden;
}

.label-check{
  cursor: pointer;
}
/* checkbox: CHECK CAJA */
.check {
  width: 20px;
  height: 20px;
  position: relative;
  font-size: 1em;
  display: inline-block;
}

.demo-checkbox + .check:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  background: url("/img/icons/checkbox2.svg") center/ contain;
}

.modal-checkbox + .check:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  background: url("/img/icons/checkbox.svg") center/ contain;
}

.modal-checkbox:checked + .check:after {
  background: url("/img/icons/check-cuadro.svg") center/ contain;
}

.demo-checkbox:checked + .check:after {
  background: url("/img/icons/check-cuadro2.svg") center/ contain;
}

.infinite-scroll-component__outerdiv {
  width: 100% !important;
}

.form-control {
  border: 1px solid var(--onbackground-fourth) !important;
  background: var(--surface-color);
  color: var(--onbackground-third);
}

.form-control:focus {
  background-color: var(--surface-color);
  color: var(--onbackground-third);
}

.form-control::placeholder {
  color: var(--onbackground-primary)  !important;
}

.form-control:disabled {
  color: var(--onbackground-primary) !important;
  $themes:(
    "clasico":(
      background-color: #e9ecef
    ),
    "alto-contraste":(
      background-color: var(--background-color) 
    )
  );

  @include ThemesProperties($themes);
}

.scroll {
  overflow-y: auto;
}
.scroll::-webkit-scrollbar {
  width: 10px;
  background-color: var(--surface-color);
}
.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e6e6e6;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: var(--secondary-color);
}

@media only screen and (min-width: 768px) {
  .show-md {
    display: block;
  }
}

@media only screen and (min-width: 992px) {
  .main-content {
    min-height: 700px;
  }

  .hide-lg {
    display: none;
  }

  .show-lg {
    display: inline-block;
  }

  .cover-view {
    .coleccion-book-detail {
      font-size: 0.8em;
    }
  }
}

.primary-icon {
  color: var(--primary-color);
}

.bold {
  font-weight: 700;
}

.link-question {
  text-decoration: underline;
  color: var(--onbackground-secondary);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  background: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

input[type="number"] {
  -appearance: textfield;
}

.subseccion-politica {
  text-decoration: underline;
}

.lista-politica {
  ul {
    list-style: unset;
  }

  ol {
    list-style: lower-alpha;
  }
}

.boton-actualizar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  position: fixed;
  top: 60px;
  right: 0;
  margin: 0.5rem;
  z-index: 1000;
  background-color: var(--secondary-color);
  border-radius: 10px;
  border-width: none;
  color: #fff;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.4);

  i {
    font-size: 2em;
    margin: 0.5rem;
  }

  p {
    font-weight: bold;
    font-size: 1em;
    line-height: 1.2em;
  }
}

/*Textos en una sola linea*/
.una-linea-texto{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {
  .boton-actualizar {
    top: 66px;
    width: 98%;
  }
}

.boton-omitir {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 3rem;
  margin: 0.5rem;
  border-radius: 10px;
  border: none;
  color: #fff;
  transition: transform .2s;

  i {
    font-size: 2em;
    margin: 0.5rem;
  }

  p {
    font-size: 1em;
    line-height: 1.2em;
  }
}
.boton-omitir:hover {
  transform: scale(0.95);
}


@media only screen and (max-width: 500px) {
  .boton-omitir {
    width: 8rem;

  }
}

.font-quicksand {
  font-family: $secondary-font;
}

@media only screen and (min-width: 1500px) {
  .container {
    max-width: 1300px;
  }
}

.primary-color {
  color: var(--primary-color) !important;
}

.secondary-color {
  color: var(--secondary-color) !important;
}