.updating-status{
    position: fixed;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.5);
    top: 50px;
    right: 0;
    background-color: var(--surface-color);
    border-radius: .5rem;
    animation: updating-status-anim .5s, 1s, both;
    z-index: 9999999;
    max-width: 400px;
    border: solid 2px var(--secondary-inverse);

    & .description{
       color: var(--onbackground-primary);
       font-weight: 700;
       font-family: var(--secondary-font);
       font-size: 0.9em;
    }
}

@keyframes updating-status-anim{
    from{
        opacity: 0;
        transform: translateX(100%);
    }
    to{
        opacity: 1;
        transform: translateX(0);

    }
}