/*=====================================================
    LANGUAGE
    Este archivo define los estilos del dropdown de lenguaje
 ====================================================== */

.dropdownLang {
    position: fixed;
    top: 46px;
    right: 0;
    width: 100%;
    z-index: 9000 !important;
    border-top: 4px solid var(--primary-inverse);

    @include backgroundHover;

    -webkit-transition: max-height .3s, padding .3s, visibility .3s;
    -o-transition: max-height .3s, padding .3s, visibility .3s;
    transition: max-height .3s, padding .3s, visibility .3s;


    &.activeMode {
        max-height: 400px;
        visibility: visible;
    }

    &.inactiveMode {
        max-height: 0px;
        visibility: hidden;
    }

    li {
        margin: 0;
        padding: 10px;
        font-size: 0.88em;
        text-align: center;
        border-bottom: 1px solid var(--onsecondary-color);

        &:last-child {
            border-bottom: none !important;
        }

        &.activeMode {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            transition: .3s ease;

        }

        &.inactiveMode {
            opacity: 0;
            padding: 0;
            visibility: hidden;
            transform: translateY(-20px);
            transition: .3s ease;
        }

       
        button {
            transition: all .2s ease-in-out;
            padding: 0 3px;
            font-weight: 600;
            font-size: 0.88em;
            font-family: var(--primary-font);
            width: 100%;

            &.hover{
                &:hover{
                    color: var(--primary-inverse);
                }
            }

            &:hover,
            &.activeMode {
                color: var(--primary-inverse);
            }

            &.inactiveMode {
                color: var(--onsecondary-color);
            }
        }
    }

    @media screen and (min-width: 526px) {
        width: 280px;
    }

    @media screen and (min-width: 992px) {
        width: 180px;
    }
}