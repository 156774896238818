.mobile-app-popup{
    position: fixed;
    bottom: 0;
    right: 0%;
    background-color: var(--background-color);
    color: var(--onbackground-primary);
    box-shadow: 0 -5px 20px 0 rgba(0,0,0,.5);
    z-index: 99999;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;

    .app-logo{
        position: absolute;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 100px;
        border-radius: 99999px;
        border: solid 5px #fff;

    }

    .go-app{
       border-radius: .5rem; 
       background-color: var(--secondary-inverse);
       color: var(--onsecondary-inverse);
       font-weight: 700;
       transition: color .3s, background-color .3s;
       &:hover{
        background-color: var(--primary-inverse);
        color: var(--onprimary-inverse);
       }
    }

    .not-now{
        font-size: .9em;
        color: var(--secondary-inverse);
        transition: color .3s, background-color .3s;
        &:hover{
         color: var(--red-inverse);
        }
    }

}