/*=====================================================
    SWITCH-BUTTON
    Este archivo genera el estilo del componente 
    SwitchButton
 ====================================================== */

.switch-button{
    position: relative; 
    width:70px;
    height: 30px;
    background-color: var(--background-color);
    border-radius: 99999px;
    outline: solid 2px var(--onbackground-primary) !important;
    box-shadow: 0 0 10px 2px rgba(0,0,0,.25);
    &:disabled{
        background-color: var(--ondisabled-color);
    }
    &:disabled::before{
        background-color: var(--disabled-color);
    }
    &::before{
        content: '';
        width: 25px;
        height: 25px;
        position: absolute;
        top: 50%;
        left: 5px;
        border-radius: 99999px;
        transform: translate(0, -50%);
        transition: all .3s;
        background-color: var(--onbackground-primary);
    }
    &.checked::before{
        transform: translate(35px, -50%);
        background-color: var(--secondary-inverse);
    }
}