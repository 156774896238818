/*=====================================================
    BARRA-CONEXION
    En este archivo se establece el estilo de la 
    barra de conexión a internet
 ====================================================== */

@use "sass:map";

.barra-conexion{
    min-width: 100%;
    position: fixed;
    z-index: 9999;
    bottom: 0;
    left: 0;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    transform-origin: 0% 100%;

    $themes:(
        "online":(
            "clasico":(
                background-color: rgb(0, 155, 0),
                color: #fff
            ),
            "alto-contraste":(
                background-color: var(--onbackground-primary),
                color: var(--background)
            )   
        ),
        "offline":(
            "clasico":(
                background-color: #ff0000,
                color: #fff
            ),
            "alto-contraste":(
                background-color: var(--onbackground-primary),
                color: var(--background)
            )
        )
    );

    &.offline{
        @include ThemesProperties(map.get($themes, "offline"));
        animation:show-conexion .5s ease-in-out both;
    }
    &.online{
        @include ThemesProperties(map.get($themes, "online"));
        &.auto-hide{
            animation: auto-hide .5s ease-in-out both;
            animation-delay: 3s;
        }
    }
}

@keyframes auto-hide{
    0%{
        opacity: 1;
        transform: scaleY(1);
    }
    100%{
        opacity: 0;
        transform: scaleY(0);
    }
}

@keyframes show-conexion{
    0%{
        opacity: 0;
        transform: scaleY(0);
    }
    100%{
        opacity: 1;
        transform: scaleY(1);
    }
}
