/*===========================================================================================
  plector-padre

  >**NOTA:** se debe revisar las clases de este archivo ya que aparentemente no se usan. 
 ============================================================================================ */

.pl-padre-title {
  color: #fff;
  font-size: 2.4em;
  line-height: 1.3;
}

.border {
  width: 180px;
  height: 180px;
  border: 2px solid var(--onbackground-fourth);
  border-radius: 50%;
  padding: 10px;
  transition: 0.2s ease-in;
  transform: scale(1);
  &:hover {
    transform: scale(1.05);
  }
}

.pl-padre-estudiante-profile {
  @extend .border;
  background-size: contain;
}

.padre-estudiantes-container {
  a {
    &:hover {
      color: var(--onbackground-primary);
    }
  }
}

.pl-addstudent {
  @extend .border;
  div {
    background-color: var(--primary-color);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    i {
      font-size: 4em;
      color: #000;
    }
  }
}

.student-state {
  font-weight: 600;
}

.student-name {
  color: #000;
}

.estudiante-padre {
  p {
    color: var(--onbackground-primary);
  }
  .btn-primary {
    font-size: 0.9em;
  }
}

.text-medallas {
  p {
    line-height: 1.4;
  }
}
