/*=====================================================
          AYUDA O INSTRUCCIONES DE DESCARGA
 ====================================================== */

.instrucciones-app {
  margin: auto;

  h2 {
    color: var(--third-color);
    $themes:(
      "alto-contraste":(
        color:var(--onbackground-primary)
      )
    );
    @include ThemesProperties($themes);
  }

  .instrucciones-app-eyex {
    width: 150px;
    margin: auto;
    display: block;
  }

  h5 {
    font-size: 1em;
  }

  a {
    font-size: 0.9em;
  }

  .instruccion-icono {
    width: 25px;
  }
}

.videoInstruccion{
  width: 100%;
  height: 315px;
  background-color: #000;
}

.atencion{
  color: #FF3548;
  font-weight: 600;
  $themes:(
    "alto-contraste":(
      color:var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);
}

.img-btn-descargar {
  width: 100%;
  display: block;
}

.datos-de-contacto{
  position: initial;
  $themes:(
    "alto-contraste":(
      color:var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes); 
}

/*-------------------------------------------*\
  ACORDEON
\*-------------------------------------------*/

.acordeon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--surface-color);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 30;
  border: none;
  border-bottom: 1px solid var(--secondary-color);
  color: var(--third-color);
  font-weight: 600;
  font-family: $secondary-font;
  font-size: 1em;
  text-align: start;
  $themes:(
    "alto-contraste":(
      background-color:var(--surface-color),
      border:solid 2px var(--onbackground-primary),
      color:var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);

  &:hover {
    background: #f1f1ff;
    transform: scale(1.01);
    transition: all 1.5;
    $themes:(
      "alto-contraste":(
        background-color:var(--surface-color)
      )
    );
    @include ThemesProperties($themes);
  }

  &:active,
  &:focus {
    outline: none;
  }

  .icono-activo {
    transform: rotate(0.5turn);
    transition: all 1.5;
  }
}

.acordeon_active {
  border-left: 5px solid var(--secondary-color);
}

.opcionAcordeon {
  height: auto;
  transition: all 0.5s;
  background: var(--surface-color);
  color: var(--onbackground-primary);
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  z-index: 20;

  ol,
  ul {
    padding-inline-start: 20px !important;
  }

  ul {
    list-style: circle;
  }

  .instruccion-icon {
    width: 15px;
  }

  strong {
    font-size: 0.9em;
  }

  p,
  li {
    word-wrap: break-word;
    font-size: 0.9em;
    margin-bottom: 15px;
  }

  img {
    /*  image-rendering: -moz-crisp-edges; // Firefox */
    image-rendering: -o-crisp-edges; // Opera
    image-rendering: -webkit-optimize-contrast; // Webkit (non-standard naming)
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; // IE (non-standard property)
  }

  .enlace {
    font-size: 1em;
    text-decoration: underline;
    color: var(--blue-inverse);
    font-weight: 500;
    cursor: pointer;
    &:hover {
      font-weight: 500;
      color: var(--secondary-inverse);
    }
  }
}

@media only screen and (min-width: 576px) {
  .img-btn-descargar {
    width: auto;
  }
}

@media only screen and (min-width: 768px) {
  .instrucciones-app {
    width: 90%;
  }
}
