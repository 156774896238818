/*=====================================================
    CALENDARIO
    Este archivo define los estilos del componente 
    calendario
 ====================================================== */


:root {
  --calendario-primary-purple: #6666CC;
  --calendario-secondary-purple: #3c3caf;
  --calendario-shadow-purple: #9797ff;
  --calendario-today-purple: #5454f5;
  --calendario-hover-purple: #9797ff;
  --calendario-deselect-purple: #6666CC;
}
//===========================================================
//===========================================================
//===========================================================

.rmdp-container{
  .rmdp-input{
    background-color: var(--surface-color);
    padding-right: 1rem;
    $themes:(
      "alto-contraste":(
        color: var(--onbackground-primary),
        border:solid 2px var(--onbackground-primary)
      )
    );
    @include ThemesProperties($themes);

    &:focus-visible{
      outline: solid 2px var(--secondary-color) !important;
    }
  }
  svg{
    color: var(--secondary-inverse);
  }

  .rmdp-panel,.rmdp-wrapper{
    $themes:(
      "alto-contraste":(
        border:solid 2px var(--onbackground-primary)
      )
    );
    @include ThemesProperties($themes);
  }
}
.purple .rmdp-day.rmdp-deactive.rmdp-range{
  color:rgb(196, 183, 255);
  $themes:(
    "alto-contraste":(
      color: var(--surface-color)
    )
  );
  @include ThemesProperties($themes);
}

.purple .rmdp-wrapper {
  border: 1px solid var(--calendario-secondary-purple);
  box-shadow: 0 0 5px var(--calendario-secondary-purple);
}

.purple .rmdp-panel-body li {
  background-color: var(--calendario-primary-purple);
  box-shadow: 0 0 2px var(--calendario-secondary-purple);
}

.purple .rmdp-week-day {
  color: var(--calendario-primary-purple);
}

.purple .rmdp-day.rmdp-deactive {
  color: var(--calendario-secondary-purple);
}

.purple .rmdp-range {
  background-color: var(--calendario-primary-purple);
  box-shadow: 0 0 3px var(--calendario-shadow-purple);
}


.purple .rmdp-arrow {
  border: solid var(--calendario-primary-purple);
  border-width: 0 2px 2px 0;

}

.purple .rmdp-arrow-container:hover {
  background-color: var(--calendario-primary-purple);
  box-shadow: 0 0 3px var(--calendario-secondary-purple);
}

.purple .rmdp-arrow-container:hover  .rmdp-arrow{
    $themes:(
      "alto-contraste":(
        border: solid var(--surface-color),
        border-width: 0 2px 2px 0
      )
    );
    @include ThemesProperties($themes);
}

.purple .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--calendario-primary-purple);
}

.purple .rmdp-day.rmdp-today span {
  background-color: var(--calendario-today-purple);
  $themes:(
    "alto-contraste":(
      color: var(--surface-color)
    )
  );
  @include ThemesProperties($themes);
}

.purple .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--calendario-secondary-purple);
}

.purple .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--calendario-primary-purple);
  box-shadow: 0 0 3px var(--calendario-shadow-purple);
  $themes:(
    "alto-contraste":(
      color: var(--surface-color)
    )
  );
  @include ThemesProperties($themes);
}

.purple .rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--calendario-hover-purple) !important;
  $themes:(
    "alto-contraste":(
      color: var(--surface-color)
    )
  );
  @include ThemesProperties($themes);
}

.purple .b-deselect {
  color: var(--calendario-deselect-purple);
  background-color: white;
  $themes:(
    "alto-contraste":(
      background-color: var(--surface-color),
      color: var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);
}

.purple .rmdp-action-button {
  color: var(--calendario-primary-purple);
}

.purple .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--calendario-primary-purple);
}

.purple .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--calendario-deselect-purple);
}

//=========================================================
//=========================================================
//=========================================================

.bg-auto.rmdp-wrapper,
.bg-auto .rmdp-month-picker,
.bg-auto .rmdp-year-picker,
.bg-auto .rmdp-time-picker div input,
.rmdp-container .bg-auto.ep-arrow::after {
  $themes:(
    "alto-contraste":(
      background-color: var(--surface-color),
      color: var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);
}

.bg-auto .rmdp-day:not(.rmdp-deactive),
.bg-auto .rmdp-time-picker div .rmdp-am,
.bg-auto .rmdp-header-values,
.bg-auto .rmdp-panel-header {
  $themes:(
    "alto-contraste":(
      color: var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);
}

.bg-auto .rmdp-day.rmdp-range {
  $themes:(
    "alto-contraste":(
      color: var(--surface-color)
    )
  );
  @include ThemesProperties($themes);
}

.bg-auto .rmdp-panel-body li {
  $themes:(
    "alto-contraste":(
      color: var(--surface-color)
    )
  );
  @include ThemesProperties($themes);
}

.bg-auto .rmdp-day.rmdp-deactive,
.bg-auto .rmdp-day.rmdp-disabled {

  $themes:(
    "alto-contraste":(
      color: var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);
}

