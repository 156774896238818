/*===========================================================================================
  Footer
  En este archivo se definen los estilos del pie del página del sitio.
 ============================================================================================ */

.client-footer {
  $themes: (
    "alto-contraste": (
      background-color: var(--surface-color),
      border: solid 2px var(--onbackground-primary),
      color: var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);

  .brand {
    max-height: 70px;
  }

  .contact-data {
    font-weight: 700;
    p{
      overflow: hidden;
      white-space: nowrap;
    }
    
  }
}

.footer {
  background-color: #000;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 200px;


  @media screen and (min-width: 992px) {
    padding-bottom: 1.5rem;
  }

  $themes: (
    "clasico": (
      color: #fff,
      border-top: none
    ),
    "alto-contraste": (
      color: var(--onbackground-primary),
      border-top: solid 1.5px var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);
  font-size: 0.8em;

  .makemake-logo {
    max-width: 80px;
    height: auto;
  }

  .makina-logo {
    max-width: 100px;
    height: auto;
  }

  .social-media-button {
    transition:
      color 0.3s,
      transform 0.3s;
    $themes: (
      "clasico": (
        color: #fff
      ),
      "alto-contraste": (
        color: var(--onbackground-primary)
      )
    );
    @include ThemesProperties($themes);

    &:hover {
      transform: translateY(-5%);
    }

    @mixin socialMediaButton($color, $hover-color) {
      transition:
        color 0.3s,
        transform 0.3s;
      $themes: (
        "clasico": (
          color: $color
        ),
        "alto-contraste": (
          color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);

      &:hover {
        transform: translateY(-5%) scale(1.05);
        $themes: (
          "clasico": (
            color: $hover-color
          ),
          "alto-contraste": (
            color: var(--onbackground-primary)
          )
        );
        @include ThemesProperties($themes);
      }
    }

    &.appstore {
      @include socialMediaButton(var(#fff), #1bb9fa);
    }

    &.playstore {
      @include socialMediaButton(var(#fff), #01875f);
    }

    &.facebook {
      @include socialMediaButton(var(#fff), #0866ff);
    }

    &.instagram {
      @include socialMediaButton(var(#fff), #b831ac);
    }

    &.youtube {
      @include socialMediaButton(var(#fff), #ff0000);
    }

    &.whastapp {
      @include socialMediaButton(var(#fff), #2bb741);
    }
  }
  .footer-links{
    .link{
      transition: color .3s;
      $themes: (
        "clasico": (
          color: #fff
        ),
        "alto-contraste": (
          color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);
      &:hover{
        $themes: (
          "clasico": (
            color: var(--primary-inverse)
          ),
          "alto-contraste": (
            color: var(--onbackground-primary)
          )
        );
        @include ThemesProperties($themes);
      }
    }
  }

  .contact-card{
    font-size: pxToEm(16);
    .title{
      color: var(--red-inverse);
    }
    .info{
      $themes: (
        "clasico": (
          color: #E3E3F9
        ),
        "alto-contraste": (
          color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);
    }
  }

  .default-link{
    transition: color .3s;
    $themes: (
      "clasico": (
        color: #fff
      ),
      "alto-contraste": (
        color: var(--onbackground-primary)
      )
    );
    @include ThemesProperties($themes);
    &:hover{
      $themes: (
        "clasico": (
          color: var(--primary-inverse)
        ),
        "alto-contraste": (
          color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);
    }
  }
  .dropdown-arrow-transition{
    transition: transform .3s;
    &.expanded-dropdown{
      transform: rotate(180deg);
    }
    &.collapsed-dropdown-{
      transform: rotate(0);
    }
  }
  .divider{
    height: 1px;
    width: 100%;
    $themes: (
      "clasico": (
        background-color: #fff
      ),
      "alto-contraste": (
        background-color: var(--onbackground-primary)
      )
    );
    @include ThemesProperties($themes);
  }
}
