/*=====================================================
    TIENDA-PLAN-CASA

    En este archivo se definen los estilos de la 
    pestaña para /tienda/casa
 ====================================================== */

.read-flag {
    background-color: #000;    

    p {
        font-weight: 700;
        font-family: var(--secondary-font);
        color: var(--onsecondary-color);
    }
}

.plan-casa {
    background-color: #ffffff;
    background: url("/img/background.png") center/cover fixed repeat;

    .plCasa-breadcrums{
        display: flex;
        $themes : (
            "clasico": (
                background-color: #E3E3F9
            ),
            "alto-contraste": (
                 background-color: var(--primary-color)
            )
        );
        @include ThemesProperties($themes);
        
        .crumb{
            width: 50%;
            
            a{
                font-family: var(--secondary-font);
                text-transform: uppercase;
                font-size: .9em;
                font-weight: 700;
                padding: 5px 0;
                $themes : (
                    "clasico": (
                        color: #2E2E7C
                    ),
                    "alto-contraste": (
                        color: var(--onprimary-color)
                    )
                );
                @include ThemesProperties($themes);

            }
            

            &.current {
                border-bottom: 2px solid;
                $themes : (
                    "clasico": (
                        border-color: #2E2E7C
                    ),
                    "alto-contraste": (
                        border-color: var(--onprimary-color)
                    )
                );
                @include ThemesProperties($themes);
            }

            @media screen and (min-width: 526px) {
                width: auto;
            }
        }
    }

    .plCasa-tabpanel {
        position: sticky;
        top: 50px;
        z-index: 1000;

        $themes : (
            "clasico": (
                background-color: #E3E3F9
            ),
            "alto-contraste": (
                 background-color: var(--primary-color)
            )
        );
        @include ThemesProperties($themes);

        li {
            .tab {
                font-family: var(--secondary-font);
                text-transform: uppercase;
                font-size: .9em;
                font-weight: 700;
                padding: 5px 0;

                $themes : (
                    "clasico": (
                        color: #2E2E7C
                    ),
                    "alto-contraste": (
                        color: var(--onprimary-color)
                    )
                );
                @include ThemesProperties($themes);

                &.current {
                    border-bottom: 2px solid;

                    $themes : (
                        "clasico": (
                            border-color: #2E2E7C
                        ),
                        "alto-contraste": (
                            border-color: var(--onprimary-color)
                        )
                    );
                    @include ThemesProperties($themes);
                }
            }
        }
    }

    .PlanCard-box {
        background: var(--surface-color);
        border: 3px solid;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
        padding: 6px;
        margin-bottom: 40px;

        $themes : (
            "clasico": (
                border-color: #9772AE
            ),
            "alto-contraste": (
                border-color: var(--onprimary-color)
            )
        );
        @include ThemesProperties($themes);

        .column-left {
            border-radius: 20px;

            .img-card {
                border-radius: 20px 20px 0 0;
                height: 50%;
                min-height: 177px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;

                @media screen and (min-width: 768px) {
                    border-radius: 0 0 0 20px;
                }
            }
            
            @mixin cardColor($color: DarkGray) {
                $themes : (
                    "clasico": (
                        background: $color
                    ),
                    "alto-contraste": (
                        background: var(--onprimary-color)
                    )
                );
                @include ThemesProperties($themes);
            }

            &.libro-favorito-plan {
                background: var(--primary-inverse);
                color: var(--onprimary-inverse);
            }

            &.lecturas-makemake-plan {
                color: var(--onsecondary-inverse);
                @include cardColor($color: #EA5A18);
            }

            &.mi-coleccion-plan {
                color: var(--onsecondary-inverse);
                @include cardColor($color: #4A90E2);
            }

            &.card-blue {@include cardColor($color: #E4FFF8);}
            &.card-pink {@include cardColor($color: #FFEEF6);}
            &.card-green {@include cardColor($color: #F1FFDA);}

            .card-description {
                color: var(--onprimary-inverse);
          
                .general-description {
                  font-family: var(--secondary-font);
                  font-weight: 700;
                  text-transform: uppercase;
                  font-size: .6em;
                }
          
                h4 {
                  font-family: var(--secondary-font);
                  font-size: 1.5em;
                  line-height: 1;
                  margin: 10px 0;
                  width: 90%;
                }
          
                .creator {
                  font-family: var(--primary-font);
                  font-weight: 500;
                  font-size: .8em;
          
                  span {
                    font-family: var(--secondary-font);
                    font-size: 1em;
                    font-weight: 700;
                    $themes: (
                        "clasico": (
                            color: #EA3D4D
                        ),
                        "alto-contraste" : (
                            var(--onprimary-inverse)
                        )
                    );
                    @include ThemesProperties($themes);
                  }
                }
            }

        }

        .column-right {
            .btn-ver-catalogo {
                color: var(--violet-inverse);
                font-family: var(--secondary-font);
                text-decoration: underline;
                font-weight: 700;
                transition: all .3s;

                &:hover {
                    color: var(--green-inverse);
                }
            }

            .btn-trailer {
                min-width: 130px;
                i {
                    width: 84px;
                    height: 35px;
                    border-radius: 10px;
                    color: var(--surface-color);
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
            
                    $themes : (
                        "clasico": (
                            background: #81559D
                        ),
                        "alto-contraste": (
                            background: var(--onprimary-color)
                        )
                    );
                    @include ThemesProperties($themes);
                }
            
                p {
                  font-family: var(--secondary-font);
                  font-weight: 700;
                  font-size: 14px;
                  text-align: center;
                  text-decoration-line: underline;
            
                  $themes : (
                    "clasico": (
                        color: #81559D
                    ),
                    "alto-contraste": (
                        color: var(--onprimary-color)
                    )
                  );
                  @include ThemesProperties($themes);
                }
            
                &:hover {
                  i {
                    $themes : (
                        "clasico" : (
                            background: var(--secondary-inverse)
                        ),
                        "alto-contraste": (
                            background: var(--secondary-color),
                            border: 1px solid var(--secondary-inverse),
                            color: var(--secondary-inverse)
                        )
                    );
                    @include ThemesProperties($themes);
                  }
            
                  p {
                    $themes : (
                        "clasico" : (
                            color: var(--secondary-color)
                        ),
                        "alto-contraste": ( 
                            color: var(--secondary-inverse)
                        )
                    );
                    @include ThemesProperties($themes);
                  }
                }
            }

            .features-list {
                list-style: disc;
                font-size: .9em;
                padding-left: 30px;
                padding-right: 10px;
            
                li {
                  margin: 10px 0;
                  color: var(--onprimary-color);
                }
            }

        }

        .btn-tarjeta-plan {
            border-radius: 15px !important;
            box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.56);
            background: var(--primary-inverse);
            text-align: center;
            padding: 10px 20px;
            font-weight: 700;
            font-size: .9em;
            color: var(--onprimary-inverse);
            transition: all .5s;

            &:hover {
                background: var(--secondary-color);
                color: var(--onsecondary-color);
                box-shadow: none !important;

                $themes: (
                    "alto-contraste":(
                        border: 1px solid var(--onsecondary-color)
                    )
                );
                @include ThemesProperties($themes);
            }

        }
    }
}


.custom-plan{
    color: var(--onsecondary-color);
    $themes: (
        "clasico":(
            background-color: #262626,
        ),
        "alto-contraste":(
            background-color: var(--onprimary-inverse)
        )
    );
    @include ThemesProperties($themes);

    .custom-eyex-img{
        min-height: 200px;
        background-image: url('/img/tienda-makemake/plan-personalizado-thumb-2.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

}

@media screen and (min-width: 992px) {
    .read-flag {
        max-width: 500px;
        background-color: #00000000;
        background-image: url("/img/backs/black-flag.svg");
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;
    }
}