/*=====================================================
    MULTI-ACTION-BAR
    En este archivo se define los estilos de la barra
    de acciones múltiples.
 ====================================================== */

.multi-action-bar{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 30px;
    background-color: var(--secondary-inverse);
    transition: transform .3s, visibility .3s;
    padding: .25rem;
    z-index: 9999;

    &.visible{
       transform: translateY(0%);
       visibility: visible;
       
    }
    
    &.hidden{
        transform: translateY(-100%);
        visibility: hidden;

    }

    > .close-button{
        color: var(--onsecondary-inverse);
    }

    .content{
        flex-grow: 1;
        background-color: transparent;
    }

    .default-button{
        transition: color .3s, background-color .3s, transform .3s;
        border-radius: .5rem;
        transform: scale(0.9);
        &:hover{
            color: var(--secondary-inverse)!important;
            background-color: var(--onsecondary-inverse) !important;
            transform: scale(1);
        }
    }
}