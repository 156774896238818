/*=====================================================
    SPLASH-SCREEN
 ====================================================== */

.splash-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: var(--background-color);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    >img {
        width: 70%;
        min-width: 300px;
        max-width: 800px;
        $themes: (
            "alto-contraste":(background-color:var(--secondary-inverse))
        );

    @include ThemesProperties($themes);
}

>.spinner {
    margin: 1rem;
    color: var(--secondary-inverse);
}
}