@import "../abstracts/variables.scss";

/*=====================================================
                      VISOR
 ====================================================== */
.cover-visor {
  height: 100vh;
  min-height: 100vh;
  position: relative;
}

.visor {
  height: 100%;
  background: #e5e5e5;

  .footer-copyright {
    background: #1e1e1e;
    width: 100%;
    color: var(--onsecondary-color);
    font-size: 18px;
    z-index: 3;

    p {
      text-align: center;
    }
  }
}

.visor-header {
  background: #1e1e1e;
  color: #fff;
  font-size: 20px;
  font-family: $secondary-font;
  box-shadow: 0 1px 15px #9f9f9f;
  position: block !important;
  z-index: 3;

  .title {
    display: none;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    width: 100%;
    display: block;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
  }

  .btn {
    font-size: 18px;
    border-radius: 0.35rem;
  }

  p {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}

.size-font {
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }

  img {
    width: 49px;
    height: 42px;
  }
}

.tamano-texto {
  padding: 13px 40px;
  margin: 0;
  font-size: 17px;
  text-align: left;
}

.btn-descubre {
  position: absolute;
  top: 73px;
  left: 0;
  font-size: 0.85em !important;
}

.descubre-container {

  height: 100%;
  width: 90%;
  max-width: 420px;
  position: absolute;
  z-index: 101;
  top: 0;
  right: -100%;
  transition: all 0.3s ease;

  $themes: ("clasico":(background: var(--onbackground-primary) url("/img/background.png")),
    "alto-contraste":(background: var(--background-color) url("/img/background.png")),
  );

  @include ThemesProperties($themes);

  .descubre {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  h3 {
    text-transform: uppercase;
    color: var(--onsecondary-color);
    border-bottom: 2px solid var(--onsecondary-color);
  }

  .card {
    background: var(--surface-color);
    border-radius: 0.5rem;

    $themes: ("clasico":(border:none),
      "alto-contraste":(border: 1px solid var(--onsecondary-color)));

    @include ThemesProperties($themes);

    p {
      line-height: 1.3;
    }
  }
}

.link-descubre {

  &:hover {
    transform: scale(1.01);
    display: block;
    $themes: ("clasico":(color: #0056b3),
      "alto-contraste":(color: var(--onsecondary-color),
      ));

    @include ThemesProperties($themes);
  }
}

.appear-container {
  right: 10px !important;
}

.mb-bookvisor-container {
  width: 100%;
  background-color: #e5e5e5;
  border: none !important;

  iframe {
    border: none !important;
  }
}

.mb-bookvisor-container-h{
  height: 85%;
}
.mb-bookvisor {
  width: 100%;
  overflow: hidden;

  button {
    &:first-child {
      border-radius: 0.25rem !important;
      background-color: #000 !important;

      span {
        background: #fff !important;
      }
    }
  }
}

.copy-terms {
  display: none;
}

.close-descubre {
  position: absolute;
  top: 0;
  right: 15px;
}

.iframeClass {
  height: 0;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 0;
}

.title-controls {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
}

.settings-button {
  background-color: black;
  color: white;
  padding: 0 4px;

  &:hover {
    color: #fff;
  }
}

.font-size-modal {
  position: absolute;
  right: 1px;
  top: 49px;
  z-index: 10;
  background: #6b6b6b;
  border-radius: 5px 0px 0px 30px;
  color: #fff;

  .titulo-texto {
    position: relative;
    margin: 0;
    padding: 6px;
    text-align: center;
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px 3px 0 0;

    h3 {
      font-size: 22px;
      font-weight: 300;
    }

    i {
      position: absolute;
      font-size: 1.3em;
      right: 10px;
      cursor: pointer;
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba($color: #fff, $alpha: 0.8);
}

.moda_tipo_visor{
  width: 90vw !important;
  max-width: 650px !important;
  max-height: 90vh !important;

  h2{
    color: var(--secondary-inverse);
    font-family: var(--secondary-font);
    font-weight: 700;
    font-size: 1.2em;
    line-height: 1.4em;
  }
  
}

.fullscreen-enabled {
  .mb-bookvisor {
    margin: 0px !important;
    height: 100% !important;
    width: 100% !important;
  }
}

.cont-activo {
  width: 80%;
  border: 3px solid var(--onbackground-third);

  .btn-recomendado {
    display: block !important;
  }
}

.cont-inactivo {
  width: 0 !important;
  border: none;

  .btn-recomendado {
    display: none !important;
  }
}

.cont-recomendados {
  width: 80%;
  height: 100px;
  border-radius: 0 0 0 5px;
  position: absolute;
  bottom: 5px;
  right: 0px;
  transition: all 0.3s ease;

  .btn-active-recomendado {
    position: absolute;
    top: -3px;
    left: -26px;
    width: 25px;
    height: 50px;
    border-radius: 5px 0 0 5px;
    background: var(--primary-inverse);
    font-size: 20px;
  }

  .btn-recomendado {
    padding-right: 110px;
    padding-left: 12px;
    height: 100%;
    font-size: 16px;
    color: var(--onsecondary-color);
    background-image: url("/img/visor-mas.png");
    background-repeat: no-repeat;
    background-position: 90%;
    background-size: contain;
    font-family: var(--secondary-font);

    $themes: (
      "clasico":(background-color: var(--onbackground-primary)),
      "alto-contraste":(background-color: var(--background-color)),
    );
    @include ThemesProperties($themes);

    &:hover {
      font-weight: 500;
      background-position: 88%;
    }
  }
}

@media only screen and (min-width: 360px) {
  .visor-header {
    .title {
      display: flex;
      justify-content: space-between;
      h2, p{width: 40%;}
      p{text-align: end;}
    }
  }
}

@media only screen and (min-width: 500px) {
  .cont-recomendados, .cont-activo {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) {
  .copy-terms {
    display: inline-block;
  }
  .mb-bookvisor-container-h{
    height: 90%;
  }
  .visor-header {
    .title {
      width: 200px;
      height: 50px;
      display: block;
      h2, p{width: auto;}
      p{text-align: start;}
    }
  }
}

@media only screen and (min-width: 992px) {
  .btn-descubre {
    position: relative;
    top: 0;
    left: 0;
  }
}