.section-libros{
    .preview-button-option {
        border-radius: 999999px;
        transition:
          color 0.3s,
          background-color 0.3s,
          transform 0.3s;
        $themes: (
          "clasico": (
            background-color: #eaeaff,
            color: var(--onbackground-primary)
          ),
          "alto-contraste": (
            background-color: var(--background-color),
            color: var(--onbackground-primary),
            border: solid 1px var(--secondary-inverse)
          )
        );
      
        @include ThemesProperties($themes);
      
        
      
        &:hover:not(.selected) {
          transform: translateY(-5%);
          $themes: (
            "clasico": (
              background-color: var(--secondary-inverse),
              color: var(--onsecondary-inverse)
            ),
            "alto-contraste": (
              background-color: var(--secondary-inverse),
              color: var(--onsecondary-inverse),
              border: solid 1px var(--secondary-inverse)
            )
          );
      
          @include ThemesProperties($themes);
          background-color: var(--secondary-inverse);
          color: var(--onsecondary-inverse);

        }
      }
}

.newsletter-cta {
  h2{
    font-size: 1.2em;
  }
  $themes: (
    "clasico": (
      background-color: #e3e3f9
    ),
    "alto-contraste": (
      background-color: var(--background-color)
    )
  );

  @include ThemesProperties($themes);
  .shape {
   /*  position: absolute; */
    background-image: url("/img/que-es/home/newsletter/newsletter-speaker.png");
    background-position: 0%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 200px;
    height: 200px;
    margin: auto;

    @media (min-width: 992px) {
      width: 465px;
      height: 332px;
    }

    @media (min-width: 1200px) {
      width: 555px;
      background-size: cover;
      background-position: 5% -10%;
    }

    @media (min-width: 1500px) {
      width: 670px;
      height: 266px;
      background-size: 75%;
      background-position: 5% 12%;
    }
  }
}