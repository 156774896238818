/*=====================================================
    DROPDOWN
    En este archivo se encuentran los estilos del 
    dropdown
 ====================================================== */

.dropdown-component{
    position: relative;
    width: 100%;
    background-color: #e0e0f5;
    color: var(--secondary-color);
    border-radius: .5rem;
    transition: border-radius .3s;
    z-index: 100;
    $themes:(
        "alto-contraste":(
            background-color: var(--surface-color),
            border:solid 2px var(--onbackground-primary),
            color:var(--onbackground-primary)
        )
    );
    @include ThemesProperties($themes);

@media (min-width: 576px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
   width: 300px;
 }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

 }


    .dropdown-button{
        display: grid;
        grid-template-columns: 1fr max-content;
        background-color: inherit;
        align-items: center;
        color: inherit;
        width: 100%;
        min-width: 100%;
        height: 100%;
        padding: .5rem;
        outline: none !important;
        border: none !important;
        border-radius: inherit;
        font-weight: bold;


    &:focus{
        outline: none !important;
        border: none !important;
    }

    span{
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        justify-self: flex-start;
    }
    
    .caret{
        transition: transform .3s;
        flex-grow: 1;
        }
    }

    .dropdown-content{
        position: absolute;
        top:100%;
        left:0;
        width: 100%;
        max-height: 200px; 
        background-color: var(--surface-color);
        box-shadow: 0 5px 10px 2px rgba(0,0,0,0.25);
        color: var(--onbackground-primary);
        border-bottom-left-radius: .5rem;
        border-bottom-right-radius: .5rem;
        overflow: auto;
        padding: 0;
        transform-origin: 0 0;
        transition:  transform .3s; 
        -moz-transition: none;
        $themes:(
            "alto-contraste":(
                background-color: var(--surface-color),
                border:solid 2px var(--onbackground-primary),
                color:var(--onbackground-primary)
            )
        );
        @include ThemesProperties($themes);

        .dropdown-item{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            transition: color .3s, background-color .3s;
            padding: .5rem;

            &:hover{
                background-color:#e0e0f5;
                color:var(--secondary-inverse);
                $themes:(
                    "alto-contraste":(
                        background-color: var(--onbackground-primary),
                        color:var(--surface-color)
                    )
                );
                @include ThemesProperties($themes);
            }
        }

        .dropdown-item-separator{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            padding: .5rem;
            color: var(--secondary-inverse);
            font-weight: bold;
            border-bottom: solid 4px var(--secondary-inverse);
            transition: color .3s, background-color .3s;
        }
    }

    &.visible{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.visible .dropdown-content{
        transform: scaleY(1);
    }
    &.hidden .dropdown-content{
        transform: scaleY(0);
    }
    &.visible .caret{
        transform: rotate(180deg);
    }
    &.hidden .caret{
        transform: rotate(0deg);
    }
}