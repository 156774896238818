/*=====================================================
    Estilos de los formularios de eventos
 ====================================================== */

.event-banner {
    width: 100%;
    height: 165px;

    .container {
        height: 100%;
        overflow: hidden;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.event-back {
    background: url("/img/background.png") center/cover fixed repeat;

    .event-form {
        width: 100%;
        min-height: 200px;
        background-color: var(--surface-color);
        max-width: 1000px;
        margin: auto;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
        position: relative;
        
        $themes:(
            "alto-contraste":(
                border:solid 2px var(--onbackground-primary)
            )
        );
        @include ThemesProperties($themes);

        .flag-event {
            @media screen and (min-width: 992px) {
                position: absolute;
                right: -40px;
                top: 0;
                width: 250px;
                height: 100px;

                .text-flag {
                    position: absolute;
                    right: 40px;
                    top: 40px;

                    p {
                        width: 90px;
                    }
                }
            }
        }

        .event-header {
            $themes:(
                "alto-contraste":(
                    border-bottom:solid 2px var(--onbackground-primary)
                )
            );
            @include ThemesProperties($themes);

            @media screen and (min-width: 992px) {
                h1 {
                    max-width: 700px;
                    line-height: 1.1em
                }

            }

            .times-arrow{
                transition: all .5s;
                &.visible {  
                    transform: rotate(180deg);
                }
                &.hidden {
                    transform: rotate(0deg);
                }
           
            }

            .eventHeader-timeZones{
                transition: opacity 0.6s, all .5s;
          
                &.visible {    
                    border-top: 1px solid;   
                    padding: 5px 0 0 10px;   
                    pointer-events: all;
                    height: 170px;
                    li{
                        visibility: visible;
                    }
                }
                &.hidden {
                    border-top: none !important;
                    padding: 0;
                    pointer-events: none;
                    height: 0;
                    li{
                        visibility: hidden;
                    }
                }
            }
        }

        @mixin btn-event{
            width: 100%;
            background-color: var(--primary-color);
            color: var(--onprimary-color);
            font-family: var(--primary-font);
            font-size: 1em;
            font-weight: 600;

            $themes: (
                "clasico":(
                    border: none,
                ),
                "alto-contraste":(
                    border: 1px solid var(--onsecondary-color),
                )
            );
            @include ThemesProperties($themes);

            &:hover {
                background-color: var(--secondary-inverse);
                color: var(--onsecondary-inverse);
            }
        }

        .btn-inscribirse{
           @include btn-event();
           height: 100%;
        }

        .btn-ver-evento{
            display: block;
            max-width: 200px;
            margin: auto;
            @include btn-event();
         }

         
    }
}

.modal-events{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.25);
    padding: 20px;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    box-sizing: border-box !important;

    $themes: (
        "clasico":(
            background: #f1f1ff,
        ),
        "alto-contraste":(
            background: var(--background-color),
        ),
    );

    @include ThemesProperties($themes);
 }