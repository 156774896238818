/*===========================================================================================
  Podemos ayudar
  En este archivo se realizaron los estilos para el formulario de podemos ayudar. Este 
  formulario ubicado en las paginas del ¿qué es? y home
 ============================================================================================ */

.seccion-gris {
  background-color: var(--background-color);
}

.titulo-podemosAyudar {
  font-family: $secondary-font;
  font-size: 1.4em;
  font-weight: 700;
}

.imagenAyudar {
  width: 90%;
}
.link-agendar {
  transition: all .3s;
  text-decoration: underline;
  &:hover{
    text-decoration: none;
    transform: scale(1);
  }
}

.campos-formulario {
  width: 100%;
  padding: 0;
  input, select{
    $themes:(
      "clasico":(
        background-color:var(--surface-color),
        color:var(--onbackground-primary),
        ),
        "alto-contraste":(
        background-color:var(--surface-color),
        color:var(--onbackground-primary),
        border:solid 2px var(--onbackground-primary),
        outline:none
      )
    );
    @include ThemesProperties($themes);
  }
}

.validacion-campo-error {
  color: var(--red-inverse);
  font-size: 0.8em;
  font-weight: 900;
  padding-top: 0.3rem;
  line-height: 0.9em !important;
  font-family: var(--secondary-font);
}

.input-error {
  border-color: var(--red-inverse);
  border-width: 2px;
}

@media (min-width: 768px) {
  .imagenAyudar {
    width: 400px;
  }
}

@media (min-width: 992px) {
  .campos-formulario {
    width: 50%;
    padding: 0 10px;
  }
}

@media (min-width: 1200px) {
  .container-podemos-ayudar{
    margin: auto;
    max-width: 1500px;
  }
}

@media (min-width: 1600px) {
  .campos-formulario {
    padding: 0 10px;
  }
}
