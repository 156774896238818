/*=====================================================
    MI-CUENTA-MENU
    Este archivo define los estilos del menú de 
    mi cuenta
 ====================================================== */

.wrapper-mi-cuenta {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.384);
  position: fixed;
  top: 55px;
  left: 0;

  &.visible {
    animation: mmfadeIn 0.5s cubic-bezier(0, 0, 0.2, 1);
    opacity: 1;
    height: 100vh;
  }

  &.hidden {
    opacity: 1;
    height: 0px;
    animation: mmfadeIn 0.5s cubic-bezier(0, 0, 0.2, 1);
  }

  .menu-mi-cuenta {
    background-image: url('/img/backs/back1.png');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    -webkit-box-shadow: 0px 12px 25px -8px rgba(61,61,61,1);
    -moz-box-shadow: 0px 12px 25px -8px rgba(61,61,61,1);
    box-shadow: 0px 12px 25px -8px rgba(61,61,61,1);
    border-radius: 0px 0px 10px 10px;
    width: 238px;
    /* -webkit-transition: height .3s, opacity .3s, pointer-events .3s, visibility .1s;
    -o-transition: height .3s, opacity .3s, pointer-events .3s, visibility .1s;
    transition: height .3s, opacity .3s, pointer-events .3s, visibility .1s; */
    position: absolute;
    right: 10px;
    transition: all .5s;

    $themes:(
      "clasico":(
        background-color: #DCDDED
      ),
      "alto-contraste":(
        background-color: var(--background-color)
      )
    );
    @include ThemesProperties($themes);

    &.visible {
      display: block;
    }

    &.hidden {
      display: none;
    }

    .btn-menu-cuenta{
      display: flex;
      justify-content: space-between;
      padding: 12px 30px;
      text-align: left;
      font-weight: 600;
      color: var(--onprimary-color);

      .icon-cuenta{
        width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .i-favoritos{
        $themes:(
          "clasico":(
            background-image: url('/img/icons/favorito.svg')
          ),
          "alto-contraste":(
            background-image: url('/img/icons/favorito-contraste.svg')
          )
        );
        @include ThemesProperties($themes);
      }

      .i-descargas{
        $themes:(
          "clasico":(
            background-image: url('/img/icons/descarga.svg')
          ),
          "alto-contraste":(
            background-image: url('/img/icons/descarga-contraste.svg')
          )
        );
        @include ThemesProperties($themes);
      }

      .i-panel{
        $themes:(
          "clasico":(
            background-image: url('/img/icons/panel.svg')
          ),
          "alto-contraste":(
            background-image: url('/img/icons/panel-contraste.svg')
          )
        );
        @include ThemesProperties($themes);
      }

      &:hover{
        color: var(--secondary-inverse);
        .i-favoritos{
          $themes:(
            "clasico":(
              background-image: url('/img/icons/favorito-active.svg')
            )
          );
          @include ThemesProperties($themes);
        }

        .i-descargas{
          $themes:(
            "clasico":(
              background-image: url('/img/icons/descarga-active.svg')
            )
          );
          @include ThemesProperties($themes);
        }

        .i-panel{
          $themes:(
            "clasico":(
              background-image: url('/img/icons/panel-active.svg')
            )
          );
          @include ThemesProperties($themes);
        }
      }
    }
  }

  @keyframes mmslideIn {
    from { transform: translateY(-15%); }
    to { transform: translateY(0); }
  }
  
  @keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
  }
  .menu-mi-cuenta[aria-hidden="true"]{
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  .menu-mi-cuenta[aria-hidden="false"]{
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  .btn-cerrar-sesion{
    padding: 12px 30px;
    color: var(--onprimary-color);
    font-weight: 600;
    width: 100%;

    &:hover{
      color: var(--secondary-inverse);
    }
  }
}


@keyframes mmfadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes mmfadeOut {
  from {opacity: 1}
  to { opacity: 0 }
}

