.club-makemake {
  .content-header {
    position: relative;

    .title,
    .description {
      $themes: (
        "clasico": (
          color: #fff
        ),
        "alto-contraste": (
          color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);
    }

    .texture {
      position: absolute;
      left: 50%;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-50%);
      overflow: hidden !important;

      .texture1 {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .texture2 {
        position: absolute;
        top: 0;
        right: 0%;
        height: 100%;
      }
    }
  }

  .reading-plans {
    .book-card {
      border-radius: 1rem;
      overflow: hidden;
      .title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
      }
      .img {
        border-radius: 1rem;
      }
      .description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
      }
    }
    .past-reading-plan-card {
      border-radius: 1rem;
      transition:transform 0.3s;

      &:hover {
        transform: translateY(1%);
        .img {
          box-shadow: 0px 10px 8px 0 rgba(0, 0, 0, 0.3);
        }
      }
      .img {
        border-radius: 1rem;
        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.4);
        transition:box-shadow 0.3s;
      }
    }
  }

  .makemake-live {
    .past-event-card-container {
      .past-event-card {
        position: relative;
        max-width: 522px;
        transition:transform 0.3s;

        &:hover {
          transform: translateY(1%);
          .img {
            box-shadow: 0px 10px 8px 0 rgba(0, 0, 0, 0.3);
          }
          .hover-effect {
            opacity: 1;
          }
        }
        .img {
          box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.4);
          transition:box-shadow 0.3s;
        }

        .img,
        .hover-effect {
          border-radius: 1rem;
        }
        .hover-effect {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #6060c199;
          pointer-events: none;
          transition: opacity 0.3s;
          .play-button {
            border-radius: 99999px;
          }
        }
      }
      .title {
        font-weight: 700;
      }
    }
    .event-card {
      overflow: hidden;

      .event-type{
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--secondary-inverse);
        color: var(--onsecondary-inverse);
        border-radius: .5rem;
        font-weight: 700;
      }
      .share {
        .copy-button {
          color: var(--onbackground-primary);
          transition: color 0.3s;
          &:hover {
            color: var(--secondary-inverse);
          }
        }
      }
      .schedule-event {
        .schedule-event-link {
          font-weight: 600;
          transition: color 0.3s;
          &:hover {
            color: var(--secondary-inverse);
          }
        }
        .office-365 {
          color: #ff7b00;
        }
        .yahoo {
          color: #4400ff;
        }
      }
      .event-layer {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition:
          opacity 0.3s,
          transform 0.3s;

        &.visible {
          opacity: 1;
          pointer-events: all;
          transform: translateX(0%);
        }
        &.hidden {
          opacity: 0;
          pointer-events: none;
          transform: translateX(-100%);
        }
      }
      .img{
        width: 100%;
        min-height: 100px !important;
        max-height: 100px !important;
        object-position: center center;
        object-fit: cover;
      }

      border-radius: 1rem;
      box-shadow:
        0 0px 5px 0 rgba(0, 0, 0, 0.25),
        0 5px 5px 0 rgba(0, 0, 0, 0.2);
      $themes: (
        "clasico": (
          background-color: #f1f1f1
        ),
        "alto-contraste": (
          background-color: var(--background-color)
        )
      );
      @include ThemesProperties($themes);

      .title {
        font-weight: 700;
        color: var(--secondary-inverse);
      }
      .guests {
        font-weight: 600;
      }
      .description {

      }
      .youtube-message {
        font-weight: 600;
        $themes: (
          "clasico": (
            color: #808080
          ),
          "alto-contraste": (
            color: var(--onbackground-primary)
          )
        );
        @include ThemesProperties($themes);
      }
      .date-info-container {
        position: relative;
      }

      .date-info-container,
      .lazy-image,
      .event-layer,
      .img-container {
        $themes: (
          "clasico": (
            background-color: #e3e3f9
          ),
          "alto-contraste": (
            background-color: var(--background-color)
          )
        );
        @include ThemesProperties($themes);
      }
    }

    .light-button {
      cursor: pointer;
      background-color: var(--onsecondary-inverse);
      color: var(--secondary-inverse);
      font-weight: 600;
      transition:
        color 0.3s,
        background-color 0.3s;

      &:hover {
        background-color: var(--secondary-inverse);
        color: var(--onsecondary-inverse);
      }
    }
    .watch-button {
      @extend .light-button;
      background-color: var(--primary-inverse);
      color: var(--onprimary-inverse);
    }
  }

  .blog-section {
    .blog-post-card {
      position: relative;
      max-width: 382px;
      border-radius: 1rem;
      overflow: hidden;
      box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.4);
      color: var(--onbackground-primary) !important;
      transition:
        transform 0.3s,
        box-shadow 0.3s;
      $themes: (
        "clasico": (
          background-color: #f1f1f1
        ),
        "alto-contraste": (
          background-color: var(--background-color)
        )
      );
      @include ThemesProperties($themes);
      &:hover {
        transform: translateY(1%);
        box-shadow: 0px 10px 8px 0 rgba(0, 0, 0, 0.3);
      }
      .title,
      .description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
      }
      .title {
        -webkit-line-clamp: 2;
      }
      .description {
        -webkit-line-clamp: 3;
      }
    }
  }

  .resources-section {
    .resource-shape {
      position: absolute;
      top: 0;
      left: 0;
    }
    .cta-container {
      position: relative;
      .float-button {
        position: absolute;
        width: 100%;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -75%);
      }
    }
  }
}
