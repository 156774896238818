/*===========================================================================================
  plector-datosestudiante

  >**NOTA:** se debe revisar si estas clases se usan en el proyecto
 ============================================================================================ */


.datos-estudiante-imagen {
  width: 150px;
  height: 150px;
  background-size: contain;
  background-image: url("/img/avatares/avatar1.png");
}

.codigo-estudiante {
  font-size: 1.6em;
  font-weight: 700;
}

.btn-cambiarimagen {
  font-size: 0.8em;
  background-color: var(--background-color);
  font-weight: 700;
  &:hover {
    @extend .noutline;
  }
  &:focus {
    @extend .noutline;
  }
  &:active {
    @extend .noutline;
  }
}

.btn-cambiarcodigo {
  font-size: 0.8em;
}
