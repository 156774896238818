/*=======================================
    Aquí se generan los estilos de la sección de recursos de mi panel
=========================================*/

.header-recursos {
  color: var(--onviolet-color);
  font-weight: 600;

  $themes:(
    "clasico":(
      border:none,
      background: #6680e3,
    ),
    "alto-contraste":(
      border: 1px solid var(--violet-color),
      background: var(--third-color),
    )
  );

  @include ThemesProperties($themes);
}

.resource-content {
  h4 {
    $themes:(
      "clasico":(
        color: #ff7f50,
      ),
      "alto-contraste":(
        color: var(--onbackground-primary),
      ),
    );

    @include ThemesProperties($themes);
  }

  img {
    border-radius: 5px;
  }
  div {
    order: 16;
  }
  .btn-eliminar {
    order: 3;
  }
  .btn-leer {
    order: 1;
  }
}

.informacion-principal{
  font-weight: 700;
  font-size: 0.9em;

  $themes: ("clasico":(color: #6A6969,
  ),
  "alto-contraste":(color: var(--violet-inverse),
  ));

@include ThemesProperties($themes);
}

.contenedor-principal{
  border-top: none;
}

.contenedor-recursos{
  border-top: 1px solid;
  $themes: ("clasico":(border-top-color: #979797,
  ),
  "alto-contraste":(border-top-color: var(--violet-inverse),
  ));

  @include ThemesProperties($themes);
}

.contenedor-individual{
  border-top: 1px solid;
  $themes: ("clasico":(border-top-color: #979797,
  ),
  "alto-contraste":(border-top-color: var(--violet-inverse),
  ));

  @include ThemesProperties($themes);
}

.contenedor-imagen{
  width: 175px;
  height: 272px;
  background-color: #efefef;
}
.text-titulo{
  font-size: 1.2em;
  
}
.text-subtitulo{
  font-weight: 700;
  font-size: 0.9em;
  $themes: ("clasico":(color: #979797,
  ),
  "alto-contraste":(color: var(--violet-inverse),
  ));

@include ThemesProperties($themes);
}
.text-content{
  font-size: 0.9em;
}

.text-palabras{
  font-family: var(--primary-font);
  font-size: 0.9em;
}

.btn-primary-recursos {
  font-family: var(--primary-font);
  font-weight: 700;
  font-size: 1em;
  transition: 0.2s;
  border-radius: 0.375rem;
  background: var(--primary-inverse);
  color: var(--onprimary-inverse);
  line-height: 1.2;
  @extend .noutline;

  $themes: ("clasico":(border: none,
    ),
    "alto-contraste":(border: 1px solid var(--onsecondary-color),
    ));

  @include ThemesProperties($themes);

  &:hover {
    color: var(--onviolet-color);
    transform: scale(1.01);
    @extend .noutline;

    $themes: ("clasico":(border: none,
    background-color: #6666cc,
    ),
    "alto-contraste":(border: 1px solid var(--onsecondary-color),
    background-color: var(--violet-color),
    ));

  @include ThemesProperties($themes);
  }

  &:focus {
    @extend .noutline;
    color: var(--onviolet-color);

    $themes: ("clasico":(border: none,
    background: #6666cc,
    ),
    "alto-contraste":(border: 1px solid var(--onsecondary-color),
    background-: var(--violet-color),
    ));

  @include ThemesProperties($themes);
  }

  &:active {
    background: var(--primary-color) !important;
    transform: scale(0.95);
    @extend .noutline;

    &:focus {
      box-shadow: none !important;
    }
  }

  &:visited {
    @extend .noutline;
  }
}

.icon-descargar{
  color: var(--onbackground-third--color);

  &:hover {
    color: var(--onviolet-color);
    @extend .noutline;
  }

  &:focus {
    @extend .noutline;
    color: var(--onviolet-color);
  }

}

.imagen-estilo{
  box-shadow: -1px 3px 5px rgba(0, 0, 0, 0.6);
}

/* .skeleton-recursos{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
} */

@media only screen and (min-width: 576px) {
  .resource-content {
    div {
      order: 1;
    }
    .btn-eliminar {
      order: 3;
    }
    .btn-leer {
      order: 2;
    }
  }
}



@media (min-width: 1200px) {
  .contenedor-individual{
    border-top: none;
  }
  .contenedor-principal{
    border-top: 1px solid;
    $themes: ("clasico":(border-top-color: #979797,
    ),
    "alto-contraste":(border-top-color: var(--violet-inverse),
    ));
  
    @include ThemesProperties($themes);
  }
}