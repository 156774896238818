/*=======================================
    DASHBOARD MENU
    Este archivo genera los estilos del menú de mi panel.
=========================================*/
@mixin navButton() {
  border-bottom: 2px solid var(--onbackground-fourth);
  background: var(--surface-color);
  color: var(--onbackground-primary);
  cursor: pointer;
  line-height: 1;
  text-align: center;
  width: 100%;

  //transition: all .2s ease-in-out;
  &:hover {
    font-weight: 700;
    text-decoration: none;
  }

  &:focus-visible {
    border: 2px solid var(--red-inverse);
    outline: none !important;
  }
}

.nav-menu {
  overflow: hidden;
  box-shadow: 0 1px 8px rgba(159, 159, 159, 0.6);
  transition: all 0.4s ease-in-out;

  .nav-button{
    @include navButton;
    height: 100%;
  }

  .nav-button-2{
    @include navButton;
  }

  a,
  button {
    color: var(--onbackground-primary);
    transition: 0.2s;

    &:hover {
      font-weight: 700;
      text-decoration: none;
    }
  }
}

.nav-menu-opciones-movil {
  transition: 0.4s all;
}

.nav-menu--panel {
  width: 100%;
  display: block;
  a {
    color: var(--onbackground-primary);
    transition: 0.2s;

    &:hover {
      font-weight: 700;
      text-decoration: none;
    }
  }
}

.nav-menu--active {
  display: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--secondary-inverse) !important;
  font-weight: 700;
  color: var(--surface-color) !important;
  border: none !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;

  &:focus-visible {
    border: 2px solid var(--red-inverse) !important;
    outline: none !important;
  }

  a,
  button {
    color: white;

    &:hover {
      text-decoration: none;
    }
  }
}

.nav-menu--responsive {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--secondary-inverse) !important;
  font-weight: 700;
  color: var(--surface-color);
  position: relative;
  text-align: center;
  padding: 5px 0 !important;
  cursor: pointer;

  div {
    transition: all 0.4s ease-in-out;
    position: absolute;
    width: 0;
    height: 0;
    right: 20px;
    margin: auto;
    top: 0;
    bottom: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  i {
    right: 1rem;
    line-height: 0;
    top: 1rem;
  }
}

.slideDown {
  height: 220px;
}

.slideDownMin {
  height: 60px;
}

.arrowUp {
  border-top: 0 !important;
  border-bottom: 8px solid #fff;
}

.nav-menu-noresponsive {
  background: none !important;
  height: auto !important;
  box-shadow: none !important;

  .nav-button  {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 2px solid var(--onbackground-fourth);
    border-left: 2px solid var(--onbackground-fourth);
    border-right: 2px solid var(--onbackground-fourth);
    border-bottom: none;
  }

  .nav-menu--active {
    display: block !important;
  }
}

@media only screen and (min-width: 992px) {
  .nav-menu {
    height: auto;
    box-shadow: none;

    .nav-button {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-top: 2px solid var(--onbackground-fourth);
      border-left: 2px solid var(--onbackground-fourth);
      border-right: 2px solid var(--onbackground-fourth);
      border-bottom: none;
    }
  }

  .nav-menu--active {
    display: block;
  }

  .nav-menu--responsive {
    display: none;
  }
}