/*=====================================================
    PLANES-INDIVIDUAL

    En este archivo se encuentra el estilo de la 
    tarjeta de plan de lecturas y el layout que tienen 
    en común las secciones de mi colección, mi libro 
    favorito y lectura makemake
 ====================================================== */

@use "sass:map";

.plan-lectura-card {
    position: relative;
    border: solid 3px transparent;
    background-color: var(--surface-color);
    color: var(--onsurface-primary);
    border-radius: 2rem;
    border: solid 1px var(--onbackground-fourth);
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: .5rem;
    margin-top: .5rem;
    overflow: hidden;

    &.selected {
        background-color: rgba(178, 174, 27, 0.2);
        border-color: #B2AE1B;
        box-shadow: 0 0 10px 0 #B2AE1B;
        $themes:(
            "alto-contraste":(
                border-color: var(--secondary-inverse),
                box-shadow: 0 0 10px 0 var(--secondary-inverse),
                background-color: var(--surface-color)
                )
            );
        @include ThemesProperties($themes);

        *{
            filter: grayscale(0%);
        }
    }

    &.selected > .section-1, &.selected > .section-2{
        background-color: transparent;
        border-color: inherit;

    }

    > .checkbox-wrapper{
        position: absolute;
        top: -4px;
        right: 0;
        z-index: 1;
        padding: 0;
        .checkbox{
            font-size: 2em;
            padding: 0;
            margin: 0;
            &:before {
                border: 2.5px solid var(--red-inverse);
                background: var(--surface-color);
            }

            &:checked::before {
                border: 2.5px solid var(--red-inverse);
                background: transparent;
                background: var(--red-inverse);
            }


            &:checked::after {
                color: var(--onred-inverse);
            }

            &.focus:focus-visible::before {
                outline: solid 2px var(--primary-inverse);
            }

        }
    }

    > .section-1, > .section-2{
        padding: 2rem;
    }
    > .section-1{
        max-width: 400px;
        border-right: solid 2px var(--onbackground-fourth);
        color: var(--onbackground-primary);
        >.description, >.title{
            font-weight: bold;
        }
        .list{
            list-style-type: square;
        }
    }

    > .section-2{
        overflow: hidden;
        background-color: var(--background-color);
        > .swipper-buttons-container{
            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding: .25rem;
            > .swipper-button{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background-color: var(--onbackground-primary);
                color:var(--surface-color);
                cursor: pointer;
                transition: color .3s, background-color .3s;
                &:hover{
                    background-color: var(--secondary-inverse);
                    color:var(--onsecondary-inverse);
                }
            }
        }
        > .books-swipper{
            width: 500px;
        }
   
            .libro-tarjeta {
                margin: 0 !important;
                &> .titulo{
                    font-size: .7em;
                }
            }
    }

    .selector-button{
        padding: 1rem;
        border-radius: 1rem;
        width: fit-content;
        height: fit-content;
        font-weight: bold;
        background-color: var(--secondary-inverse);
        color: var(--onsecondary-inverse);
        cursor: pointer;
        transition: background-color .3s, color .3s;
        &.selected{
            background-color: var(--primary-inverse);
            color: var(--onprimary-inverse);
        }
    }

    .download-pdf-button{
        width: fit-content;
        height: fit-content;
        padding: 1rem;
        font-weight: bold;
        background-color: var(--onsecondary-inverse);
        color: var(--secondary-inverse);
        border: solid 2px var(--secondary-inverse);
        border-radius: 1rem;
        transition: color .3s, background-color .3s, border .3s;
        .icon{
            font-size: 1.5em;
            transition: transform .3s;
            margin-left: .5rem;
        }
        &:hover{
            background-color: var(--secondary-inverse);
            color: var(--onsecondary-inverse);
            border: solid 2px var(--onsecondary-inverse);
        }
    }
}


// layout que tienen en común las secciones de mi colección, mi libro favorito y lectura makemake
.plan-section-layout{
    position: relative;
    width: 100%;
    background-color: var(--surface-color);

    .sidenav{
        width: 80%;
        max-width: 400px;
        margin-right: .5rem;
        box-shadow: 0 0 10px 0px rgba(0,0,0,.5);
        $themes:(
            "alto-contraste":(
                background-color:var(--background-color),
                color:var(--onbackground-primary),
                border:solid 2px var(--onbackground-primary)
                )
            );
        @include ThemesProperties($themes);


        >.title{
            padding: 1rem;
            padding-left: 2rem;
            font-size: 1.5em;
            $themes:(
                "alto-contraste":(
                    background-color:var(--background-color),
                    color:var(--onbackground-primary),
                    border-bottom:solid 2px var(--onbackground-primary)
                )
            );
            @include ThemesProperties($themes);
        }

        > .details{
            padding: 1rem;
            > .detail{
                .icon{
                    font-size: 1em;
                    margin-right: .5rem;
                    $themes:(
                        "alto-contraste":(
                            color:var(--onbackground-primary),
                        )
                    );
                    @include ThemesProperties($themes);
                }
            }
        }
        .list{
            list-style: disc ;
            padding: 20px 0 0 20px;
        }
    }

    .content_layout {
        width: 78%;
        padding: 20px;
        background-color: var(--surface-color);
    }

    .price-info{
        position: sticky;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        bottom: 87px;
        left: 0;
        width: 100%;
        height: 100px;
        padding: .5rem;
        background-color: var(--surface-color);
        box-shadow: 0px -5px 10px -4px rgba(0,0,0,0.5);
        color: var(--onbackground-primary);
        z-index: 30;
        .price-title{
            font-family: 'Raleway';
        }
        .price{
            font-weight: bolder;
            font-family: 'Quicksand';
            font-size: 1.5rem;
            .moneda{
                text-transform: uppercase;
                font-size: .9rem;
            }
        }
        > .buy-button{
            border-radius: 1rem;
            padding: .5rem;
            box-shadow: 2px 2px 5px 0px rgba(0,0,0,9);
            background-color: var(--primary-inverse);
            color: var(--onprimary-inverse);
            font-weight: bold;
            transition: color .3s, background-color .3s;
            &:hover{
                background-color: var(--secondary-inverse);
                color: var(--onsecondary-inverse);
            }
            &:disabled{
                background-color: var(--disabled-color);
                color: var(--ondisabled-color);
            }
        }

        @media  screen and (min-width:992px) {
            bottom: 0;
        }
    }

    .mobile-accordion {
        display: none;
        padding: 1rem;
    }

    // estilos para la sección de lecturas makemake
    &.lectura-makemake {
        .sidenav {
            background-color: #B2AE1B33;
            >.title {
                background-color: #B2AE1B;
                color: #fff;
            }
        }

        .details {
            >.detail {
                color: var(--onbackground-primary);

                .icon {
                    color: #B2AE1B;
                }
            }
        }
    }
    // estilos para la sección de mi colección
    &.mi-coleccion {
        .sidenav {
            background-color: rgba(74, 144, 226, .25);
            >.title {
                background-color: rgba(74, 144, 226, 1);
                color: #fff;
            }
        }

        .details {
            >.detail {
                color: var(--onbackground-primary);

                .icon {
                    color: rgba(74, 144, 226, 1);
                }
            }
        }

        .price-collection{
            p {
                color: var(--onprimary-color) !important;
                font-size: .8em !important;
                font-weight: 600 !important;
                line-height: 19px !important;
            }
        }

        .collection-card {
            background: var(--surface-color);
            border: 1px solid ;
            border-radius: 20px;
            font-family: var(--primary-font);
            max-width: 1200px;
            margin: auto;

            $themes: (
                "clasico": (border-color: #9B9B9B),
                "alto-contraste":(border-color: var(--secondary-inverse),)
            );
            @include ThemesProperties($themes);

            h4 {
                font-weight: 700;
                font-size: 1.1em;
                $themes: (
                    "clasico": (color: #262626),
                    "alto-contraste":(color: var(--secondary-inverse),));
                @include ThemesProperties($themes);
            }

            p {
                color: var(--violet-inverse);
                font-size: .8em;
                font-weight: 700;
                line-height: 19px;
            }

            ul {
                list-style: disc;

                li {
                    font-weight: 500;
                    font-size: .8em;
                    line-height: 25px;
                    $themes: (
                        "clasico": (color: #262626),
                        "alto-contraste":(color: var(--secondary-inverse),));
                    @include ThemesProperties($themes);
                }
            }

            .img-coleccion {
                border-radius: 0 20px 20px 0;

                .img {
                    border-radius: 0 20px 20px 0;
                    width: 100%;
                    height: 600px;
                    vertical-align: middle;
                    background-size: cover;
                    background-position: left 80%;
                    background-repeat: no-repeat;

                    //mix-blend-mode: multiply;
                }

            }

            .price-info-new{
                position: sticky;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 80px;
                padding: .5rem;
                background-color: var(--surface-color);
                color: var(--onbackground-primary);
                z-index: 30;
                .price-title{
                    font-family: 'Raleway';
                }
                .price{
                    font-weight: bolder;
                    font-family: 'Quicksand';
                    font-size: 1.5rem;
                    .moneda{
                        text-transform: uppercase;
                        font-size: .9rem;
                    }
                }
                > .buy-button{
                    border-radius: 1rem;
                    padding: .5rem;
                    box-shadow: 2px 2px 5px 0px rgba(0,0,0,9);
                    background-color: var(--primary-inverse);
                    color: var(--onprimary-inverse);
                    font-weight: bold;
                    transition: color .3s, background-color .3s;
                    &:hover{
                        background-color: var(--secondary-inverse);
                        color: var(--onsecondary-inverse);
                    }
                    &:disabled{
                        background-color: var(--disabled-color);
                        color: var(--ondisabled-color);
                    }
                }
                
            }
        }
    }

    // estilos para la sección de libro favorito.
    &.libro-favorito {
        .sidenav {
            background-color: rgba(241, 183, 12, .25);
            >.title {
                background-color: rgba(241, 183, 12, 1);
                color: #000;
            }
        }

        .details {
            >.detail {
                color: var(--onbackground-primary);

                .icon {
                    color: var(--secondary-inverse);
                }
            }
        }
    }

}

.text-background{
    background-color: var(--violet-inverse);
    border-radius: 20px 0px 0px 0px;
    h4 {
        color: var(--onviolet-inverse);
    }
}

// MEDIA QUERIES
@media screen and (max-width:map.get($grid-breakpoints, "xl")) {
    .plan-lectura-card{
        grid-template-columns: 1fr;
        > .section-1{
            width: 100%;
            max-width: 100%;
            border:none;
            border-bottom: solid 2px var(--onbackground-fourth);
        }
    }
    .plan-section-layout{
        .content_layout {
            width: 68%;
        }
        &.mi-coleccion{
            .collection-card {
                .img-coleccion {
                    border-radius: 20px 20px 0 0;
                    height: 300px;

                    .img {
                        border-radius: 20px 20px 0 0;
                        height: 300px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:991px) {
    .plan-section-layout{
        .desktop-accordion{
            display: none;
            width: 100%;
        }
        .mobile-accordion{
            display: flex;
            width: 100%;
            grid-column: span 2;
        }
       
    }
    .text-background{
        border-radius: 00px 0px 0px 0px;
    }
}

@media screen and (min-width:1200px) {
    .plan-section-layout{
        &.mi-coleccion { 
            .collection-card {
                .img-coleccion {
                    .img {
                        background-position: -60px;
                    }
                }
            }
        }      
    }
}

@media screen and (max-width:1200px) {
    .text-background{
        border-radius: 00px 0px 0px 0px;
    }
}
@media screen and (max-width:map.get($grid-breakpoints, "lg")) {
    .plan-section-layout{
        /* display: flex;
        flex-direction: column; */
        .sidenav{
            width: 100%;
            max-width: 100%;
        }
        .content_layout {
            width: 100%;
        }
    }
}