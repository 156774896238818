/*===========================================================================================
  plector-estudiante

  En este archivo se encuentran algunas clases que se usan en LibroHomeIndividual y CambiarAvatar

  >**NOTA:** se debe revisar la mayoría de clases ya que aparentemente no se usan. 
 ============================================================================================ */

 .pl-student-container {
  // background: transparentize($color: var(--blue-color), $amount: 0.1) url("/img/background.png") center/ cover fixed;
  background-blend-mode: multiply;
  font-family: $secondary-font;
}
.mm-component {
  background-color: #fff;
}

.login-student {
  &::after {
    font-family: Font Awesome\5 Free;
    content: "\f059";
    font-weight: 900;
    font-weight: 2em;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: #fff;
  }
}

.login-student--items {
  p {
    font-weight: 900;
  }
}

.student-profile {
  width: 130px !important;
  height: 130px !important;
  border: 2px solid #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.student-profile--title {
  width: 160px;
  height: 160px;
}

.student-profile--active {
  border: 8px solid var(--secondary-inverse);
}

.header-student-profile {
  font-family: $secondary-font;
  color: #fff;
  line-height: 1.2;

  .student-name {
    font-size: 1.8em;
    color: #fff;
  }
  .progress {
    height: 0.75rem;
  }
  .progress-bar {
    background-color: #f2580c;
  }
}

.change-avatar {
  .swiper-button-next,
  .swiper-button-prev {
    top: 63% !important;
  }
  .student-profile {
    max-width: 130px;
    min-width: 130px;
  }
}

.pl-student {
  .swiper-button-next,
  .swiper-button-prev {
    top: 40%;
    padding: 0 !important;
  }
  p {
    font-weight: 700;
    line-height: 1.2;
  }
}

.student-menu {
  background-color: #fff;
  border-bottom-left-radius: 30px;
  box-shadow: 0 1px 15px #9f9f9f;
  color: var(--onbackground-primary);
  font-family: $secondary-font;
  font-size: 1.2em;
  font-weight: 700;
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    border: 1px solid var(--onbackground-fourth);
  }
}

.messages-icon {
  text-indent: -10000px;
  background: url("/img/message-icon.png") center/contain no-repeat;
  width: 45px;
  height: 45px;
}

.border-right {
  border-right: 1px solid var(--onbackground-fourth);
}

.message-icon {
  background: url("/img/message-icon.png") center top / auto 40px no-repeat;
  padding-top: 40px;
  position: relative;
  .message-number {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: var(--red-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding-top: 3px;
    color: #fff;
  }
}

.student-content {
  font-family: $secondary-font;
}

.student-books {
  .title {
    border-bottom: 2px solid var(--onbackground-fourth);
  }
}

.student-medallas {
  // background-color: transparentize($color: var(--primary-color), $amount: 0.9);
  border-radius: 0.5em;
  h2 {
    background: url("/img/medallas-icon.png") left center / 60px auto no-repeat;
    padding: 10px 0 10px 70px;
  }
  p {
    line-height: 1.1;
  }
}

.cover-individual {
  transition: 0.2s ease-in;
  backface-visibility: hidden;
}

.student-book {
  transform-style: preserve-3d;
  transition: 0.2s ease-in;
  transform: rotateY(0deg);
}

.individual-book-des {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  transform: rotateY(-180deg);
  backface-visibility: hidden;
  .btn-secondary {
    font-size: 0.8em;
  }
}

.individual-book-desc {
  height: 90%;
  p {
    font-weight: 300;
  }
}

.pl-estudiante-book-information {
  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}

.book-description {
  overflow-y: auto;
  height: 44%;
  @extend .scrollBar;
}

.rotateficha {
  transform: rotateY(180deg);
}

.plector-messages {
  display: none;
  position: fixed;
  right: -100%;
  max-width: 480px;
  width: 80%;
  height: 100vh;
  background-color: #fff;
  top: 0;
  z-index: 101;
  box-shadow: 3px 0 20px #4d4d4d;
  overflow-y: auto;
  transition: 0.2s ease-in;
  @extend .scrollBar;
}

.profile-min {
  width: 90px;
  height: 90px;
}

.message {
  border-bottom: 1px solid var(--onbackground-fourth);
  .date-message {
    color: var(--onbackground-secondary);
    font-size: 0.9em;
  }
  .message-content {
    background: var(--background-color);
    border-radius: 5px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--background-color);
      left: 30px;
      top: -10px;
    }
  }
}

.student-messages-icon {
  span {
    width: 27px;
    height: 27px;
    display: inline-block;
    color: #fff;
    background-color: var(--red-color);
    border-radius: 50%;
    text-align: center;
  }
}

.header-estudiante,
.pl-footer {
  transition: 0.2s ease-in;
}

@media only screen and (min-width: 768px) {
  .student-messages-icon {
    background: url("/img/messages-icon.png") left center/contain no-repeat;
    padding-left: 35px;
  }
}
