/*=====================================================
    TIENDA-INDIVIDUAL

    Este archivo establece los estilos de la pestaña
    'quiero leer en mkmk'
 ====================================================== */

@use "sass:map";

/*-------------------------------------------*\
 TARJETAS
\*-------------------------------------------*/

.card-header-img {
    mix-blend-mode: multiply;
}

.red-plan {
    $themes: ("clasico":(background: #2c3869),
        "alto-contraste":(background: var(--blue-inverse)));

    @include ThemesProperties($themes);

    h2 {
        color: var(--onscarlet-inverse);
    }
}

.blue-plan {
    $themes: ("clasico":(background: #ffeaff),
        "alto-contraste":(background: var(--blue-inverse)));

    @include ThemesProperties($themes);

    h2 {
        color: var(--onblue-inverse);
    }
}

.green-plan {
    $themes: ("clasico":(background: #e9def9),
        "alto-contraste":(background: var(--green-inverse)));

    @include ThemesProperties($themes);

    h2 {
        color: var(--onprimary-inverse);
    }
}

.yellow-plan {
    $themes: ("clasico":(background: #bfdef2),
        "alto-contraste":(background: var(--primary-inverse)));

    @include ThemesProperties($themes);

    h2 {
        color: var(--onprimary-inverse);
    }
}

.price-info-new{
    position: sticky;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding: .5rem;
    background-color: var(--surface-color);
    color: var(--onbackground-primary);
    z-index: 30;
    .price-title{
        font-family: 'Raleway';
    }
    .price{
        font-weight: bolder;
        font-family: 'Quicksand';
        font-size: 1.5rem;
        .moneda{
            text-transform: uppercase;
            font-size: .9rem;
        }
    }
    > .buy-button{
        border-radius: 1rem;
        padding: .5rem;
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,9);
        background-color: var(--primary-inverse);
        color: var(--onprimary-inverse);
        font-weight: bold;
        transition: color .3s, background-color .3s;
        &:hover{
            background-color: var(--secondary-inverse);
            color: var(--onsecondary-inverse);
        }
        &:disabled{
            background-color: var(--disabled-color);
            color: var(--ondisabled-color);
        }
    }
    
}

.tienda-planes-p{
    color: var(--onsecondary-color);
}
.favorite-plan {
    width: 234px;
    height: 39px;
    position: absolute;
    z-index: 10;
    background-image: url("/img/tienda-makemake/favorite-plan.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-family: var(--secondary-font);
    color: var(--onsecondary-color);
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}

.plan-card {
    width: 301px;
    border: 3px solid;
    background-color: var(--surface-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 5px;
    position: relative;

    $themes: ("clasico":(border-color: #9772AE),
        "alto-contraste":(border-color: var(--violet-inverse)));
    @include ThemesProperties($themes);

    .favorite-plan {
        width: 234px;
        height: 39px;
        position: absolute;
        z-index: 10;
        background-image: url("/img/tienda-makemake/favorite-plan.svg");
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-family: var(--secondary-font);
        color: var(--onsecondary-color);
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
    }

    .card-header {
        border-radius: 20px 20px 0px 0px;

        h2 {
            font-family: var(--secondary-font);
            font-size: 1.1em;
            text-align: center;
            padding: 10px 0;
        }
    }

    .price-description {
        height: 100px;
        font-weight: 700;
        font-family: var(--secondary-font);
        color: var(--violet-inverse);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        $themes: ("clasico":(background-color: #DCDDED),
            "alto-contraste":(
                background-color: none,
                border: 2px solid var(--violet-inverse)));

        @include ThemesProperties($themes);

        .currency {
            font-size: .6em;
        }

        .price {
            font-size: 35px;
            line-height: 40px;
        }
    }

    .features-list {
        .description {
            font-size: .8em;
            margin: 15px auto;
            width: 80%;
            color: var(--violet-inverse);
            text-align: center;
            font-weight: 700;
            font-family: var(--primary-font);
            line-height: 20px;
        }

        i {

            $themes: ("clasico":(color: #81559D),
                "alto-contraste":(color: var(--primary-inverse)));
            @include ThemesProperties($themes);
        }

        p {
            font-size: .8em;
            color: var(--onprimary-color)
        }

        li {
            margin: 10px 0;
        }
    }

    .btn-tarjeta-plan {
        background: var(--primary-inverse);
        border-radius: 0 0 15px 15px;
        text-align: center;
        padding: 10px 20px;
        display: block;
        font-weight: 700;
        font-size: .9em;
        color: var(--onprimary-inverse);
        transition: all .5s;

        &:hover {
            background: var(--secondary-color);
            color: var(--onsecondary-color);
            box-shadow: none !important;

            $themes: ("alto-contraste":(border: 1px solid var(--onsecondary-color)));
            @include ThemesProperties($themes);
        }
    }
}

.btn-tarjeta-plan {
    background: var(--primary-inverse);
    text-align: center;
    padding: 10px 20px;
    display: block;
    font-weight: 700;
    font-size: .9em;
    color: var(--onprimary-inverse);
    transition: all .5s;

    &:hover {
        background: var(--secondary-color);
        color: var(--onsecondary-color);
        box-shadow: none !important;

        $themes: ("alto-contraste":(border: 1px solid var(--onsecondary-color)));
        @include ThemesProperties($themes);
    }
    width: 100%;
    //position: absolute;
    margin: auto;
    border-radius: 15px !important;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.56);
}

.tagIsFavorite {
    padding: 6 !important;
    border: 3px solid  !important;
    $themes: (
        "clasico":(border-color: var(--scarlet-color)),
        "alto-contraste":(border-color: var(--scarlet-inverse))
        );
    @include ThemesProperties($themes);
}

/*-------------------------------------------*\
 SELECTOR DE MESES
\*-------------------------------------------*/

.select-month {
    border-radius: 20px;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 5px;

    $themes: ("clasico":(background: #C8C8C8),
        "alto-contraste":(border: 1px solid var(--onbackground-primary)));
    @include ThemesProperties($themes);

    li{
        width: 100%;
        margin-bottom: 10px;
    }

    .btn-option {
        text-align: center !important;
        color: var(--onbackground-primary);
        font-weight: 500;
        width: 100%;
        height: 48px;
        border-radius: 18px;

        &:hover{
            border: 1px solid var(--onsecondary-color) !important;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        }
    }

    .btn-option-select {
        background-color: var(--onsecondary-color);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        font-weight: 700 !important;
        position: relative;
        cursor: auto;

        $themes: (
            "clasico":(color: #81559D),
            "alto-contraste":(color: var(--primary-color))
        );
        @include ThemesProperties($themes);
    }
}

.text-background{
    background-color: var(--violet-inverse);
    h2 {
        color: var(--onviolet-inverse);
    }
}

.column-title {
    display: flex;
    justify-content: center;
    border-radius: 20px 20px 0px 0px !important;

    .img-course {
      border-radius: 20px 20px 0px 0px !important;
      border-top-right-radius: 0px !important;
      height: 300px;
    }
  }

.text-checkbox{
      position: relative;
      top: -6px;
}

.new-card {
    background: var(--surface-color);
    border: 3px solid;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 6px;
    margin-bottom: 40px;
  
    $themes : (
      "clasico": (border-color: #9772AE),
      "alto-contraste": (border-color: var(--onprimary-color))
    );
    @include ThemesProperties($themes);
  
    .column-left-individual {
  
      .img-course {
        //border-radius: 20px ;
        height: 191px;
        background-repeat: no-repeat;
        background-size: cover;
        //background-position: 10px;
      }
  
  
      .card-description {
        padding: 30px 20px 20px;
        color: var(--onprimary-inverse);
        /* height: 100%; */
  
        .general-description {
          font-family: var(--secondary-font);
          font-weight: 700;
          text-transform: uppercase;
          font-size: .6em;
        }
  
        h4 {
          font-family: var(--secondary-font);
          font-size: 1.5em;
          line-height: 1;
          margin: 10px 0;
          width: 90%;
        }
  
        .creator {
          font-family: var(--primary-font);
          font-weight: 500;
          font-size: .8em;
  
          span {
            font-family: var(--secondary-font);
            font-size: 1em;
            font-weight: 700;
            $themes: ("clasico": (color: #EA3D4D),
              "alto-contraste" : (var(--onprimary-inverse)));
            @include ThemesProperties($themes);
          }
        }
      }
    }
  
    @mixin cardColor($color: DarkGray) {
      $themes : (
        "clasico": (background: $color),
        "alto-contraste": (background: var(--onprimary-color))
      );
      @include ThemesProperties($themes);
    }
  
    .card-blue {@include cardColor($color: #E4FFF8);}
    .card-pink {@include cardColor($color: #FFEEF6);}
    .card-green {@include cardColor($color: #F1FFDA);}
  
    .features-list {
      list-style: disc;
      font-size: .9em;
      padding-left: 5px;
      padding-right: 10px;
  
      li {
        margin: 10px 0;
        color: var(--onprimary-color);
      }
    }
  
    .price-description {
      font-family: var(--secondary-font);
      font-weight: 700;
      line-height: 25px;
      color: var(--onprimary-color);
  
      p:last-child{
        font-size: 20px;
      }
  
      p:nth-child(2) {
        font-size: 1.5em;
      }
    }
  
    .btn-trailer {
      i {
        width: 84px;
        height: 35px;
        border-radius: 10px;
        color: var(--surface-color);
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
  
        $themes : (
          "clasico": (background: #81559D),
          "alto-contraste": (background: var(--onprimary-color))
        );
        @include ThemesProperties($themes);
  
      }
  
      p {
        font-family: var(--secondary-font);
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        text-decoration-line: underline;
  
        $themes : (
          "clasico": (color: #81559D),
          "alto-contraste": (color: var(--onprimary-color))
        );
        @include ThemesProperties($themes);
      }
  
      &:hover {
        i {
          $themes : (
            "clasico" : (background: var(--secondary-inverse)),
            "alto-contraste": (
              background: var(--secondary-color),
              border: 1px solid var(--secondary-inverse),
              color: var(--secondary-inverse)
            )
          );
          @include ThemesProperties($themes);
        }
  
        p {
          
          $themes : (
            "clasico" : (color: var(--secondary-color)),
            "alto-contraste": ( color: var(--secondary-inverse))
          );
          @include ThemesProperties($themes);
        }
      }
    }
  
    .btn-tarjeta-course {
      background: var(--primary-inverse);
      border-radius: 15px;
      box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.56);
      text-align: center;
      padding: 10px 25px;
      display: block;
      font-weight: 700;
      font-size: 1.1em;
      color: var(--onprimary-inverse);
      transition: all .5s;
  
  
      &:hover {
        background: var(--secondary-color);
        color: var(--onsecondary-color);
        box-shadow: none !important;
  
        $themes: ("alto-contraste":(border: 1px solid var(--onsecondary-color)));
        @include ThemesProperties($themes);
      }
    }
  }


/*-------------------------------------------*\
 LIBRO FAVORITO
\*-------------------------------------------*/

.section-plan-card{
    /* display: grid;
    grid-template-columns: .8fr 1fr; */
    border: solid 1px var(--onbackground-fourth);
    border-radius: 2rem;
    overflow: hidden;
    max-width: 1200px;
    margin: auto;
    >.info{
        padding: 2rem;
        border-right: solid 1px var(--onbackground-fourth);
    }

    >.image{
        /*  display: flex; */
        border-radius: 0 20px 20px 0;

        .img {
            border-radius: 0 20px 20px 0;
            width: 100%;
            height: 550px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            //mix-blend-mode: multiply;
        }
    }
    .book-card{
        p {
            //color: var(--violet-inverse);
            font-size: .8em;
            font-weight: 600;
            line-height: 19px;
        }
    }

}

@media screen and (min-width: 410px) {
    .new-card{
        .column-left-individual{
            .img-course {
                //border-radius: 20px ;
                height: 255px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: top;
                //background-position: 10px;
              }
        }
    }
}

@media screen and (min-width: 526px) {
    .select-month {
        li{
            width: 30%;
            margin-bottom: 0;
        }

        .btn-option-select {
            &::before{
                content: "\f078";
                font-family: 'Font Awesome 5 Free';
                position: absolute;
                top: -40px;
                left: 40%;
                font-size: 20px;
                color: var(--onsecondary-color);
            }
        }
    }
    .new-card{
        .column-left-individual{
            .img-course {
                //border-radius: 20px ;
                height: 255px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position-y: -30px;
              }
        }
    }
}

@media screen and (min-width: 632px) {
    .tagIsNotFavorite,
    .tagIsFavorite {
        margin: 10px;
    }
}

@media screen and (min-width: 768px) {
    .new-card{
        .column-left-individual{
            .img-course {
                border-radius: 20px ;
                height: 380px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position-x: -135px !important;
                background-position: top;
              }
        }
    }
}

@media screen and (min-width: 992px) {
    .new-card{
        .column-left-individual{
            .img-course {
                border-radius: 20px ;
                height: 315px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: top !important;
                //background-position: 10px;
              }
        }
    }
}

@media screen and (min-width: 1200px) {
    .new-card{
        .column-left-individual{
            .img-course {
                border-radius: 20px ;
                height: 315px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: top !important;
                //background-position: 10px;
              }
        }
    }
    .section-plan-card{
        >.image{
            .img {
                background-position-x: -150px;
                background-position-y: 50px;
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .new-card{
        .column-left-individual{
            .img-course {
                border-radius: 20px ;
                height: 400px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: top !important;
              }
        }
    }
    .section-plan-card{
        >.image{
            .img {
                background-position-x: -80px;
                background-position-y: 30px;
            }
        }
    }
}

@media screen and (min-width:map.get($grid-breakpoints, "md")) {
    .contenedor-tarjeta{width: 50%;}

    .plan-card {
        /*  height: 583px; */
        .price-description {
            border-radius: 0px 0px 20px 20px;
        }

        .features-list {
            margin-bottom: 50px;
        }
    }

    .selector-button{
        border-radius: 1rem;
        width: fit-content;
        height: fit-content;
        font-weight: bold;
        background-color: var(--secondary-inverse);
        color: var(--onsecondary-inverse);
        cursor: pointer;
        transition: background-color .3s, color .3s;
        &.selected{
            background-color: var(--primary-inverse);
            color: var(--onprimary-inverse);
        }
    }
    
    .btn-tarjeta-plan {
        background: var(--primary-inverse);
        text-align: center;
        padding: 10px 20px;
        display: block;
        font-weight: 700;
        font-size: .9em;
        color: var(--onprimary-inverse);
        transition: all .5s;

        &:hover {
            background: var(--secondary-color);
            color: var(--onsecondary-color);
            box-shadow: none !important;

            $themes: ("alto-contraste":(border: 1px solid var(--onsecondary-color)));
            @include ThemesProperties($themes);
        }
        width: 100%;
        //position: absolute;
        margin: auto;
        border-radius: 15px !important;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.56);
    }

}

@media screen and (min-width:map.get($grid-breakpoints, "lg")) {
    .select-month {
        width: 439px;
    }    
}

@media screen and (max-width:map.get($grid-breakpoints, "xl")) {
    .section-plan-card{
        > .image{
            border-radius: 20px 20px 0 0;
            height: 300px;

            .img {
                border-radius: 20px 20px 0 0;
                height: 300px;
            }
        }
    }
}
