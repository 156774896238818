/*=====================================================
    BOOK
    Define los estilos del componente book.
 ====================================================== */

.libro-tarjeta {
    display: flex;
    flex-direction: column;
    user-select: none;
    /* margin: 1rem; */
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;

    >.titulo {
        cursor: pointer;
        text-align: center;
        transition: all .3s;
        font-weight: bold;
        color: var(--onbackground-primary);
    }

    >.portada {
        position: relative;
        background-color: var(--surface-color);
        display: flex;
        flex-direction: column;
        border-radius: .5rem;
        transition: all .3s;
        overflow: hidden;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, .25);


        >img {
            border: none;
            box-shadow: none;
            margin: 0;
            padding: 0;
            top: 0;
            left: 0;
            max-width: 100%;
            height: auto;

            &.hidden {
                opacity: 0;
            }

            &.gray-scale {
                filter: grayscale(1);
            }

        }

        >.loading-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--surface-color);
            color: var(--secondary-inverse);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 30;

        }

        >.ver-mas {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            pointer-events: none;
            transition: background-color .3s, opacity .3s, visibility .3s;
            background-color: transparent;
            opacity: 0;
            visibility: hidden;

            >.icon {
                padding: .5rem;
                background-color: var(--secondary-inverse);
                color: var(--onsecondary-inverse);
                border-radius: 999999999px;
            }
        }

        > .checkbox-container {
            width: 15px;
            position: absolute;
            top: 0;
            left: 2%;
            z-index: 20;
        }

    }

    &:hover{
        >.portada>.ver-mas {
            background-color: rgba(0, 0, 0, .10);
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }

    &:hover>.portada {
        transform: translateY(-1%);
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, .4);
    }

    &:hover>.titulo {
        color: var(--secondary-inverse);
    }

    &:hover>.portada>.fav {
        visibility: visible;
        opacity: 1;
    }

}

.fav {
    position: absolute;
    top: 1%;
    right: 2%;
    background-color: var(--secondary-inverse);
    color: var(--onsecondary-inverse);
    border-radius: 999999px;
    font-size: 16px;

    cursor: pointer;
    transition: all .3s;
    z-index: 30;

    &.added {
        color: var(--red-inverse);
    }


}