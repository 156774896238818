/*=======================================
Aquí se encuentran los estilos de la sección de estadísticas de mi panel. 
=========================================*/

.statistics-container {
  h2 {
    font-size: 1.2em;
  }
  p {
    font-weight: 600;
  }
  li {
    font-weight: 600;
  }
}

.statistics-preloader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 90;
  background: var(--surface-color);
  transition: opacity 0.4s ease-in;
  opacity: 1;
  &::after {
    content: "";
    width: 70px;
    height: 70px;
    position: absolute;
    margin: auto;
    background: url("/img/preloader.gif") center/contain no-repeat;
    top: 100px;
    left: 0;
    right: 0;
  }
}

.fade-in {
  opacity: 0;
}

.statistics-user {
  max-width: 280px;
}

.dashboard-detallelecturas {
  .statistics-user {
    display: none;
  }
}

.important-item {
  font-size: 3em;
  font-weight: 700 !important;
  line-height: 1;
}

.second-category-item {
  font-size: 1.6em;
  font-weight: 700 !important;
}

.highlight-item {
  font-size: 2em !important;
}

.complementary-text {
  color: var(--onbackground-fourth);
}

.content-box {
  border-radius: 10px;
  border: 1px solid var(--onbackground-secondary);
  height: 100%;
  p {
    line-height: 1;
  }
}

.important-link {
  text-decoration: underline;

  &:hover{
    $themes:(
      "clasico":(
        color: #0056b3
      ),
      "alto-contraste":(
        color: var(--onbackground-primary)
      ),
    );

    @include ThemesProperties($themes);
  }
}

.active {
  color: var(--secondary-inverse) !important;
  font-weight: bolder;
  a {
    color: var(--secondary-inverse) !important;
    font-weight: bolder;
  }
}

.active-sesion {
  font-weight: bolder;
  a {
    font-weight: bolder;
  }
}

.dashboard-list {
  list-style: none;
  counter-reset: my-awesome-counter;
  li {
    div {
      margin-top: -45px;
    }
    padding-left: 50px;
    counter-increment: my-awesome-counter;
    &::before {
      content: counter(my-awesome-counter) ". ";
      color: black;
      width: 40px;
      height: 40px;
      background-color: var(--onbackground-fourth);
      text-align: center;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      margin-left: -50px;
      padding: 0.2em;
    }
  }
}

.pika-lendar {
  font-family: $font-family-sans-serif;
}

.filter-title {
  font-size: 0.85em;
}

.dashboard-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  background-color: #e0e0f5;
  border-radius: 5px;
  font-weight: 700;
  font-size: 0.85em;
  padding: 1rem;

  .filters{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .date-picker{
      input{
        min-width: 300px;
        max-width: 300px;
        border: none;
        outline: none;
         height: 30px;
        font-size: 20px;
        padding: 0 5px;
        font-weight: 700;
        cursor: pointer;
        border-radius: 5px;
      }
  
    }
  
    select {
      flex-grow: 1;
      min-width: 300px;
      max-width: 300px;
      height: 30px;
      font-size: 20px;
      padding: 0 5px;
      font-weight: 700;
      border: none;
    }
  }

  $themes:(
    "clasico":(
      background-color: #e0e0f5,
    ),
    "alto-contraste":(
      background-color: var(--background-color),
      border: 1px solid var(--onbackground-fourth)
    ),
  );

  @include ThemesProperties($themes);


  .buttons{
    display: flex;
    flex-wrap: wrap;
    .btn {
      font-size: 1em;
      width: fit-content;
      max-width: 300px;
      min-width: max-content;
      margin: .25rem;
    }
  }

  @media screen and (max-width:1200px) {
    .filters {
      width: 100%;
      flex-wrap: nowrap;

      .date-picker {
        flex-grow: 1;
        .rmdp-container {
          width: 100%;
        }
  
        input {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }
  
      }
  
      select {
        flex-grow: 1;
      }

    }
  }

    @media screen and (max-width:768px) {
      .filters {
          width: 100%;
          flex-direction: column;
      
          .date-picker {
            width: 100%;
      
            .rmdp-container {
              width: 100%;
            }
      
            input {
              width: 100%;
              max-width: 100%;
              min-width: 100%;
            }
      
          }
      
          select {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
          }
        }


            .buttons {
              width: 100%;
      
              .btn {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
              }
            }
  
    }
}

g[aria-labelledby="id-66-title"] {
  display: none;
}

.dataTable-wrapper.no-footer .dataTable-container {
  $themes:(
    "clasico":(
      border-bottom: 1px solid #d9d9d9
    ),
    "alto-contraste":(
      border-bottom: 1px solid var(--onbackground-third)
    ),
  );

  @include ThemesProperties($themes);

 
}

.dataTable-dropdown{
  color: var(--onbackground-third);
}

.dataTable-selector {
  border-radius: 5px;
  padding: 5px;
  color: var(--onbackground-third);
  background: var(--background-color);
  border-color: var(--onbackground-secondary);
}

.dataTable-input {
  @extend .dataTable-selector;
  border: 1px solid var(--onbackground-secondary);
}

@media only screen and (min-width: 992px) {
  .active-sesion {
    color: inherit !important;
    a {
      color: inherit !important;
    }
  }
}


