.favorite{
  .cover-book{
    width: 40%;
    margin: 0 15px;
    .libro-tarjeta{
      margin: 0 !important;
    }
  }
}

@media only screen and (min-width: 768px) {
  .favorite{
    .cover-book{
      width: 20%;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .favorite{
    .cover-book{
      width: 17%;
    }
  }
}