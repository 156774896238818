/*=====================================================
                     Curadurias
 ====================================================== */
@use "sass:map";

.curadurias-background{
    background: url("/img/background.png") center/cover fixed repeat;
}

.curadurias-page {
    padding: 0;
    .tab-panel {
        min-height: 90vh;
        z-index: 1;

        .tabs {
            width: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 0;
            padding-left: 1rem;
            padding-right: 1rem;
            border-bottom: solid 3px var(--secondary-inverse);

            .tab {
                width: max-content;
                margin-left: .25rem;
                margin-right: .25rem;
                background-color: var(--background-color);
                color: var(--onbackground-secondary);
                font-weight: bold;
                padding: .9rem 2rem;
                border-radius: 1.5rem 1.5rem 0px 0px;
                font-size: .9em;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: color .3s, background-color .3s;

                &.current {
                    background-color: var(--secondary-inverse);
                    color: var(--onsecondary-inverse);
                    $themes: ("alto-contraste":(background-color:transparent,
                            border: solid 2px var(--secondary-inverse),
                            color:var(--secondary-inverse)));

                    @include ThemesProperties($themes);
                }
            }
        }

    }

    >.curadurias-header {
        display: flex;
        align-items: center;
        justify-content: space-around;

        >h1 {
            font-weight: 600;
            font-size: 3em;
            justify-self: end;
            padding-right: 4rem;
        }

        >p {
            justify-self: left;
        }

        >h1,
        >p {
            max-width: 500px;
            $themes: ("clasico":(color:#fff),
                "alto-contraste":(color:var(--onbackground-primary)));
            @include ThemesProperties($themes);
        }
    }

    .header-bg {
        $themes: ("clasico":(background-color: #262626),
            "alt-contraste":(background-color: var(--background-color)));
        @include ThemesProperties($themes);
    }
}

.curaduria-card{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    transition: all .3s;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    $themes: (
        "alto-contraste":(
        background-color:transparent
        ),
        "clasico":(
        background-color: #DCDDED80
        )
        );

@include ThemesProperties($themes);


    &:hover {
        transform: translateY(-2%);
         .title{
            color: var(--secondary-inverse);
        }
    }

    > .image-container{

    }
     .title{
        font-weight: 700;
        font-size: 1em;
        transition: color .3s;
        color: var(--onbackground-primary);
    }
     .description{
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: .8em;
        color: var(--onbackground-secondary);

    }
}

.country-card{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    transition: all .3s;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    img{
        width: 220px;
        height: auto;
    }
    &:hover {
        transform: translateY(-2%);
         .title{
            color: var(--secondary-inverse);
        }
    }
     .title{
        font-weight: 700;
        font-size: 1em;
        transition: color .3s;
        color: var(--onbackground-primary);
    }
}


.lazy-image{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 0;
    .loading-placeholder{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        color: var(--secondary-inverse);
        background-color: var(--surface-color);
    }
    
}

.imgxxx{
    width: 100%;
    height: 100%;
}

@media screen and (max-width:map.get($grid-breakpoints, "md")) {
    .curadurias-page {
        >.tab-panel {
            .tabs {
                border-bottom: none;
                flex-direction: column;
                overflow: hidden;
                padding-bottom: 1rem;
                padding-left: .5rem;
                padding-right: .5rem;
                width: auto;
                flex-grow: 1;
                .tab {
                    flex-grow: 1;
                    width: 100%;
                    padding: .5rem;
                    margin: 0;
                    border-radius: 0px;
                }
            }
        }
        >.curadurias-header {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            >h1 {
                font-size: 2em;
                padding-right: 0;
                padding: 1rem;
            }

            >p {}

            >h1,
            >p {
                justify-self: left;
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width:map.get($grid-breakpoints, "sm")) {
    .country-card{
        img{
            width: 90%;
            height: auto;
        }
    }
}

.curadurias-img{
    object-position: center;
    object-fit: contain;
    height: 200px;
}