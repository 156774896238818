.pautas {
    background-image: url("/img/background.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;


    .pautas-content{
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);
        background-color: var(--surface-color);
    }
    .content-header {
        position: relative;
        background: none;
        background-image: none;

        .texture {
            position: absolute;
            left: 50%;
            top: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-50%);
            overflow: hidden !important;

            .texture1 {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .texture2 {
                position: absolute;
                top: 0;
                right: 0%;
                height: 100%;
            }
        }
    }

    .pautas-contact-panel {
        .tabs{
            position: sticky;
            top: 55px;
            z-index: 900;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);

            
            .tab{
                width: 100%;
                padding: 10px 20px;
                justify-content: space-between;
                align-items: center;
                background: var(--background-color);
                border-radius: 0;
                text-align: left;
                
                font-size: .9em;
                font-weight: 600;
                cursor: pointer;
        
                $themes:(
                    "clasico":(
                        border: 1px solid #E3E3F9,
                        color: #6A6969
                    ),
                    "alto-contraste":(
                        border: 1px solid var(--onbackground-primary),
                        color: var(--onbackground-primary)
                    )
                );
                @include ThemesProperties($themes);
    
                &.current{
                    background: var(--secondary-color);
                    transition: all .2s;
                    display: flex;
    
                    $themes:(
                        "clasico":(
                            border: none,
                            color: var(--onsecondary-color)
                        ),
                        "alto-contraste":(
                            color: var(--onbackground-primary)
                        )
                    );
                    @include ThemesProperties($themes);
                }
            }
        }
        
        .panel{
            // min-height: 700px;
            border-radius: 30px 30px 0 0 !important;
            background-color: var(--secondary-color);
            border-radius: 10px 0 10px 0;

            $themes:(
                "alto-contraste":(
                    border: 1px solid var(--onbackground-primary)
                )
            );
            @include ThemesProperties($themes);

            .appointment-section{
                height: 100%;
                min-height: 400px;
                .appoinment-button{
                    width: 100%;
                    padding: 5px 10px;
                    margin-bottom: 30px;
                    color: var(--onprimary-inverse);
                    background-color: var(--primary-inverse);
                    border-radius: 5px;
                    font-family: var(--primary-font);
                    font-weight: 600;

                    &:hover{
                        transition: all .2s;
                        background-color: var(--onsecondary-color);
                        color: var(--onprimary-inverse);
                        border: 1px solid var(--onprimary-inverse);
                    }
                }
            }

            .animated-container{
                animation: page-content-anim .5s, backwards; 
            }
        }

        @media screen and (min-width: 992px) {
            padding: 3em 3em 0 3em;
            
            .tabs{
                display: block;
                position: relative;
                box-shadow: none;
                .tab{
                    border-radius: 10px 0 0 10px;
                    display: flex;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .book-package-table{
        background-color: var(--surface-color);
        border: solid 2px var(--secondary-inverse);
        border-radius: 0.5rem;

        .dashed-arrow{
            display: flex;
            align-items: center;
            color: var(--secondary-inverse);
            .dash{
                width: 150px;
                height: 1px;
                border-bottom: dashed 3px  var(--secondary-inverse);
            }
        }

        .table-border{
            &.top{
                border-top: solid 2px var(--secondary-inverse);  
            }
            &.bottom{
                border-bottom: solid 2px var(--secondary-inverse);  
            }
            &.left{
                border-left: solid 2px var(--secondary-inverse);  
            }
            &.right{
                border-right: solid 2px var(--secondary-inverse);  
            }
        }
        .table-row{
          padding: .5rem;
          font-family: var(--secondary-font);
          color: var(--onbackground-primary);
        }
    }

        .ilustration-background {
            width: 100%;
            background-image: url("/img/pautas/background.jpg");
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: cover;
    
        }

    .main-description{
        font-size: 1.3em;

        @media screen and (min-width: 768px) {
            font-size: 1.5em;
        }  
        @media screen and (min-width: 1200px) {
            font-size: 2.1em;
        }     
    }

    .dial-container{
        position: relative;
        top: -50px;
        .dial{
            font-weight: 700;
            font-family: var(--secondary-font);
            border-radius: .5rem;
        }
        .dial-icon{

            animation: levitate 2s ease-in-out infinite;
        }
        @keyframes levitate {
            0% {
              transform:rotate(90deg) translateX(30px) scale(1.5);
            }
            50% {
              transform:rotate(90deg) translateX(-10px) scale(1.25);
            }
            100% {
              transform:rotate(90deg) translateX(30px) scale(1.5);
            }
          }
    }
   
    .pauta-cta-link{
        transition: color .3s, background .3s, transform .3s;
        background-color: var(--secondary-inverse);
        color: var(--onsecondary-inverse);
        border-radius: .5rem;
        font-family: var(--secondary-font);
        font-weight: 700;
        &:hover{
            background-color: var(--primary-inverse);
            color: var(--onprimary-inverse);
            transform: translateY(-5%);
        }
    }
}