* {
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  button{
    border: none;
    outline: none;
    background: transparent;
  }

  main{
    // position: relative;
  }
  
.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.dropdown-arrow-transition{
  transition: transform .3s;
  &.expanded-dropdown{
    transform: rotate(180deg);
  }
  &.collapsed-dropdown-{
    transform: rotate(0);
  }
}