/*=====================================================
    QUE ES BIBLIOTECAS?
    En este archivo se encuentran los estilos de la
    pantalla de ¿qué es bibliotecas?. También tiene
    unos estilos que comparte con que ¿Qué es
    Colegios?
 ====================================================== */
.que-es-bibliotecas{
  .feature-background{
    background-position: center 15% !important; 
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;

    $themes:(
      "clasico":(
        background-image: url("/img/bibliotecas-que-es/back-que-es.svg")
      ),
      "alto-contraste":(
        background-image: url("/img/bibliotecas-que-es/back-que-es-contraste.svg")
      )
    );
  
    @include ThemesProperties($themes);
  }

  h1{
    font-weight: 600;
    font-size: 1.3em;
    @media (min-width: 992px) {
      font-size: 2em;
      font-weight: 800;
    }
  }

  .introduction{
    $themes:(
      "clasico":(
        background-color: #ebf4ff
      ),
      "alto-contraste":(
        background-color:var(--surface-color)
      )
    );
  
    @include ThemesProperties($themes);

    .ilustracion-inicial {
      width: 100%;
      height: 350px !important;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
    
      @media (min-width: 526px) {
        height: 400px !important;
        background-size: 80%;
      }

      @media (min-width: 768px) {
        background-size: 60%;
      }  
  
    
      @media (min-width: 992px) {
        height: 100% !important;
        background-size: 90%;
      }  
  
      @media (min-width: 1500px) {
        background-position: top left;
      }
      
    }
  
    .sub-title{
      border-left: 5px solid var(--blue-inverse);
    }
  }

  .training{
    position: relative;

    .shape{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background: url("/img/que-es/home/plans/books_shape.png");
      background-position: 0 0;
      background-repeat: repeat;
      background-size: 40%;
      z-index: -1;
      opacity: 0.3;
    }
    
    ul{
      li{
        .card{
          height: 100%;
          border-radius: 15px;
          background-color: var(--onsecondary-inverse);
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
          $themes: (
            "clasico": (
              background-color: #f4f4ff
            ),
            "alto-contraste": (
              background-color: var(--background-color)
            )
          );
          @include ThemesProperties($themes);
        }
      }
    }
  }

  .discover{
    background-color: var(--secondary-color);
    border:solid 2px var(--secondary-inverse);

    .learn-more-button{
      $themes:(
        "clasico":(
        color:var(--onred-color),
        background-color:var(--red-color),
        ),
        "alto-contraste":(
          color:var(--onbackground-primary),
          background-color:var(--surace-color),
          border:solid 2px var(--onbackground-primary)
        ),
      );
      @include ThemesProperties($themes);
    }
  }

  .experience{
    $themes:(
      "clasico":(
        background-color: #ebf4ff,
      ),
      "alto-contraste":(
        background-color: var(--surface-color),
        border: 1px solid,
        border-color: var(--onbackground-primary),
      ),
    );

    @include ThemesProperties($themes);

    .comments{
      .experience-libraries {
        width: 90%;
        border-radius: 15px;
        position: relative;
        $themes:(
          "clasico":(
            color: var(--onsecondary-color),
            background-color: #4A90E2
          ),
          "alto-contraste":(
            color: var(--onbackground-primary),
            border:solid 2px var(--onbackground-primary),
            background-color:var(--surface-color)
          )
        );
      
        @include ThemesProperties($themes);
      
        p {
          font-size: 0.9em !important;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box !important;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
        }
      
        &:before {
          content: "";
          width: 25px;
          height: 25px;
          position: absolute;
          $themes:(
            "clasico":(
              background-color: #4A90E2
              ),
              "alto-contraste":(
              background-color: var(--surface-color),
              border:solid 2px var(--onbackground-primary),
            )
          );
        
          @include ThemesProperties($themes);
          bottom: -10px;
          left: 50%;
          margin-left: -25px;
          transform: rotate(45deg);
        }
      }
    }
  } 
}



.enlace-que-es {
  cursor: pointer;
  color: var(--onbackground-primary) !important;
  font-weight: 800 !important;
  text-decoration: underline !important;

  &:hover {
    transition: all .5s;
    transform: scale(1.1) !important;
    text-decoration: none !important;
  }
}