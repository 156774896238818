@use "sass:map";

@mixin appendColorClasses($color) {
    &--color {
        color: $color  !important;
    }

    &--bg {
        background-color: $color  !important;
    }

    &--border-color {
        border:  $color !important;
    }
    &--borderb-color {
        border-bottom-color:  $color !important;
    }
    
    &--bordered {
        border: 1px solid $color !important;
    }
    &--bordered-actualizar {
        border: 3px solid $color !important;
    }
    &--placeholder::placeholder {
        color: $color  !important;
    }
}

@mixin ThemesProperties ($themes){
    @each $themeName, $properties in $themes{
        body[data-theme=#{$themeName}] & {
            @each $property, $value in $properties{
                #{$property}:#{$value} !important;
            }
          }
    }
}

@mixin focusable($type, $size, $color) {
    outline: $type $size transparent !important;
    &:focus-visible{
        outline: $type $size $color !important;
        $themes:(
            "alto-contraste":(
                outline: $type $size var(--onbackground-primary)
            )
        );
        
        @include ThemesProperties($themes);
    }
}

@mixin focusableByChildren($type, $size, $color) {
    outline: $type $size transparent !important;
    &:focus-within{
        outline: $type $size $color !important;
        $themes:(
            "alto-contraste":(
                outline: $type $size var(--onbackground-primary)
            )
        );
        
        @include ThemesProperties($themes);
    }
}
