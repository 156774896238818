/*=====================================================
    POPUP-DEMO
    Este archivo define los estilos del componente
    demo-popup
 ====================================================== */

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/*-------------------------------------------*\
    POPUP
 \*-------------------------------------------*/
.demo-popup-backdrop {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    animation: none;
}

.demo-popup-backdrop.visible {
    display: flex;
    pointer-events: all;
    animation: show-popup 0.3s backwards;
}

.demo-popup-backdrop.hidden {
    display: flex;
    pointer-events: none;
    animation: hide-popup 0.3s both;
}

.demo-popup-window {
    overflow: auto;
    position: relative;
    width: 100%;
    height: auto;
    max-width: 500px !important;
    max-height: 80vh !important;
    margin: .5rem;
    background-color: #fff;
    box-shadow: 0 0 10px 1px #f18840;
    border-radius: .8rem;
    $themes: (
        "alto-contraste":(background-color: var(--background-color),
            color:var(--onbackground-primary),
            border:solid 2px var(--onbackground-primary),
            box-shadow:none)
    );
@include ThemesProperties($themes);
}

.demo-popup-close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .3rem;
    background-color: #fff;
    border: solid 3px #f18840;
    border-radius: 99999px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: transform .3s, background-color .3s, border-color .3s;
    $themes: (
        "alto-contraste":(background-color: var(--background-color),
            color:var(--onbackground-primary),
            border:solid 3px var(--onbackground-primary))
    );
@include ThemesProperties($themes);
}

.demo-popup-close-button:hover {
    background-color: #f18840;
    transform: scale(1.2);
    $themes: (
        "alto-contraste":(background-color: var(--onbackground-primary),
            color:var(--background-color),
            border:solid 3px var(--onbackground-primary))
    );
@include ThemesProperties($themes);
}


.demo-popup-close-button .icon {
    width: 100%;
    height: 3px;
    background-color: #f18840;
    $themes: (
        "alto-contraste":(background-color:var(--onbackground-primary),
        )
    );
@include ThemesProperties($themes);
}

.demo-popup-close-button:hover .icon {
    background-color: #fff;
    $themes: (
        "alto-contraste":(background-color:var(--background-color),
        )
    );
@include ThemesProperties($themes);
}

.demo-popup-content {
    // max-height: 800px;
    // min-height: 100%;
}


.demo-popup-form-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.demo-popup-title {
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    color: #3b58a1;
    text-align: center;
    $themes: (
        "alto-contraste":(color:var(--onbackground-primary),
        )
    );
@include ThemesProperties($themes);
}

.demo-popup-logo {
    width: 120px;
    margin: .5rem;
}


.demo-popup-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.demo-popup-form fieldset {
    min-width: 95%;
    margin-top: 1rem;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
}

.demo-popup-form label {
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    color: #f18840;
    $themes: (
        "alto-contraste":(color:var(--onbackground-primary),
        )
    );
@include ThemesProperties($themes);
}

.demo-popup-form input,
.demo-popup-form textarea {
    outline: none;
    padding: .2rem;
    font-family: 'Raleway', sans-serif;
    color: #3b58a1;
    border: solid 2px #f18840;
    border-radius: .5rem;
    padding-left: .5rem;
    transition: color .3s, border .3s;
    $themes: (
        "alto-contraste":(background-color: var(--background-color),
            color:var(--onbackground-primary),
            border:solid 2px var(--onbackground-primary))
    );
@include ThemesProperties($themes);
}

.demo-popup-form input::placeholder,
.demo-popup-form textarea::placeholder {
    $themes: (
        "alto-contraste":(color:var(--onbackground-primary),
        )
    );
@include ThemesProperties($themes);
}


.demo-popup-submit {
    place-self: end;
    padding: .4rem;
    border-radius: .5rem;
    border: none;
    outline: none;
    background-color: #eebf58;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: transform .3s, background-color .3s, color .3s;
    $themes: (
        "alto-contraste":(background-color: var(--background-color),
            color:var(--onbackground-primary),
            border:solid 2px var(--onbackground-primary))
    );
@include ThemesProperties($themes);
}

.demo-popup-submit.sending-form {
    color: #fff;
    background-color: #3b58a1;
    $themes: (
        "alto-contraste":(background-color: var(--onbackground-primary),
            color:var(--background-color),
            border:solid 2px var(--onbackground-primary))
    );
@include ThemesProperties($themes);
}


.demo-popup-submit:hover {
    transform: scale(1.05);
    color: #fff;
    background-color: #3b58a1;
    $themes: (
        "alto-contraste":(background-color: var(--onbackground-primary),
            color:var(--background-color),
            border:solid 2px var(--onbackground-primary))
    );
@include ThemesProperties($themes);
}


@media screen and (max-width: 500px) {
    .demo-popup-window {
        max-width: 90%;
        max-height: 90vh;
    }
}

@media screen and (max-height: 500px) {
    .demo-popup-window {
        height: 90vh;
    }
}

.demo-popup-confirmation-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: .5rem;
}

.demo-popup-form-view.hidden,
.demo-popup-confirmation-view.hidden {
    display: none;
}

.demo-popup-confirmation-view .logo {
    width: 100px;
    margin: .5rem;
}

.demo-popup-confirmation-view .title {
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    color: #3b58a1;
    text-align: center;
    $themes: (
        "alto-contraste":(color:var(--onbackground-primary),
        )
    );
@include ThemesProperties($themes);
}

.demo-popup-confirmation-view .description {
    font-family: 'Quicksand', sans-serif;
    text-align: center;
    padding-bottom: 1rem;
}

.demo-popup-confirmation-view .button {
    margin: 1rem;
    padding: .4rem;
    border-radius: .5rem;
    border: none;
    outline: none;
    background-color: #eebf58;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: transform .3s, background-color .3s, color .3s;
    $themes: (
        "alto-contraste":(background-color: var(--background-color),
            color:var(--onbackground-primary),
            border:solid 2px var(--onbackground-primary))
    );
@include ThemesProperties($themes);
}


.demo-popup-confirmation-view .button:hover {
    transform: scale(1.05);
    color: #fff;
    background-color: #3b58a1;
    $themes: (
        "alto-contraste":(background-color: var(--onbackground-primary),
            color:var(--background-color),
            border:solid 2px var(--onbackground-primary))
    );
@include ThemesProperties($themes);
}

/*-------------------------------------------*\
    BOTÓN POPUP. despliega el popup
 \*-------------------------------------------*/

.demo-popup-fixed-container {
    z-index: 9999999;
    position: fixed;
    width: 100%;
    bottom: 0%;
}

.demo-popup-open-card {
    border-radius: 1rem;
    border: none;
    outline: none;
    background-color: #f18840;
    box-shadow: 0 0 10px 1px #f18840;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    transition: background-color .3s, border-color .3s;
    $themes: (
        "alto-contraste":(background-color: var(--background-color),
            box-shadow: 0 0 10px 1px var(--onbackground-primary),
        )
    );
@include ThemesProperties($themes);
}

.demo-popup-open-card.visible {
    animation: show-popup-card .3s forwards;
}

.demo-popup-open-card.hidden {
    animation: hide-popup-card .3s forwards;

}


.demo-popup-open-card .title {
    padding-right: .5rem;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    color: #fff;
    $themes: (
        "alto-contraste":(color:var(--onbackground-primary),
        )
    );
@include ThemesProperties($themes);
}

.demo-popup-open-card .button {
    padding: .4rem;
    border-radius: .5rem;
    border: none;
    outline: none;
    color: #fff;
    background-color: #3b58a1;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: transform .3s, background-color .3s, color .3s;
    $themes: (
        "alto-contraste":(background-color: var(--background-color),
            color:var(--onbackground-primary),
            border:solid 2px var(--onbackground-primary))
    );
@include ThemesProperties($themes);
}

.demo-popup-open-card .button:hover {
    transform: scale(1.05);
    background-color: #eebf58;
    color: #000;
    $themes: (
        "alto-contraste":(background-color: var(--onbackground-primary),
            color:var(--background-color),
            border:solid 2px var(--onbackground-primary))
    );
@include ThemesProperties($themes);
}

.demo-popup-open-card .logo {
    width: 110px;
    margin: .5rem;
}


@keyframes show-popup-card {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes hide-popup-card {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(150%);
    }
}

@keyframes show-popup {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;

    }
}

@keyframes hide-popup {
    0% {
        opacity: 1;

    }

    100% {
        opacity: 0;

    }
}