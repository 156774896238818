.copy-box {
    position: relative;
    cursor: pointer;
    width: 90%;
  
    .copy-box-input {
      width: 100%;
      padding: 10px;
      padding-right: 40px;
      box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.4);
    }
  
    > .copy-box-button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: flex;
      margin-right: 10px;
      justify-content: center;
      align-items: center;
      transition: color 0.3s, background-color 0.3s;
  
      &:hover {
        color: var(--onprimary-color);
      }
    }
  }