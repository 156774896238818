/*=====================================================
                    SKELETONS
 ====================================================== */
.header-skeleton{
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 100;
} 

.skeleton-icon{
  width: 25px;
  height: 25px;
  border-radius: 50%
}
.skeleton-text{
  width: 100px;
  height: 20px;
  border-radius: 3px;
}
.skeleton-search{
  width:250px;
}
.skeleton-logo{
  width: 120px;
  height: 40px;
  border-radius: 5px;
}

.skeleton-background{
  background-color: #f1f1f1;
}

.skeleton-component{
  background-color: #cccccc;
}

.skeleton-medium{
  background-color: #e3e3e3;
}

.story-skeleton{
  width:140px;
  height: 140px;
  border-radius: 50%;
}

.banner-component{
  height: 260px;
  border-radius: 10px;
}

.title-skeleton{
  border-radius: 3px;
  height: 30px;
}

.title-skeleton-md{
  max-width: 200px;
  @extend .title-skeleton;
}

.title-skeleton-sm{
  max-width: 130px;
  @extend .title-skeleton;
}

.title-skeleton-lg{
  max-width: 400px;
  @extend .title-skeleton;
}

.cover-skeleton{
  border-radius: 10px;
  height: 350px;
  width: 100%;
}

.colection-skeleton{
  height: 220px;
}

.ilustrados-skeleton{
  height: 300px;
}

/*********************
        Menu
**********************/
.skeleton-icon-subject{  
  width: 105px;
  height: 105px;  
  border-radius: 50%;
  margin: 0 auto;
}
.title-skeleton-menu{
  margin: 0 auto;
}
.skeleton-text{
  margin: 0 auto;
}
.skeleton-reading-level{
  height: 56px;  
  border-radius: 10px;
}
.skeleton-gender{  
  height: 40px;    
  border-radius: 10px;
}
.skeleton-age{  
  height: 50px;    
  border-radius: 10px;
}