

/*
 Tamaños de fuente desde text-8 hasta text-80.
 estos tamaños son calculados a partir de la fuente base y se adaptan a la fuente principal en 'em'.
*/
@for $i from 4 through 80 {
    $size: $i * 2; 
    .text-#{$size} {
      font-size: pxToEm($size) !important; 
    }
  }


/*
 Alturas de línea desde text-line-h-8 hasta text-line-h-80.
 estos tamaños son calculados a partir de la fuente base y se adaptan a la fuente principal en 'em'.
*/
@for $i from 4 through 80 {
    $size: $i * 2; 
    .text-line-h-#{$size} {
      line-height: pxToEm($size) !important; 
    }
  }