/*===========================================================================================
  plector-recuperar

  >**NOTA:** se debe revisar las clases de este archivo ya que aparentemente no se usan. 
 ============================================================================================ */

.recuperar-contrasena {
  .dashboard-importantzone {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: var(--onbackground-primary);
  }
}
