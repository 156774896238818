/*=====================================================
  Aquí se encuentran algunos de los estilos de la sección de soporte de mi panel
 ====================================================== */
.dashboard-support--list {
  li {
    cursor: pointer;
  }
}

.support-list {
  h3 {
    font-size: 1 em;
  }
}

@mixin titulo($bottom: none) {
  border-bottom: $bottom !important;
  transition: 0.2s ease-in;
  border: 1px solid var(--onbackground-fourth);
  text-align: start;

  &:hover {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  }
}

.list-main-item {
  font-family: var(--primary-font);
  cursor: pointer;

  .title {
    @include titulo($bottom: none);
  }

  .title-2 {
    @include titulo($bottom: 1px solid var(--onbackground-fourth) );
  }

  .list-selector {
    font-weight: bold;
    font-family: var(--secondary-font);
    font-size: 1em !important;
  }

  textarea {
    resize: none;
  }
}

.list-content {
  border-left: 1px solid var(--onbackground-fourth);
  border-right: 1px solid var(--onbackground-fourth);
  overflow: hidden;
  transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) all;

  li {
    &:not(:last-child) {
      border-bottom: 1px solid var(--onbackground-fourth);
    }
  }
}

.list-content-4 {
  border-bottom: 1px solid var(--onbackground-fourth);
}

/*=====================================================
                SOPORTE ACTUALIZACION 2023
 ====================================================== */

.descripcion-soporte {
  font-weight: 700;
  font-size: 0.9em;

  $themes: ("clasico":(color: #6A6969,
    ),
    "alto-contraste":(color: var(--violet-inverse),
    ));

  @include ThemesProperties($themes);
}

.contenido-soporte {
  border-top: 1px solid;

  $themes: ("clasico":(border-color: #979797),
    "alto-contraste":(border-color: var(--onprimary-color)));

  @include ThemesProperties($themes);

}

.break-words {
  word-break: break-word;
}

.titulo-contacto {
  font-weight: bold;
  font-size: 0.9em;
  font-family: var(--primary-font);
  color: var(--secondary-color);
}

.info-contacto {
  font-family: var(--secondary-font);
  font-weight: bold;
  font-size: 0.9em;

  $themes: ("clasico":(color: #2C2B2C),
    "alto-contraste": (color:var(--onprimary-color)));

  @include ThemesProperties($themes);

  a {
    cursor: pointer;

    &:hover {
      $themes: ("clasico":(color: #2C2B2C),
        "alto-contraste": (color: var(--onprimary-color)));

      @include ThemesProperties($themes);
    }
  }
}

/*-------------------------------------------*\
  Acordeon
\*-------------------------------------------*/
@mixin transicionAll() {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.acordeon-soporte {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--secondary-inverse);
  background-color: var(--surface-color);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 30;
  $themes: (
    "clasico":(
      border-bottom: 1px solid var(--secondary-inverse)
    ),
    "alto-contraste":(
      border: 1px solid var(--secondary-inverse)  
    )
  );
  @include ThemesProperties($themes);

  &:hover {
    transform: scale(1.01);
    @include transicionAll();
    $themes: (
      "clasico":(
        background-color: #F1F1FF
      ),
      "alto-contraste":(
        background-color: var(--disabled-color),
        color: var(--surface-color)
      )
    );
    @include ThemesProperties($themes);
  }

  h3 {
    font-family: var(--secondary-font);
    font-size: 0.9em;
  }
}

.acordeon_active {
  border: none;
}

.icono-rotate {
  transform: rotate(180deg);
  transition-duration: 500ms;
}

.preguntasFrecuentes {
  @include transicionAll();
  color: var(--onbackground-secondary);
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  z-index: 20;
  $themes: (
    "clasico":(
      background-color: #F1F1FF
    ),
    "alto-contraste":(
      background-color: var(--surface-color),
      border: 1px solid var(--secondary-inverse)
    )
  );
  @include ThemesProperties($themes);

}

.caja-video {
  height: 350px;
  position: relative;
}

/*-------------------------------------------*\
  Formularios
\*-------------------------------------------*/
.titulo-formularios {
  font-size: 0.9em;
}

.selector-formularios {
  width: 100%;
  cursor: pointer;
  font-size: 0.9em;
  font-family: var(--secondary-font);
  font-weight: 700;
  color: var(--secondary-inverse);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  $themes: ("clasico":(background: rgba(102, 102, 204, 0.26087)),
    "alto-contraste":(background: var(--onsecondary-inverse)));

  @include ThemesProperties($themes);
}

.borde-redondeado {
  border-radius: 5px
}

.borde-redondeado-superior {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.selector-opciones {
  background-color: var(--surface-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 100%;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  overflow: hidden;
  transition: all;
  transition-duration: 0.5s;
  max-height: 320px;
  z-index: 100;

  li {
    padding: 5px 15px;
  }

  .opcion {
    cursor: pointer;
    font-size: 0.8em;
    font-family: var(--primary-font);
    color: #4A4A4A;

    &:hover {
      background-color: #F1F1FF;
    }
  }

  .opcion-default {
    border-bottom: 1px solid #262626;
    font-family: var(--secondary-font);
    font-weight: 700;
    font-size: 0.9em;
    color: var(--secondary-color);
  }
}

.pregunta-formulario {
  font-family: var(--secondary-font);
  font-weight: 700;
  font-size: 0.9em;
  color: #000;
  display: inline-block;
}

.asterisco {
  color: var(--red-color);
  font-family: var(--secondary-font);
  font-weight: 700;
  font-size: 1em;
  line-height: 1;
}

.input-text-soporte {
  padding: 0 40px 0 12px !important;
  background: var(--surface-color);
  border: 1px solid #979797;
  height: 36px;
  border-radius: 4px;
  font-size: 0.9em;

  &:focus-visible {
    outline: 2px solid var(--secondary-color);
    border: none !important;
  }
}

.radio-boton-soporte {
  cursor: pointer;
  position: relative;
  display: block;
  padding: 15px;
  padding-left: 40px;
  font-family: var(--secondary-font);
  font-weight: 400;
  color: #262626;
  font-size: 0.9em;

  input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    visibility: hidden;

    &:checked+.radio:after {
      background: var(--secondary-color);
      transform: scale(1);
      opacity: 1;
    }
  }

  &:hover {
    input[type=radio]:not(:checked)+.radio {
      background: rgba(102, 102, 204, 0.26087);
    }
  }


}

.radio {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 2px solid var(--secondary-color);
  position: absolute;
  left: 0;

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transform: scale(0);
    transition-duration: 200ms;
  }
}

.radio-boton-soporte-bottom {
  border-bottom: 1px solid #979797;
}

.textarea-soporte {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: var(--surface-color);
  border: 1px solid #979797;
  border-radius: 6px;
  height: 144px;
  width: 100%;
  font-size: 0.8em;
  resize: none;
  font-family: var(--primary-font);
  color: #6A6969;
  padding: 10px;

  &:focus{
    border:  none !important;
    outline: 2px solid var(--secondary-color);
    color: var(--onprimary-color);
  }
}

.alert_error{
  font-size: 0.7em;
  font-family: var(--secondary-font);
  font-weight: 700;
  color: var(--red-color);
}

.input_error{
  border: 2px solid var(--red-color);
}