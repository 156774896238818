/*=======================================
  MENU RESPONSIVE
=========================================*/
.menuMobile{
  width: 100%;
  position: fixed;
  bottom: 0;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px -2px 4px 0px #00000040;
  z-index: var(--z-mobile-bottom-nav);

  $themes: (
    "clasico":(
      background-color: #E3E3F9
    ),
    "alto-contraste":(
      background-color: var(--background-color),
      border: 1px solid var(--onprimary-color),
      box-shadow: 0 1px 15px #9f9f9f
    )
  );
  @include ThemesProperties($themes);
  
  .btn-option-menu{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    color: var(--onbackground-primary);
    padding: 15px 10px;
    
    span{
      width: 100%;
      font-size: 15px;
      font-weight: 600;
      color: var(--onbackground-primary);
      text-align: center;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 5px;
    }

    &.activeMode{
      background: var(--secondary-inverse);
      color: var(--onsecondary-inverse);
      span{
        color: var(--onsecondary-inverse);
      }
    }
  }

  

  @mixin dropdownMenuResponsive{
    width: 100%;
    background: var(--secondary-inverse);
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -2px 4px 0px #00000040;
    position: absolute;
    bottom: 100%;

    -webkit-transition: max-height .3s, padding .3s, visibility .3s;
    -o-transition: max-height .3s, padding .3s, visibility .3s;
    transition: max-height .3s, padding .3s, visibility .3s;


    &.activeMode {
      max-height: 400px;
      visibility: visible;
    }

    &.inactiveMode {
      max-height: 0px;
      visibility: hidden;
    }

    li {
      text-align: center;
      padding: 20px 10px;
      a, button{
        color: var(--onsecondary-inverse);
        font-size: .9em;
        font-weight: 600;
      }

      &.activeMode {
        opacity: 1;
        visibility: visible;
        /* transform: translateY(0); */
        transition: .3s ease;
        
      }

      &.inactiveMode {
        opacity: 0;
        padding: 0;
        visibility: hidden;
        /* transform: translateY(-20px); */
        transition: .3s ease;
      }

    }
  }

  .dropdownGeneralMenu{
    @include dropdownMenuResponsive;
  }

  .dropdownPublicMenu{
    @include dropdownMenuResponsive;
  }
}