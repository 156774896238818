.community-nav {
  $themes: (
    "clasico": (
      border: solid 1px #6666cc
    ),
    "alto-contraste": (
      border: solid 1px var(--onbackground-primary)
    )
  );

  @include ThemesProperties($themes);

  .item {
    $border-size: 3px;
    text-transform: uppercase;
    font-weight: 700;
    transition: border 0.3s;
    $themes: (
      "clasico": (
        color: #2e2e7c,
        border-bottom: solid #{$border-size} transparent
      ),
      "alto-contraste": (
        color: var(--onbackground-primary),
        border-bottom: solid #{$border-size} transparent
      )
    );

    @include ThemesProperties($themes);

    &:hover,
    &.active {
      $themes: (
        "clasico": (
          border-bottom: solid #{$border-size} #2e2e7c
        ),
        "alto-contraste": (
          border-bottom: solid #{$border-size} var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);
    }
  }

  .link {
    text-transform: uppercase;
    font-weight: 700;
    $themes: (
      "clasico": (
        color: #2e2e7c
      ),
      "alto-contraste": (
        color: var(--onbackground-primary)
      )
    );

    @include ThemesProperties($themes);
  }

  .social-media-button {
    @mixin socialMediaButton($color, $hover-color) {
      transition:
        color 0.3s,
        transform 0.3s;
      $themes: (
        "clasico": (
          color: $color
        ),
        "alto-contraste": (
          color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);

      &:hover {
        transform: translateY(-5%) scale(1.05);
        $themes: (
          "clasico": (
            color: $hover-color
          ),
          "alto-contraste": (
            color: var(--onbackground-primary)
          )
        );
        @include ThemesProperties($themes);
      }
    }

    &.appstore {
      @include socialMediaButton(var(#fff), #1bb9fa);
    }

    &.playstore {
      @include socialMediaButton(var(#fff), #01875f);
    }

    &.facebook {
      @include socialMediaButton(var(#fff), #0866ff);
    }

    &.instagram {
      @include socialMediaButton(var(#fff), #b831ac);
    }

    &.youtube {
      @include socialMediaButton(var(#fff), #ff0000);
    }

    &.whastapp {
      @include socialMediaButton(var(#fff), #2bb741);
    }
  }
  .underline {
    text-decoration: underline;
  }
}

.community-title {
  .title {
    font-family: var(--secondary-font);
  }
  .line {
    height: 2px;
  }
}

.community-month-selector {
  .nav-button{
    padding: 1rem;
    margin: .5rem;
    color: var(--secondary-inverse);
    transition: color .3s, opacity .3s, transform .3s;
    &:hover{
      transform: scale(1.1);
    }
    &:disabled{
    color: var(--onbackground-primary);
      opacity: 0;
    }
  }
  .item {
    border-radius: 0.5rem;
    text-transform: uppercase;
    font-weight: 500;
    border: solid 2px transparent;
    cursor: pointer;
    transition:
      colors 0.3s,
      background-color 0.3s, border .3s;
    $themes: (
      "clasico": (
        color: var(--onbackground-primary),
        background-color: #e7e7e7
      ),
      "alto-contraste": (
        color: var(--onbackground-primary),
        background-color: var(--background-color)
      )
    );
    @include ThemesProperties($themes);

    &:hover:not(.active) {
      $themes: (
        "clasico": (
          color: var(--secondary-inverse),
          border: solid 2px var(--secondary-inverse)
        ),
        "alto-contraste": (
          color: var(--secondary-inverse),
          border: solid 2px var(--secondary-inverse)
        )
      );
      @include ThemesProperties($themes);
    }
    &.active {
      $themes: (
        "clasico": (
          color: var(--onsecondary-inverse),
          background-color: var(--secondary-inverse)
        ),
        "alto-contraste": (
          color: var(--onsecondary-inverse),
          background-color: var(--secondary-inverse)
        )
      );
      @include ThemesProperties($themes);
    }
  }
}

  .community-book-demo-cta {
    .shape {
      background-image: url("/img/que-es/home/demo/demo-running.png");
      background-position: 0%;
      background-size: contain;
      background-repeat: no-repeat;
      width: 200px;
      height: 200px;
      margin: auto;
      $themes: (
        "clasico": (
          background-color: transparent
        ),
        "alto-contraste": (
          background-color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);

      @media (min-width: 992px) {
        width: 465px;
        height: 332px;
      }

      @media (min-width: 1200px) {
        width: 470px;
        height: 266px;
        background-size: contain;
        background-position: 5% -10%;
      }

      @media (min-width: 1500px) {
        width: 670px;
        height: 266px;
        background-size: 75%;
        background-position: 100% 12%;
      }
    }
  }

  .community-book-demo-cta,
  .community-newsletter-cta {
    $themes: (
      "clasico": (
        background-color: #e3e3f9
      ),
      "alto-contraste": (
        background-color: var(--background-color)
      )
    );

    @include ThemesProperties($themes);
  }

  .community-newsletter-cta {
    h2 {
      font-size: 1.2em;
    }
    .shape {
      background-image: url("/img/que-es/home/newsletter/newsletter-speaker.png");
      background-position: 0%;
      background-size: contain;
      background-repeat: no-repeat;
      width: 200px;
      height: 200px;
      margin: auto;

      $themes: (
        "clasico": (
          background-color: transparent
        ),
        "alto-contraste": (
          background-color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);

      @media (min-width: 992px) {
        width: 465px;
        height: 332px;
      }

      @media (min-width: 1200px) {
        width: 555px;
        background-size: cover;
        background-position: 5% -10%;
      }

      @media (min-width: 1500px) {
        width: 670px;
        height: 266px;
        background-size: 75%;
        background-position: 0% 12%;
      }
    }
  }

  .community-selector {
    .item {
      font-weight: 600;
      transition:
        color 0.3s,
        background-color 0.3s;
        $themes: (
          "clasico": (
            color: var(--onbackground-secondary),
            background-color: #e7e7e7
          ),
          "alto-contraste": (
            color: var(--onbackground-secondary),
            background-color: var(--background-color)
          )
        );
        @include ThemesProperties($themes);

        &.purple{
          &:hover:not(.active) {
            color: var(--secondary-inverse) !important;
          }
          &.active {
            $themes: (
              "clasico": (
                background-color: var(--secondary-inverse),
                color: var(--onsecondary-inverse)
              ),
              "alto-contraste": (
                background-color: var(--secondary-inverse),
                color: var(--onsecondary-inverse)
              )
            );
            @include ThemesProperties($themes);
          }
        }
   
        &.yellow{
          &:hover:not(.active) {
            color: var(--onbackground-primary) !important;
          }
          &.active {
            $themes: (
              "clasico": (
                background-color: var(--primary-inverse),
                color: var(--onprimary-inverse)
              ),
              "alto-contraste": (
                background-color: var(--primary-inverse),
                color: var(--onprimary-inverse)
              )
            );
            @include ThemesProperties($themes);
          }
        }
    }
  }