/*=====================================================
    INPUT
    En este archivo se definen los estilos del 
    input y checkbox que se ubican en los filtros
    de búsqueda.
 ====================================================== */

/*-------------------------------------------*\
  SEARCH INPUT
\*-------------------------------------------*/

.search-input-container {
    position: relative;
    display: inline-flex;
    height: fit-content;
    width: 100%;

    .input {
        border-radius: .5rem;
        color: var(--onbackground-primary);
        background-color: var(--surface-color);
        border: solid 1px var(--onbackground-secondary);
        padding-right: 2em;
        width: 100%;
        box-shadow: none;
        outline: none;
        border: solid 2px var(--onbackground-secondary);

        &:focus,
        &:active {
            box-shadow: none;
            outline: none;
            border: solid 2px var(--secondary-inverse);
        }
    }

    .button {
        color: var(--onbackground-fourth);
        position: absolute;
        top: 0;
        right: 0%;
    }

}

/*-------------------------------------------*\
   CHECKBOX
\*-------------------------------------------*/

.checkbox[type=checkbox] {
    /* Reset */
    -webkit-appearance: none;
    cursor: pointer;
    appearance: none;
    background-color: var(--surface-color);
    width: 1em;
    height: 1em;
    margin: 0;
    margin-right: 25px;
    position: relative;
    text-align: center;

    font-size: 16px;

    &:focus {
        box-shadow: none;
        outline: none;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 1em;
        height: 1em;
        border: 2.5px solid var(--secondary-inverse);
        background: var(--surface-color);
        box-sizing: content-box;
        border-radius: 3px;
    }

    &:checked::before {
        border: 2.5px solid var(--secondary-inverse);
        background: transparent;
        background: var(--secondary-inverse);
    }

    &::after {
        content: '✓';
        text-indent: 4px;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 1em;
        height: 1em;
        box-sizing: content-box;
        text-align: center;
    }

    &:checked::after {
        display: block;
        color: var(--onsecondary-inverse);
    }

    &.focus:focus-visible::before {
        outline: solid 2px var(--primary-inverse);
    }

}