/*=====================================================
    TIENDA-MAKEMAKE

    Este archivo contiene los estilos generales de 
    la tienda
 ====================================================== */


@use "sass:map";

.tienda-makemake {
    position: relative;
    min-height: inherit;

    >.section-header {
        padding-top: 1rem;
        padding-left: 4rem;
        padding-bottom: 2rem;

        >.title {
            color: var(--red-inverse);
        }

        >.description {
            color: var(--onbackground-primary);
        }
    }

    >.shape {
        position: absolute;
        top: -2rem;
        right: 0;
        width: 400px;
        height: 180px;
        z-index: 0;
    }

    .tab-panel {
        min-height: 90vh;
        z-index: 1;
        padding-top: 1rem;
        .tabs {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 0;
            padding-left: 1rem;
            padding-right: 1rem;

            .tab {
                width: max-content;
                margin-left: .25rem;
                margin-right: .25rem;
                background-color: var(--background-color);
                color: var(--onbackground-third);
                font-weight: bold;
                padding: 5px 20px;
                border-radius: 1.5rem 1.5rem 0px 0px;
                font-size: .9em;
                display: flex;
                justify-content: center;
                align-items: center;

                &.current.institucional {
                    background-color: #FFF1B4;
                    color: var(--onbackground-primary);
                }

                &.current.individual {
                    background-color: #81559D;
                    color: var(--onsecondary-inverse);
                }

                &.current.cursos {
                    background-color: #E6F5F9;
                    color: var(--onbackground-primary);
                }

                &.current.demo {
                    background-color: #b1cf83;
                    color: var(--onbackground-primary);
                }

                &.current.institucional,
                &.current.individual,
                &.current.cursos,
                &.current.demo {
                    $themes: ("alto-contraste":(background-color:transparent,
                            border: solid 2px var(--secondary-inverse),
                            color:var(--secondary-inverse)));

                    @include ThemesProperties($themes);
                }

                .icon {
                    width: 70px;
                    height: 70px;
                    object-fit: contain;
                    $themes: ("alto-contraste":(background-color:var(--secondary-inverse),
                        ));
                    @include ThemesProperties($themes);
                }
            }
        }

    }
    .individual-section{
        background-color:#ffffff;
        background: url("/img/background.png") center/cover fixed repeat;
    }

    .institucional-section{
        background-color: #FFF1B4;
        min-height: 90vh;
    }

    .cursos-section{
        background-color: #E6F5F9;
    }

    .demo-section{
        background-color: #b1cf83;
        min-height: 90vh;
    }

    .individual-section,
    .institucional-section,
    .cursos-section,
    .demo-section {
        $themes: ("alto-contraste":(
            background-color:transparent,
            ));
        @include ThemesProperties($themes);
    }

    .tienda-breadcrumbs{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: .5rem;
        background-color: #81559D;
        color: #fff;
        font-size: .8em;
        $themes: ("alto-contraste":(
            background-color:var(--secondary-inverse),
            ));
        @include ThemesProperties($themes);

        .crumb{
            cursor: pointer;
            &:hover *{
                color: #ececec;

            }
            *{
                color: #fff;
                transition: color .3s;
                $themes: ("alto-contraste":(
                    color:var(--onsecondary-inverse),
                    ));
                @include ThemesProperties($themes);
            }
            &.current{
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width:map.get($grid-breakpoints, "lg")) {
    .tienda-makemake {
        >.section-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: .5rem;
            padding-right: .5rem;
            text-align: center;
        }

        >.tab-panel {
            .tabs {
                flex-direction: column;
                border: solid 2px var(--onbackground-fourth);
                border-radius: 1rem;
                overflow: hidden;
                padding: 0;
                margin-bottom: 1rem;
                margin-left: .5rem;
                margin-right: .5rem;
                width: auto;
                flex-grow: 1;
                position: sticky;
                top: 60px;
                z-index: 2000;

                .tab {
                    flex-grow: 1;
                    width: 100%;
                    padding: .5rem;
                    margin: 0;
                    border-radius: 0px;

                    .icon {
                        display: none;
                    }
                    &.current{
                        box-shadow: 0 0 10px 0 rgba(0,0,0,.25);
                    }
                }
            }
        }
    }
}

@media screen and (max-width:map.get($grid-breakpoints, "xl")) {
    .tienda-makemake {
        >.shape {
            display: none;
        }
    }

}