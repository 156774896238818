/*===========================================================================================
 Ficha colección
 En este archivo se establecen los estilos para la ficha de colección. También se establecen 
 los estilos que comparte con la ficha de editorial
 ============================================================================================ */

.coleccion-container {
  overflow: hidden;
  position: relative;
  font-weight: 700;
  h1 {
    font-size: 2.5em;
    line-height: 1;
    text-align: center;
    text-transform: initial;
  }
  .container {
    position: relative;
    z-index: 2;
  }
}

.coleccion-background {
  background-image: url("/img/background.png") repeat;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60%;
  background-blend-mode: multiply;
}
.coleccion-editorial {
  height: 100%;
  top: 400px;
}

.coleccion-autor {
  font-size: 1.2em;
}

.carrusel-coleccion {
  padding: 0 0 80px !important;

  .swiper-pagination {
    bottom: 30px !important;
  }

  .swiper-pagination-bullet {
    color: var(--onsecondary-color);
    font-size: 15px;
    height: 30px !important;
    text-align: center;
    width: 30px !important;

    $themes:(
      "clasico":(
        border:none,
        padding-top: 3px,
      ),
      "alto-contraste":(
        border: 1px solid var(--onsecondary-color),
        padding-top: 0,
      )
    );
    @include ThemesProperties($themes);
  }

  .collection-swiper-navigation-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: var(--secondary-inverse);
    color: var(--onsecondary-inverse);
    cursor: pointer;
    transition: color .3s, background-color .3s;

    &:hover {
      background-color: var(--third-inverse);
      color: var(--onthird-inverse);
    }

    &:disabled {
      background-color: var(--disabled-color);
      color: var(--ondisabled-color);
      border: solid 2px var(--disabled-color);
      cursor: auto;
      opacity: .5;
    }
  }
}

.coleccion-controlers {
  span {
    background-color: var(--secondary-color);
    color: white;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      color: var(--primary-color);
    }
    &:focus {
      color: var(--primary-color);
    }
    .disabled {
      opacity: 0.8;
    }
  }
}

.coleccion-controler-dots {
  div {
    background-color: var(--onbackground-secondary);
    cursor: pointer;
    width: 15px;
    height: 15px;
    border-radius: 20px;

    &:hover {
      background-color: var(--onbackground-primary);
    }
    &:focus {
      background-color: var(--onbackground-primary);
    }
  }
  .current {
    background-color: var(--onbackground-primary);
  }
}
.covers-collection {
  .swiper-button-next,
  .swiper-button-prev {
    top: 95% !important;
    padding: 0 10px !important;
    width: auto !important;
  }
}

@media only screen and (min-width: 526px) {
  .coleccion-container {
    h1 {
      font-size: 4em;
    }
  }
}

@media only screen and (min-width: 992px) {
  .coleccion-container {
    h1 {
      text-align: right;
    }
  }
  .coleccion-background {
    clip-path: polygon(0 0, 100% 50%, 100% 100%, 0% 100%);
  }

  .coleccion-editorial {
    clip-path: polygon(0 0, 100% 150px, 100% 100%, 0% 100%);
  }

  .fichalibro-data--plusinfo {
    > .row {
      flex-direction: row;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .coleccion-container {
    h1 {
      font-size: 4.2em;
    }
  }
}
