/*=====================================================
    CARRUSEL
    Este archivo define los estilos de los carruseles 
    o swipers.
 ====================================================== */

.swiper-button {
  $themes:(
    "clasico":(
      background-color: rgba(255, 255, 255, 0.7),
      color: var(--secondary-color),
      border: 1px solid #d2d9f7

      
      ),
      "alto-contraste":(
      background-color: var(--surface-color),
      color: var(--onbackground-primary),
      border: 1px solid var(--onbackground-primary)
    )
  );

  @include ThemesProperties($themes);

  outline: none;
  padding: 10px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.3s, color 0.3s, background-color 0.3s, opacity 0.3s;
  .icon {
    transform: scale(1.5);
    width: 50px;
    height: 100px;
  }
}

.swiper-button:hover {
  body[data-theme="clasico"] &{
    background-color: var(--secondary-color);
    opacity: 0.5;
    border: 1px solid #d2d9f7;
    color: #fff;
  }
  .icon {
    transform: scale(1.8);
  }
}

.swiper-button-left {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}
.swiper-button-right {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}


.swiper-pagination-bullet{
    $themes:(
      "alto-contraste":(
        border: 1px solid var(--onbackground-primary),
      )
    );
    @include ThemesProperties($themes);
}

@media only screen and (max-width:992px) {
  .swiper-button{
    display: none;
  }
}