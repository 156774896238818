.piloto-rnbp {
  .content-header {
    position: relative;
    background: none;
    background-image: none;

    .texture {
      position: absolute;
      left: 50%;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-50%);
      overflow: hidden !important;

      .texture1 {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .texture2 {
        position: absolute;
        top: 0;
        right: 0%;
        height: 100%;
      }
    }
  }

  .piloto-rnbp-content {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);
    background-color: var(--surface-color);
  }

  .main-description {
    font-size: 1em;

    @media screen and (min-width: 990px) {
      font-size: 1.3em;
    }
    @media screen and (min-width: 1300px) {
      font-size: 1.5em;
    }
    @media screen and (min-width: 1500px) {
      font-size: 1.7em;
    }
  }

  .co-map {
    .cls-1 {
      fill: #7ad8e2;
    }

    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9 {
      stroke-miterlimit: 10;
      transition: all .3s;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }

    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5,
    .cls-7,
    .cls-8 {
      stroke: #fff;
      stroke-width: 3px;
    }

    .cls-2 {
      fill: #23868e;
    }

    .cls-3 {
      fill: #2288bc;
    }

    .cls-4 {
      fill: #134863;
    }

    .cls-5 {
      fill: #13a8ba;
    }

    .cls-6 {
      fill: #eee;
      stroke: #000;
    }

    .cls-6,
    .cls-9 {
      stroke-width: .81px;
    }

    .cls-7 {
      fill: #126087;
    }

    .cls-8 {
      fill: #4ac1dd;
    }

    .cls-9 {
      fill: #30b390;
      stroke: #eee;
    }
  }

  .region-label {
    z-index: 2;
    position: relative;

    &.current-region {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 150%;
        left: 50%;
        width: 30px;
        height: 30px;
        background-color: inherit;
        transform: rotate(45deg) translate(-150%, -50%);
        z-index: -1;
      }
    }
  }

  .list {

    &::-webkit-scrollbar {
      width: 10px;
      background-color: var(--surface-color);
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #CCCCCC;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background: #289CD8;
    }
  }


  .tutorial{
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.25);
    transition: transform .3s;
    &:hover{
      transform: scale(0.97);
    }
  }

  .faq{
    h2, p, li{
      color: var(--onbackground-primary);
    }
    a{
      font-weight: 700;
      color: var(--secondary-inverse);
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
