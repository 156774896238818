/*=====================================================
    COMPONENTS
    En este archivo se importan los estilos de esta
    carpeta para luego ser llamado en styles.scss
 ====================================================== */

@import "./barra-conexion.scss";
@import "./sin-conexion-popup.scss";
@import "./carruseles.scss";
@import "./spinners.scss";
@import "./configAccesibilidad.scss";
@import "./notificaciones.scss";
@import "./dropdown.scss";
@import "./calendario.scss";
@import "./card-login.scss";
@import "./mi-cuenta-menu";
@import "./splash-screen.scss";
@import "./book.scss";
@import "./input.scss";
@import "./multi-action-bar.scss";
@import "./accordion.scss";
@import "./popup-demo.scss";
@import "./no-results.scss";
@import "./advanced-search.scss";
@import "./switch-button.scss";
@import "./titles.scss";
@import "./modal.scss";
@import "./tour-tooltip.scss";
@import "./select.scss";
@import "./mobile-app-popup.scss";
@import "./static-badge.scss";
@import "./prominent-clients.scss";
@import "./community.scss";
@import "./notifications.scss";
@import "./updating-status.scss";
@import "./copy-box.scss";
@import "./language.scss";

.action-button{
  border-radius: 16px;
  box-shadow: 0px 0px 4.69px 0px rgba(0,0,0,0.75);
  padding: 1rem;
  cursor: pointer;
  &:focus{
    box-shadow: none;
  }
}

