/*=====================================================
    NO-RESULTS
    Este archivo define los estilos del componente
    de no hay resultados. Este aparece cuando no 
    hay resultados de una busqueda, o no se 
    encontró algún recurso.
 ====================================================== */

@use "sass:map";

.no-results{
    padding: 2rem;
     .title-no-results{
        font-size: 3em;
    }
     .img-no-results{
        max-width: 400px;
    }
}

@media screen and (max-width:map.get($grid-breakpoints, "md")) {
    .no-results{

         .title-no-results{
            font-size: 2em;
        }
         .img-no-results{
            min-width: 90%!important;
        }
    }
    
}
