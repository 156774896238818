@import "./institutional-search.scss";


@keyframes page-content-anim {
    0%{
        transform: translateY(15%);
        opacity: 0;
    }
    100%{
        transform: translateY(0%);
        opacity: 1;
    }
}



.institutional-auth {
    background-color: var(--secondary-color);
    min-height: 100vh;
    position: relative;
    z-index: 0;
    padding: 1rem;

    .not-found-option-card{
        border-radius: 1rem;
        background-color: var(--background-color);
        color: var(--onbackground-third)!important;
        .img-wrapper{
            width: 70px;
            height: 70px;
            $themes: (
                "alto-contraste":(
                    background-color:var(--primary-inverse)
                )
            );
        @include ThemesProperties($themes);
        }
        .description{
            font-weight: 500;
        }
    }

    .page-content{
        animation: page-content-anim .5s, backwards;
    }

    .error-alert{
        border-radius: .5rem;
        background-color: var(--onred-inverse);
        color: var(--red-inverse);
        padding: .5rem;
        text-align: center;
        font-weight: 700;    
    }


    .text-field {
        padding: .25rem;
        border-radius: .5rem;
    }

    .form-field-error {
        color: var(--primary-inverse);
        font-weight: 700;
    }

    .inst-auth-avatar {
        width: 7rem;
        height: 7rem;
    }

    // Clases para el componente 'SelectedInstitution' el cual se usa cuando se selecciona una institución al momento de autenticarse en modo institucional.
    .selected-institution {
        border-radius: .7rem;
        border: solid 2px var(--ongreen-color);
        background-color: var(--green-color);
        color: var(--ongreen-color);
        width: 100%;
        max-width: 600px;
        text-align: center;

        .name {
            font-family: var(--secondary-font);
            font-weight: bolder;
        }

        .location {
            font-weight: 600;
            font-style: italic;
        }

        .remove-button {
            font-weight: bolder;
            font-size: 2em;
            background-color: transparent;
            color: var(--ongreen-color);
        }
    }

    .bg-shape {
        background-image: url('/img/institutional-auth/background-shape.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-position: 50% 50%;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .onsecondary-title {
        font-weight: 700;
        font-family: var(--secondary-font);
        color: var(--onsecondary-color);
        text-align: center;
        font-size: 2rem;
    }

    .onsecondary-title2 {
        font-weight: 500;
        font-family: var(--secondary-font);
        color: var(--onsecondary-color);
        text-align: center;
        font-size: 1.3rem;
    }

    @mixin baseButton($background, $color, $activeBackground, $activeColor ) {
        border-radius: 20px;
        padding: 15px 30px;
        font-family: var(--primary-font);
        font-weight: 700;
        text-align: center;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background: $background;
        color: $color;
        transition: color .3s, background-color .3s;

        @media screen and (max-width: 500px) {
            font-size: .8em;
        }

        $themes: (
            "clasico":(border: none),
            "alto-contraste":(border: 1px solid var(--onbackground-color))
        );
    @include ThemesProperties($themes);

    &:active,
    &:hover {
        background: $activeBackground;
        color: $activeColor;
        box-shadow: none !important;
    }

    &:disabled {
        box-shadow: none !important;
        background: var(--ondisabled-color);
        color: var(--disabled-color);
      
    }
}


.primary-button {
    @include baseButton(var(--primary-color), var(--onprimary-color), var(--onsecondary-color), var(--secondary-color));
}

.primary-button-var2 {
    @include baseButton(var(--primary-color), var(--onprimary-color), var(--secondary-color), var(--onsecondary-color));
}
.secondary-button {
    @include baseButton(var(--secondary-color), var(--onsecondary-color), var(--primary-color), var(--onprimary-color));
}


.back-button-header {
    .back-button {
        border-radius: .5rem;
        font-weight: 700;
        transition: color .3s, background-color .3s;
        $themes: (
            "clasico":(background-color: #fff,
                color: #000),
            "alto-contraste":(background-color: var(--onsecondary-inverse),
                color: var(--secondary-inverse))
        );
    @include ThemesProperties($themes);

    &:hover {
        $themes: (
            "clasico":(background-color: var(--primary-color),
                color: var(--onprimary-color),
            ),
            "alto-contraste":(background-color: var(--secondary-inverse),
                color: var(--onsecondary-inverse))
        );
    @include ThemesProperties($themes);
}
}
}



$colors: (
    primary:( default:var(--primary-color), hover: var(--secondary-color)),
    //morado
    secondary: ( default:var(--secondary-color), hover: var(--primary-color)),

    secondary-var2: ( default:var(--secondary-inverse), hover: var(--primary-inverse)),
    //amarillo
    onsecondary: ( default:var(--onsecondary-color), hover: var(--primary-inverse)),
    //blanco
);

//Genera las clases de los enlaces con base en los colores
//Modo de empleo: <a className = "secondary-link"> ... </a>
@each $color, $color-value in $colors {
    .#{$color}-link {
        color: map-get($color-value, 'default');
        font-family: var(--primary-font);
        text-decoration: underline;
        font-size: 1em;
        cursor: pointer;
        transition: color .3s;

        &:active,
        &:hover {
            color: map-get($color-value, 'hover');
        }
    }
}
}