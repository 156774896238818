/*=====================================================
                      LOGIN
 ====================================================== */

.alert_container {
  max-width: 870px !important;
}

//LOGINS
.logins {
  .section-title {
    font-size: 2em;
    color: var(--onsecondary-color);
    font-weight: 600;
    text-align: center;
  }

  .secondary-item {
    font-weight: 600;
    font-size: 1em;
    color: var(--secondary-inverse);
  }

  @mixin plan($width, $height, $border, $widthP, $bottomP, $leftP, $fontP) {
    width: $width !important;
    height: $height !important;
    border: $border solid var(--onsecondary-color);
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-position: 50% 174%;
    box-shadow: 0px 0px 5px 1px #0009;
    position: relative;
    cursor: pointer;
    background-color: #fff;

    p {
      position: absolute;
      border-radius: 5px;
      background: var(--primary-inverse);
      color: var(--onprimary-inverse);
      font-weight: 600;
      width: $widthP !important;
      bottom: $bottomP !important;
      left: $leftP !important;
      font-size: $fontP !important;
    }
  }

  .img-plan {
    @include plan(150px, 150px, 3px, 100%, -15px, 0, 18px);
  }

  .btn-iniciar-sesion {
    font-size: 1em !important;
  }

  .otro-inicio {
    width: 110px;
    height: 110px;
    transition: all 0.2s ease-in;
    &:hover {
      transform: scale(1.03);
    }

    p {
      font-size: 18px;
      position: inherit;
      bottom: -80px;
    }
  }
}

.biblioteca {
  background-color: var(--red-color) !important;
  background-image: url("/img/icons/login-bibliotecas.png");
}

.colegios {
  background-color: var(--third-color) !important;
  background-image: url("/img/icons/login-colegios.png");
}

.academia {
  background-color: var(--green-color) !important;
  background-image: url("/img/icons/login-academia.png");
}

/*=====================================================
                NUEVO LOGIN 03/2024
====================================================== */
.login{
  background: var(--secondary-color);
  /* background-image: url('/img/login/books-back.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--secondary-color);
  background-attachment: fixed; */

  .img-login{
    width: 100%;
    position: absolute;
    overflow: hidden;

    @media screen and (min-width: 992px) {
      height: 100%;

      img {
        height: 100%;
      }
    }
  }

  .wrapper-login{
    width: 100%;
    position: relative;
    background: var(--secondary-color);
    z-index: 1000;

    @keyframes page-content-anim {
      0%{
          transform: translateY(15%);
          opacity: 0;
      }
      100%{
          transform: translateY(0%);
          opacity: 1;
      }
    }

    .content-login{
      width: 90%;
      max-width: 500px;
      margin: auto;
      padding-top: 50px;
      animation: page-content-anim .5s, backwards;

      h1{
        font-size: 2em;
        font-weight: 600;
        text-align: center;
      }

      .btn-institutional{
        background: var(--primary-inverse);
        border-radius: 10px;
        color: var(--onprimary-inverse);

        img{ 
          max-width: 55px;
        }

        &:hover{
          background: var(--background-color);
          color: var(--secondary-inverse);
        }

        $themes:(
          "clasico":(
            border: none
          ),
          "alto-contraste":(
            border: 1px solid var(--onprimary-color)
          )
        );      
        @include ThemesProperties($themes);

        .icon{
          $themes: (
            "alto-contraste":(
              background-color:var(--secondary-inverse),
            )
          );
          @include ThemesProperties($themes);
        }

      }
    }

    .line-o{
      width: 100%;
      height: 2px;
      background: var(--onsecondary-color);
    }

    .btn-iniciar-sesion{
      background: var(--primary-inverse);
      border-radius: 5px;
      color: var(--onprimary-inverse);
      font-weight: 600;

      &:hover{
        background: var(--background-color);
        color: var(--secondary-inverse);
      }

      $themes:(
        "clasico":(
          border: none
        ),
        "alto-contraste":(
          border: 1px solid var(--onprimary-color)
        )
      );      
      @include ThemesProperties($themes);
    }
  }

  .login-academia{
    width: 90%;
    max-width: 500px;
    margin: auto;
    
    p{color: var(--onsecondary-color);}

    a{
      color: var(--primary-inverse);
      text-decoration: underline;
      font-weight: 600;
    }

   

    .avatar{
      width: 80px;
      height: 80px;
      border: 3px solid var(--onsecondary-color);
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 50%;
      background-position: 50% 174%;
      box-shadow: 0px 0px 5px 1px #0009;
      background-color: var(--green-color) !important;
      background-image: url("/img/icons/login-academia.png");
    }
   
  }


  @media only screen and (min-width: 992px) {
    .wrapper-login{
      min-height: 100vh;
      background: linear-gradient(90deg, rgba(102,102,204,0) 0%, var(--secondary-color) 20%,  var(--secondary-color) 50%,  var(--secondary-color) 80%, rgba(102,102,204,0) 100%); 

      .content-login{
        width: 50%;
        max-width: 600px;
        padding-top: 100px;
      }
    }

    .login-academia{
      width: 350px;
      margin: 0;
      position: sticky;
      top: 87%;
      left: 75%;
      border-radius: 20px 20px 0 0;

      p, a{
        font-size: .8em;
      }

      $themes:(
        "clasico":(
          background-color: #262626
        ),
        "alto-contraste":(
          background-color: var(--onprimary-inverse)
        )
      );      
      @include ThemesProperties($themes);
    }
  }

  @media only screen and (min-width: 1200px) {
    .wrapper-login{
      .content-login{
        width: 40% !important;
      }
    }
  }


  @media only screen and (min-width: 1500px) {
    .wrapper-login{
      .content-login{
        width: 30% !important;
      }
    }
  }
}

