/*=====================================================
        POLITICA DE PRIVACIDAD Y SOPORTE
 ====================================================== */
.subseccion-politica {
  text-decoration: underline;
  color: var(--onbackground-third)
}
.lista-politica {
  ul {
    list-style: unset;
    color: var(--onbackground-third)
  }
  ol {
    list-style: lower-alpha;
    color: var(--onbackground-third)
  }
}

.politica-soporte{
  p{
    margin-bottom: 1.5rem;
  }
  .lista-disc{
    list-style: disc;
    color: var(--onbackground-third)
  }
  tr{
    td:first-child{
      font-weight: 700;
    }
  }
}