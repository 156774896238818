.__floater, .__floater__open{
  max-width: 90% !important;
  width: 600px !important;
}

.beacons{
  position: relative;
  padding: 10px;
  background: var(--surface-color);
  border-radius: 15px;
  text-align: center;

  $themes: (
    "clasico":(
      border: none,
    ),
    "alto-contraste":(
      border: 1px solid var(--onsecondary-color),
    )
  );

  @include ThemesProperties($themes);


}

@mixin titleH2($fontSize: 18px) {
  font-family: var(--secondary-font) !important;
  color: var(--secondary-inverse) !important;
  font-size: $fontSize;
}

.title-beacons{
  @include titleH2(1.5em);
}

.content-beacons{
  h2{
    @include titleH2(40px);
  }
  p{
    font-family: var(--secondary-font) !important;
    color: var(--onbackground-third) !important;
    font-size: 1.2em;
    font-weight: 600;
    margin: 0 10px;
  }
}

.btn-start{
  border-radius: 10px;
  margin: 30px 0 20px;
  font-size: 1.2em;
  padding: 5px 30px;
  font-weight: 700;
  font-family: var(--secondary-font);
  transition: 0.2s;
  line-height: 1.2;
  background: var(--primary-inverse);
  color: var(--onprimary-inverse);
  @extend .noutline;

  $themes: (
    "clasico":(
      border: none,
    ),
    "alto-contraste":(
      border: 1px solid var(--onsecondary-color),
    )
  );

  @include ThemesProperties($themes);

  &:hover {
    background-color: var(--secondary-color);
    color: var(--onsecondary-color);
    transform: scale(1.01);
    @extend .noutline;
  }

  &:focus {
    @extend .noutline;
    background: var(--secondary-color) !important;
    color: var(--onsecondary-color);
  }

  &:active {
    background: var(--primary-color) !important;
    transform: scale(0.95);
    @extend .noutline;
  }
}