/*=====================================================
    REDIMIR 
    En este archivo se establecen estilos para 
    diferentes formularios, como por ejemplo demo, 
    información de institución y redimir código.
 ====================================================== */

.mm-redimir_steps {
  list-style: none;
  font-size: 0.85em;
  position: relative;
  flex-direction: column !important;
  align-items: flex-start !important;
  li {
    .cont-paso{
      flex-grow: 1;
      position: relative;
      z-index: 1;
      span {
        color: var(--surface-color);
        background-color: var(--onbackground-fourth);
        border-radius: 50%;
        display: inline-block;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
      }
      .step-active {
        background-color: var(--secondary-inverse) !important;
  
      }
    }
    
  }
  hr {
    flex-grow: 3;
    border-style: solid;

    $themes:(
      "clasico":(
        border-color: var(--background-color)
      ),
      "alto-contraste":(
        border-color: var(--onbackground-primary)
      )
    );

    @include ThemesProperties($themes);
  }
}

.mm-redimir-terms {
  font-size: 0.9em;
  $themes:(
    "clasico":(
      color: #9b9b9b,
    ),
    "alto-contraste":(
      color: var(--onbackground-primary),
    )
  );
  @include ThemesProperties($themes);

  .links-tyc {
    $themes:(
      "clasico":(
        color: var(--secondary-inverse)
      ),
      "alto-contraste":(
        color: var(--onbackground-primary),
      )
    );

    @include ThemesProperties($themes);
  }

  
}

.mm-redimir_title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7em;
  line-height: 1.3;
  span {
    background-color: var(--secondary-inverse);
    color: var(--surface-color);
    border-radius: 50%;
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    height: 50px;
    line-height: 50px;
    margin-right: 10px;
  }
}

.mm-redimir_data {
  background-color: var(--background-color);

  $themes:(
    "clasico":(
      border: none,
    ),
    "alto-contraste":(
      border: 1px solid var(--onbackground-fourth),
    )
  );

  @include ThemesProperties($themes);

}

.mm-redimir_questionhover {
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  font-weight: 300 !important;
  position: absolute;
  right: -85%;
  top: -50%;
  width: 80%;
  opacity: 0;
  transition: opacity 0.2s ease;
  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent rgba(0, 0, 0, 0.85) transparent transparent;
  }
  p {
    display: none;
  }
}

.mm-redimir_questioncodeactivate {
  .tooltiptext {
    padding: 10px;
    width: 200px;
  }
  &:hover {
    ~ .mm-redimir_questionhover {
      opacity: 1;
      p {
        display: block;
      }
    }
  }

  &:focus-visible{
    outline: none;
    .tooltip .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}

.already-mm {
  .tooltiptext {
    left: -124px;
    bottom: 40px;
    width: 450px;
  }
}

.entercode-mm {
  .tooltiptext {
    left: -148px;
    bottom: 40px;
    width: 450px;
  }
}

.username-mm {
  .tooltiptext {
    left: 110px;
    bottom: 80px;
    width: 350px;
  }
}

.school-mm {
  .tooltiptext {
    left: 107px;
    bottom: 80px;
    width: 350px;
  }
}

.mm-redimir {
  .form-check-input {
    margin-top: 0.5rem;
  }
  // .main-content {
  //   padding-bottom: 10rem !important;
  // }
}

@media only screen and (min-width: 768px) {
  .mm-redimir_steps {
    flex-direction: row !important;
    /* align-items: center !important; */
  }
}

/* @media only screen and (min-height: 800px) {
  .main-content {
    min-height: 60vh !important;
  }
}
 */
