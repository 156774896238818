/*===========================================================================================
  Header
  En este archivo se definen los estilos del encabezado o header del sitio.
 ============================================================================================ */

.skip-link {
  height: 0;
  display: block;
  color: var(--surface-color);

  &:focus {
    cursor: pointer;
    font-family: var(--primary-font);
    background: var(--secondary-color);
    height: auto;
    text-align: center;
    color: var(--onsecondary-color);

    $themes: (
      "alto-contraste":(
        border: 1px solid var(--onsecondary-color)
      )
    );
    @include ThemesProperties($themes)
  }
}


@mixin backgroundHover {
  $themes: (
    "clasico":(
      background-color: #262626
    ),
    "alto-contraste":(
      background-color: var(--background-color)
    )
  );
  @include ThemesProperties($themes);
}

@mixin backgroundMenuResponsive{
  $themes: (
    "clasico":(
      background-color: #E3E3F9
    ),
    "alto-contraste":(
      background-color: var(--background-color)
    )
  );
  @include ThemesProperties($themes);
}

header {
  box-shadow: 0 1px 15px #9f9f9f;
  z-index: 4000;
  position: sticky;
  top: 0;
  width: 100%;

  .option-nav {
    height: 100%;

    li {
      display: flex;
      align-items: center;

      a,
      button {
        padding: 0 3px;
        font-weight: 600;
        font-size: 0.88em;
        font-family: var(--primary-font);
      }

      a {
        color: var(--onsecondary-color);
        cursor: pointer;

        &:hover {
          color: var(--primary-inverse);
        }
      }

      &.activeMode {
        border-bottom: 4px solid var(--primary-inverse);

        a {
          color: var(--primary-inverse) !important;
        }

        button {
          color: var(--primary-inverse) !important;
        }
      }

      &.btn-setting,
      &.btn-notification {
        button {
          color: var(--onsecondary-color);
        }

        &.activeMode,
        &:hover {
          @include backgroundHover;

          button {
            color: var(--primary-inverse) !important;
          }
        }
      }

      .btn-demo {
        padding: 8px 15px;
        border: 1px solid var(--onsecondary-color);
        border-radius: 5px;
        transition: all .2s ease-in-out;

        &:hover,
        &.activeMode {
          border: none !important;
          background-color: var(--primary-inverse);
          color: var(--onprimary-inverse);
          font-weight: 600;
        }
      }

      .dropdownMenu {
        position: absolute;
        top: 91%;
        border-top: 4px solid var(--primary-inverse);

        @include backgroundHover;

        -webkit-transition: max-height .3s, padding .3s, visibility .3s;
        -o-transition: max-height .3s, padding .3s, visibility .3s;
        transition: max-height .3s, padding .3s, visibility .3s;


        &.activeMode {
          max-height: 400px;
          visibility: visible;
        }

        &.inactiveMode {
          max-height: 0px;
          visibility: hidden;
        }

        li {
          margin: 0;
          padding: 10px;    
          font-size: 0.88em;      
          text-align: center;
          border-bottom: 1px solid var(--onsecondary-color);
          
          &:last-child {
            border-bottom: none !important;
          }

          &.activeMode {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            transition: .3s ease;
            
          }

          &.inactiveMode {
            opacity: 0;
            padding: 0;
            visibility: hidden;
            transform: translateY(-20px);
            transition: .3s ease;
          }

          a {
            width: 100%;
            color: var(--onsecondary-color) !important;
            &:hover {
              color: var(--primary-inverse) !important;
            }
          }
        }
      }

      &.btn-comunidad, &.btn-buscar {
        button {
          padding: 8px 42px;
          border: 1px solid var(--onsecondary-color);
          border-radius: 5px;
          transition: all .2s ease-in-out;
          color: var(--onsecondary-color);
    
          &:hover,
          &.activeMode {
            background-color: var(--primary-inverse);
            color: var(--onprimary-inverse);
            font-weight: 600;
          }
        }
      }

      &.btn-comunidad-general, &btn-buscar-general {
        button {
          padding: 8px 42px;
          border: 1px solid var(--primary-inverse);
          border-radius: 5px;
          transition: all .2s ease-in-out;
    
          &:hover,
          &.activeMode {
            border: none !important;
            font-weight: 600;
          }
        }
      }
            
    }
    
  }

 /* .btn-buscar {
    margin-top: 2px;
    button {
      padding: 8px 15px;
      border: 1px solid var(--onsecondary-color);
      border-radius: 5px;
      transition: all .2s ease-in-out;
      color: var(--onsecondary-color);

      &:hover,
      &.activeMode {
        background-color: var(--primary-inverse);
        color: var(--onprimary-inverse);
        font-weight: 600;
      }
    }
  }

  .btn-buscar-general-home {
    margin-top: 2px;
    button {
      padding: 8px 15px;
      border: 1px solid;
      border-radius: 5px;
      transition: all .2s ease-in-out;

      &:hover,
      &.activeMode {
        font-weight: 600;
      }
    }
  }*/

  .logo {
    img {
      display: block;
      height: 43px;
      margin: auto;
    }
  }

  .icono-home {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      @include backgroundHover;
    }

    &.activeMode {
      @include backgroundHover;
      border-bottom: 4px solid var(--primary-inverse);
    }
  }

  .icono-general-home {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.activeMode {
      border-bottom: 4px solid;
    }
  }

  .btn-menu {
    font-weight: 600;
    border: none;
    background: none;
    color: var(--onsecondary-color);
    height: 100%;
    width: 110px; //132px
    display: flex;
    justify-content: center;
    align-items: center;

    i{
      margin-top: 6px;
    }

    &.activeMode{
      @include backgroundMenuResponsive;
      color: var(--onprimary-color);
    }
  }

  .btn-cuenta {
    font-family: var(--primary-font);
    height: 100%;
    font-weight: 600;
    transition: all 0.5s;
    position: relative;
    display: flex;
    align-items: center;

    $themes: (
      "clasico":(
        background-color: #DCDDED
      ),
      "alto-contraste":(
        background-color: var(--onprimary-color)
      )
    );
    @include ThemesProperties($themes);

    i {
      font-size: 1em;
    }

    @mixin modoActivo {
      color: var(--primary-inverse) !important;
      border: 1px solid var(--primary-inverse);
      $themes: (
        "clasico":(
          background-color: #262626
        ),
        "alto-contraste":(
          background-color: var(--secondary-color)
        )
      );
      @include ThemesProperties($themes);
    }
  
    .btn-avatar {
      width: 80px;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
    }

    @media screen and (min-width: 526px) {
      padding: 0 20px 0 0 !important;
    }

    @media screen and (min-width: 992px) {
      height: 40px;
      border-radius: 5px;
      .btn-avatar {
        height: 40px;
      }

      &.active-cuenta {
        @include modoActivo();
        >.arrow {
          transform: rotate(180deg);
        }
      }

      &:hover {
        @include modoActivo();
      }
    }
  }

  .btn-cuenta-general {
    font-family: var(--primary-font);
    height: 100%;
    font-weight: 600;
   
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 5px;

    i {
      font-size: 1em;
    }

    @mixin modoActivo {
      color: var(--onprimary-inverse) !important;
      border: 1px solid var(--onprimary-inverse) !important;
      background: var(--primary-inverse);
    }
  
    .btn-avatar {
      width: 80px;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
    }

    @media screen and (min-width: 526px) {
      padding: 0 20px 0 0 !important;
    }

    @media screen and (min-width: 992px) {
      height: 40px;
      border-radius: 5px;
      .btn-avatar {
        height: 40px;
      }

      &.active-cuenta {
        @include modoActivo();
        
      }

      .active-arrow {
        transition: all 0.5s;
        transform: rotate(180deg);
      }

      &:hover {
        @include modoActivo();
      }
    }
  }

  .dropdownMenuResponsive{
    position: absolute;
    padding-bottom: 20px;
    top: 100%;
    width: 100%;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);

    $themes: (
      "alto-contraste":(
        border-top: 1px solid var(--onprimary-color),
        border-bottom: 1px solid var(--onprimary-color),
        box-shadow: 0 1px 15px #9f9f9f
      )
    );
    @include ThemesProperties($themes);


    @include backgroundMenuResponsive;

    -webkit-transition: max-height .3s, padding .3s, visibility .3s;
    -o-transition: max-height .3s, padding .3s, visibility .3s;
    transition: max-height .3s, padding .3s, visibility .3s;

    &.activeMode {
      max-height: 400px;
      visibility: visible;
    }

    &.inactiveMode {
      max-height: 0px;
      visibility: hidden;
    }

    hr{
      width: 90%;
      margin: auto;
      border: none !important;
      height: 1px;
      background: var(--onsecondary-color);

      &.activeMode {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: .2s all ease;
        
      }

      &.inactiveMode {
        opacity: 0;
        padding: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: .2s all ease;
      }
    }

    li{
      padding: 15px 10px;    
     
      @mixin opcionesMenuResponsive{
        font-size: .9em;      
        text-align: center;
        color: var(--onprimary-color);
        &:hover{
          color: var(--onprimary-color);
        }
      }

      .link-principal{
        font-weight: 700;
        @include opcionesMenuResponsive;
      }

      .link-secundario{
        @include opcionesMenuResponsive;
        margin: auto;
        display: block;
      }   
      
      &.activeMode {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: .2s all ease;
        
      }

      &.inactiveMode {
        opacity: 0;
        padding: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition:  .2s all ease;
      }
    }
  }
  
}

.public-header{
  height: 100%;
  .dropdownMenu {
    width: 160px;
    right: -100%;

    &.dropDownComunidad{
      right: -35% !important;
    }
  }

 
}

.general-header {
  height: 100%;
  .dropdownMenu {
    width: 100%;
    right: 0;
  }
}