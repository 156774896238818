/*=====================================================
    NOTIFICACIONES
    Este archivo ajusta el estilo de la barra de 
    notificación de estado de configuración de PWA.
 ====================================================== */

@use "sass:map";

.notificacion-config-pwa {
    $themes:(
        "configurando":(
            "clasico":(
                background-color: var(--secondary-inverse),
                color: var(--onsecondary-color)
            ),
            "alto-contraste":(
                background-color: var(--secondary-inverse),
                color: var(--background)
            )
        ),
        "configurado":(
            "clasico":(
                background-color: rgb(0, 154, 0),
                color: #fff
            ),
            "alto-contraste":(
                background-color: var(--secondary-inverse),
                color: var(--background)
            )
        ),
        "config-fallida":(
            "clasico":(
                background-color: #f00,
                color: #fff
            ),
            "alto-contraste":(
                background-color: var(--secondary-inverse),
                color: var(--background)
            )
        )
    );
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: 0;
    right: 0;
    z-index: 800;
    transition: opacity 0.3s, background-color 0.3s;
    transform-origin: 0% 100%;
    @include ThemesProperties(map.get($themes, "configurando"));
    &.configurando {
        @include ThemesProperties(map.get($themes, "configurando"));
        > .configurado {
            display: none;
        }
        > .configuracion-fallida {
            display: none;
        }
    }
    &.configurado {
        @include ThemesProperties(map.get($themes, "configurado"));
        > .configurando {
            display: none;
        }
        > .configuracion-fallida {
            display: none;
        }
    }
    &.configuracion-fallida {
        @include ThemesProperties(map.get($themes, "config-fallida"));
        > .configurando {
            display: none;
        }
        > .configurado {
            display: none;
        }
    }

    &.visible {
        pointer-events: all;
        animation: show-config-state 0.5s ease-in-out both;
        animation-delay: 1.5s;
    }

    &.hidden {
        pointer-events: none;
        animation: auto-hide 0.5s ease-in-out both;
    }

    .configurando,
    .configurado,
    .configuracion-fallida {
        display: grid;
        grid-template-columns: min-content 1fr;
        font-weight: bold;
        align-items: center;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        .icon {
            width: 30;
            margin-right: 0.5rem;
        }
        p {
            justify-self: start;
            font-size: 1rem;
        }
    }
}

@keyframes auto-hide {
    0% {
        opacity: 1;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform: scaleY(0);
    }
}

@keyframes show-config-state {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}
