/*=====================================================
    CARD-LOGIN
    Define los estilos de la tarjeta de iniciar 
    sesión del header.
 ====================================================== */

.wrapper-card-login{
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.342);
  display: flex;
  position: fixed;
  top: 55px;
  justify-content: flex-end;

  .link-inst{
    text-decoration: underline;
  }

  .card-login{
    width: 430px;
    background-color: var(--background-color);
    border-radius: 0 0 0 10px;
    transform-origin: 50% 0;

    &.visible{
      max-height: 100%;
      visibility: visible;
      pointer-events: all;
      display: block;
      overflow-y: scroll;
    }

    &.hidden{
      max-height: 0px;
      visibility: hidden;
      pointer-events: none;
      display: none;
    }

    p{
      color: var(--onsecondary-color);
      font-family: var(--primary-font);
      font-weight: 600;
      font-size: 0.9em !important;
    }

    .form-login{
      background-color: var(--secondary-color);
      color: var(--onsecondary-color);
      font-family: var(--primary-font);
      font-weight: 600;
      font-size: 0.9em !important;

      label{
        display: block;
        margin-bottom: 20px;
      }
    }

    .olvide-contrasena{
      font-size: 0.85em;
      text-decoration: underline;

      &:hover{
        color: var(--primary-inverse);
      }
    }

    .btn-iniciar-sesion{
      background-color: var(--primary-inverse);
      color: var(--onprimary-inverse);
      font-family: var(--primary-font);
      font-weight: 600;
      padding: 5px 20px;
      border-radius: 6px;

      &:hover{
        background-color: var(--red-color);
        color: var(--onred-color);

        $themes:(
          "alto-contraste":(
            border: 1px solid var(--onred-color)
          )
        );
        @include ThemesProperties($themes);
      }
    }

    .register-login{
      background-color: var(--surface-color);
      color: var(--secondary-inverse);
      font-family: var(--primary-font);
      font-size: 0.9em;
      font-weight: 600;
      $themes:(
        "alto-contraste":(
          border-bottom: 1px solid var(--onprimary-color)
        )
      );
      @include ThemesProperties($themes);

      .btn-register{
        background-color: var(--secondary-inverse);
        color: var(--onsecondary-inverse);
        padding: 5px;
        border-radius: 6px;
        display: block;
        text-align: center;

        &:hover{
          background-color: var(--primary-color);
          color: var(--onprimary-color);
    
          $themes:(
            "alto-contraste":(
              border: 1px solid var(--onprimary-color)
            )
          );
          @include ThemesProperties($themes);
        }
      }
    }

    .other-logins{
      .title-other{
        font-family: var(--primary-font);
        color: var(--secondary-inverse);
        font-weight: 600;
        font-size: 0.9em;
        text-align: center;
      }

      .otro-inicio {
        width: 110px;
        height: 110px;
        transition: all 0.2s ease-in;
        &:hover {
          transform: scale(1.03);
        }
    
        p {
          font-size: 18px;
          position: inherit;
          bottom: -80px;
        }
      }
      .img-plan {
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-position: 50% 174%;
        border: 4px solid var(--onsecondary-color);
        box-shadow: 0px 0px 5px 1px #0009;
        position: relative;
        cursor: pointer;
        background-color: #fff;
    
        p {
          position: absolute;
          border-radius: 5px;
          background: var(--primary-inverse);
          color: var(--onprimary-inverse);
          font-weight: 600;
          width: 100%;
          bottom: -15px;
          width: 100%;
          font-size: 18px;
        }
      }
    }

    @media screen and (max-width:992px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 0;

      &.visible{
        max-height: 100vh;
        visibility: visible;
        pointer-events: all;
        display: block;
      }

      .close-button{
          display: block;
          color: var(--onsecondary-color);
          &:hover{
            color: var(--primary-inverse);
          }
      }
    }
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(-15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.wrapper-card-login[aria-hidden="false"]{
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.wrapper-card-login[aria-hidden="true"]{
  animation: mmfadeOut 0.4s cubic-bezier(0, 0, 0.2, 1);
}

.wrapper-card-login[aria-hidden="false"] > .card-login{
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.wrapper-card-login[aria-hidden="true"] > .card-login{
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}