/*=====================================================
    TIENDA-CURSOS

    En este archivo se definen los estilos del
    contenedor y tarjetas de cursos en 'Quiero 
    aprender algo nuevo'
 ====================================================== */


 .cards-container {
  width: 100%;
  margin: auto;
}

.academy-card {
  background: var(--surface-color);
  border: 3px solid;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  padding: 6px;
  margin-bottom: 40px;

  $themes : (
    "clasico": (border-color: #9772AE),
    "alto-contraste": (border-color: var(--onprimary-color))
  );
  @include ThemesProperties($themes);

  .column-left {

    border-radius: 20px;

    .img-course {
      border-radius: 20px;
      height: 177px;
      background-repeat: no-repeat;
      background-size: cover;
    }


    .card-description {
      padding: 30px 20px 20px;
      color: var(--onprimary-inverse);
      /* height: 100%; */

      .general-description {
        font-family: var(--secondary-font);
        font-weight: 700;
        text-transform: uppercase;
        font-size: .6em;
      }

      h4 {
        font-family: var(--secondary-font);
        font-size: 1.5em;
        line-height: 1;
        margin: 10px 0;
        width: 90%;
      }

      .creator {
        font-family: var(--primary-font);
        font-weight: 500;
        font-size: .8em;

        span {
          font-family: var(--secondary-font);
          font-size: 1em;
          font-weight: 700;
          $themes: ("clasico": (color: #EA3D4D),
            "alto-contraste" : (var(--onprimary-inverse)));
          @include ThemesProperties($themes);
        }
      }
    }
  }

  @mixin cardColor($color: DarkGray) {
    $themes : (
      "clasico": (background: $color),
      "alto-contraste": (background: var(--onprimary-color))
    );
    @include ThemesProperties($themes);
  }

  .card-blue {@include cardColor($color: #E4FFF8);}
  .card-pink {@include cardColor($color: #FFEEF6);}
  .card-green {@include cardColor($color: #F1FFDA);}

  .features-list {
    list-style: disc;
    font-size: .9em;
    padding-left: 30px;
    padding-right: 10px;

    li {
      margin: 10px 0;
      color: var(--onprimary-color);
    }
  }

  .price-description {
    font-family: var(--secondary-font);
    font-weight: 700;
    line-height: 25px;
    color: var(--onprimary-color);

    p:last-child{
      font-size: 20px;
    }

    p:nth-child(2) {
      font-size: 1.5em;
    }
  }

  .btn-trailer {
    i {
      width: 84px;
      height: 35px;
      border-radius: 10px;
      color: var(--surface-color);
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      $themes : (
        "clasico": (background: #81559D),
        "alto-contraste": (background: var(--onprimary-color))
      );
      @include ThemesProperties($themes);

    }

    p {
      font-family: var(--secondary-font);
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      text-decoration-line: underline;

      $themes : (
        "clasico": (color: #81559D),
        "alto-contraste": (color: var(--onprimary-color))
      );
      @include ThemesProperties($themes);
    }

    &:hover {
      i {
        $themes : (
          "clasico" : (background: var(--secondary-inverse)),
          "alto-contraste": (
            background: var(--secondary-color),
            border: 1px solid var(--secondary-inverse),
            color: var(--secondary-inverse)
          )
        );
        @include ThemesProperties($themes);
      }

      p {
        
        $themes : (
          "clasico" : (color: var(--secondary-color)),
          "alto-contraste": ( color: var(--secondary-inverse))
        );
        @include ThemesProperties($themes);
      }
    }
  }

  .btn-tarjeta-course {
    background: var(--primary-inverse);
    border-radius: 15px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.56);
    text-align: center;
    padding: 10px 25px;
    display: block;
    font-weight: 700;
    font-size: 1.1em;
    color: var(--onprimary-inverse);
    transition: all .5s;


    &:hover {
      background: var(--secondary-color);
      color: var(--onsecondary-color);
      box-shadow: none !important;

      $themes: ("alto-contraste":(border: 1px solid var(--onsecondary-color)));
      @include ThemesProperties($themes);
    }
  }
}

@media screen and (min-width: 768px) {
  .column-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px 0px 0px 20px !important;

    .img-course {
      border-radius: 20px 0px 0px 20px !important;
      border-top-right-radius: 0px !important;
      height: 300px;
    }
  }
  .column-left-individual {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0px 0px 0px 20px !important;

    .img-course {
      border-radius: 0px 0px 0px 20px !important;
      border-top-right-radius: 0px !important;
      height: 300px;
    }
  }
}

@media screen and (min-width: 992px) {
  .cards-container {
    width: 85%;
  }
}

