/*=====================================================
    CONFIGACCESIBILIDAD
    Este archivo define los estilos del panel de 
    accesibilidad.
 ====================================================== */

.menu-accesibilidad-flotante {
  position: fixed;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 9000 !important;
  border-top: 4px solid var(--primary-inverse);
  $themes: (
    "clasico":(
      background-color: #262626
    ),
    "alto-contraste":(
      background-color: var(--background-color)
    )
  );
  @include ThemesProperties($themes);

  &.collapsedMode {
    border: none !important;
  }
}


.menu-accesibilidad {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  width: 100%;
  color: var(--onsecondary-color);
  overflow: hidden;
  padding: 1rem 1.2rem;
  

  -webkit-transition: max-height .3s, padding .3s, visibility .3s;
  -o-transition: max-height .3s, padding .3s, visibility .3s;
  transition: max-height .3s, padding .3s, visibility .3s;

  &.expanded {
    visibility: visible;
  }

  &.collapsed {
    max-height: 0px;
    padding: 0;
    visibility: hidden;
    border: none;
  }

  &>.item {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
  }

  &>.item-languaje {
    border-bottom: 1px solid var(--onsecondary-color);
    padding-bottom: 20px;

    .language-selector {
      font-size: 14px;
    }

    .text-idioma {
      font-size: 15px;
      font-weight: 600;
      width: 100%;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &>.reset-button {
    width: 100%;
    min-width: 200px;
    height: 45px;
    padding: .25rem;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: var(--primary-inverse);
    color: var(--onprimary-inverse);
    font-weight: bold;
    border-radius: .5rem;
    margin-top: 30px;
  }

  .close-button {
    background: none;
    border: none;
    outline: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--onsecondary-color);
    line-height: 0;
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column-align: end;
    justify-self: end;


    &:hover {
      color: var(--primary-inverse);
    }
  }

  .text-fontSize {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tipo-fuente {
    color: var(--onsecondary-color);
    font-size: 14px;
    font-family: var(--secondary-font);
    font-weight: 600;

    .letra {
      width: 50px;
      height: 50px;
      background-color: var(--surface-color);
      color: var(--onprimary-color);
      font-size: 30px;
      margin-bottom: 5px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      padding-top: 1px;

      $themes: (
        "alto-contraste":(
          border: 1px solid var(--primary-inverse)
        )
      );
      @include ThemesProperties($themes);
    }

    &.activeMode {
      color: var(--primary-inverse);

      .letra {
        color: var(--onprimary-inverse);
        background-color: var(--primary-inverse);
      }
    }
  }

  @mixin colorYcontraste {
    width: 90%;
    height: 45px;
    border-radius: 8px;
    font-size: 14px;
    border: 3px solid var(--onprimary-color);
    font-weight: 600;
    font-family: var(--secondary-font);
  }

  .btn-original {
    @include colorYcontraste();
    background-color: var(--surface-color);
    color: var(--onprimary-color);
  }

  .btn-alto-contraste {
    @include colorYcontraste();
    background-color: var(--onprimary-color);
    color: var(--primary-color);
  }

  .range-slider__range_settings {
    -webkit-appearance: none;
    height: 8px;
    width: 100%;
    border-radius: 5px;
    border: solid 2px #262626;
    background: var(--background-color);
    outline: none;
    padding: 0 !important;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .range-slider__range_settings::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 0.5px solid var(--onprimary-color);
    background: var(--primary-color);
    cursor: pointer;
    -webkit-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;

    &:focus {
      outline: none !important;
    }
  }

  .range-slider__range_settings::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0.5px solid var(--onprimary-color);
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
    z-index: 2000;
    -moz-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;
  }

  .range-slider__range_settings::-webkit-slider-thumb:hover {
    background: var(--green-color);
    outline: none !important;
  }

  .range-slider__range_settings::-moz-range-thumb:hover {
    background: var(--green-color);
    outline: none !important;
  }

  .range-slider__range_settings:active::-webkit-slider-thumb {
    background: var(--green-color);
    outline: none !important;
  }

  .range-slider__range_settings:active::-moz-range-thumb {
    background: var(--green-color);
    outline: none !important;
  }

  .wa-select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
    background: var(--background-color);
    color: var(--onbackground-primary);
    border: solid 2px #262626;
    padding: 2px 5px;
    cursor: pointer;
    outline: none !important;
    font-size: 16px;

    option {
      font-size: 16px;
    }
  }
}

@media screen and (max-width:576px) {
  .menu-accesibilidad-flotante {
    width: 100%;
  }

  .menu-accesibilidad {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;

    &>.reset-button {
      -ms-grid-column-align: center;
      justify-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }

    &>.close-button {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }
  }
}

@media screen and (min-width:577px) {
  .menu-accesibilidad {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;

    &>.item-languaje {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      /* -ms-grid-columns: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr); */
      margin-top: 10px;
    }

    &>.reset-button {

      -ms-grid-column-align: center;
      justify-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }

    &>.close-button {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }
  }
}

@media screen and (min-width:768px) {
  /* .menu-accesibilidad-flotante {
    left: auto;
    right: 0;
    width: 400px;
  } */

  .menu-accesibilidad {
   /*  max-width: 600px;
    margin: auto; */
    /* -ms-grid-columns: 1fr;
    grid-template-columns: 1fr; */

   /*  &>.item-languaje {
      -ms-grid-columns: repeat(2,1fr);
      grid-template-columns: repeat(2,1fr);
    } */
    /* -ms-grid-columns: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;

    &>.item-languaje {
      grid-row-start: 1;
      grid-row-end: 3;
    } */
  }
}

@media screen and (min-width:768px) {
  .menu-accesibilidad {
    max-width: 800px;
    margin: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;

    &>.item-languaje,
    &>.item {
      /* -ms-grid-columns: repeat(2,1fr);
      grid-template-columns: repeat(2,1fr);
    } */
    -ms-grid-columns: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    border-bottom: none !important;
/* 
    &>.item-languaje {
      grid-row-start: 1;
      grid-row-end: 3;
    } */
    }
  }
}



@media screen and (min-width:1200px) {
  .menu-accesibilidad-flotante {
    left: 0;
    right: auto;
    width: 100%
  }

  .range-slider__range_settings {
    margin-top: 24px !important;
    margin-bottom: 0 !important;
  }

  .menu-accesibilidad {
    max-width: none;
   /*  -ms-grid-columns: repeat(4, 1fr);
    grid-template-columns: repeat(4, 1fr); */
    -ms-grid-columns:  1fr 1fr 1fr 60px;
    grid-template-columns:  1fr 1fr 1fr 60px;
    column-gap: 10px;
    grid-auto-rows: 28px 64px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: start;

    &>.item {
      margin: 0;
      grid-row: 2;
      display: block;
      padding-right: 10px;
      grid-row-start: 1;
      grid-row-end: 3;
      height: 100%;
      border-right: 1px solid var(--onsecondary-color);
    }

    /* &>.item-languaje {
      display: block;
      padding-right: 10px;
      padding-bottom: 0;
      grid-row-start: 1;
      grid-row-end: 3;
      
      height: 100%;
      border-right: 1px solid var(--onsecondary-color);
      margin-top: 0;

      .text-idioma {
        margin-bottom: 10px;
      }
    } */

    .tipo-fuente {
      .letra {
        width: 40px;
        height: 40px;
        font-size: 24px;
        margin: auto;
      }
    }

    &>.close-button {
      -ms-grid-row: auto;
      grid-row-start: auto;
      -ms-grid-column-align: end;
      grid-column: 5;
      grid-row: 1;
    }

    &>.reset-button {
      margin-top: 0;
      grid-column: 5;
      grid-row: 2;
    }

  }
}

@media screen and (min-width:1600px) {
  .menu-accesibilidad {
   
    -ms-grid-columns: 400px 1fr 1fr 60px;
    grid-template-columns: 400px 1fr 1fr 60px;
  }
}