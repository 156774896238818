
.notification-inbox-container{
    position: fixed;
    top: 50px;
    left: 0;
    z-index: var(--z-notifications-inbox);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s;
    overflow: hidden;

    &.visible{
        pointer-events: all;
        opacity: 1;
        .notification-inbox{
            transform: translateX(0);
        }
    }
    &.hidden{
        pointer-events: none;
        opacity: 0;
        .notification-inbox{
            transform: translateX(100%);
        }
    }

    .notification-inbox{
        position: relative;
        max-width: 400px;
        min-height: 200px;
        height: fit-content;
        max-height: calc(100% - 90px - 50px);
        overflow: auto;
        transition: transform .3s;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        box-shadow: 0 5px 10px 0 rgba(0,0,0,.5);
        $themes:(
            "clasico":(
                background-color: #dfe0ee
                ),
                "alto-contraste":(
                background-color: var(--background-color)
            )
        );
        @include ThemesProperties($themes);

        &::-webkit-scrollbar{
            width: 6px;
        }
        &::-webkit-scrollbar-track{
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb{
            background-color: var(--secondary-inverse);
        }

        .load-more-button{
            font-weight: 700;
            color: var(--secondary-inverse);
            transition: border .3s, transform .3s, color .3s, background-color .3s;
            border: solid 2px var(--secondary-inverse);
            border-radius: 9999px;
            &:hover{
                color: var(--onsecondary-inverse);
                background-color: var(--secondary-inverse);
                transform: scale(1.05);
                border: solid 2px var(--secondary-inverse);
            }
        }

        .inbox-header{
            position: sticky;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 9999;
        }
    }

    .newsletter-form{
        border: dashed 3px var(--onbackground-primary);
        border-radius: 1rem;
        $themes:(
            "clasico":(
                background-color: #F1B70C
                ),
                "alto-contraste":(
                background-color: var(--background-color)
            )
        );
        @include ThemesProperties($themes);

        .description, .form-label{
            font-weight: 600;
        }

    }
}

.notification-card{
    background-color: var(--background-color);
    color: var(--onbackground-primary);
    border-radius: 1rem;
    transition: transform .3s, box-shadow .3s;
    &:hover{
        transform: translateY(-2.5%);
        box-shadow: 0 5px 10px 0 rgba(0,0,0,.25);
    }

    &.grayscale{
        filter: grayscale(100%);
    }

    .type{
       color: var(--secondary-inverse); 
       font-weight: 700;
       text-transform: uppercase;
       letter-spacing: 2px;
       line-height: 1rem *1.5;
       font-family: var(--font-secondary);
    }

    .date{
        font-weight: 700;
        font-size: .95rem;
    }
    .title{
        font-weight: 700;
        line-height: 1rem *1.5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
    }
    .description{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
    }
    .new-notification-circle{
        $size:10px;
        width: #{$size};
        height: #{$size};
        border-radius: 99999px;
        background-color: #f00;
        animation: new-notification-anim 2s infinite ease;
    }

    @keyframes new-notification-anim {
        0%{
            transform: scale(0.7)
        }
        50%{
            transform: scale(1)
        }
        100%{
            transform: scale(0.7)
        }
    }
}

.notification-button{
    position: relative;
    .icon{

    }
    .new-notification-circle{
        position: absolute;
        top: 0;
        left:0;
        $size:10px;
        width: #{$size};
        height: #{$size};
        border-radius: 99999px;
        background-color: #f00;
        animation: new-notification-anim 2s infinite ease;
    }

    @keyframes new-notification-anim {
        0%{
            transform: scale(0.7)
        }
        50%{
            transform: scale(1)
        }
        100%{
            transform: scale(0.7)
        }
    }
}

.notification-shake{
    animation: bell-shake 4s .3s ease-in-out 1;
}

@keyframes bell-shake {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
}

.notibanner {
    transform-origin: 100% 0;
    transition: transform .3s;

    .title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
        }
    
        .description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
        }
    .img {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        height: 150px;
    }

    &.visible {
        position: relative;
        pointer-events: all;
    }

    &.hidden {
        z-index: var(--z-notibanner);
        position: fixed;
        width: 100%;
        pointer-events: none;
        animation: animNotibanner .5s ease forwards;

    }

    .main-button{
        background-color: #fff;
        font-weight: 700;
        color: #000;
        border-radius: .5rem;
        transition: transform .3s;
        &:hover{
            transform: scale(1.05);
        }
    }
    .date{
        text-transform: uppercase;

    }

    @keyframes animNotibanner {
        0% {
            translate: 0 0;
            scale: 1 1;
        }

        30% {
            translate: 0 -50px;
            scale: 1 1;
        }

        to {
            translate: 0 -50px;
            scale: 0 0;
        }
    }

    @media screen and (max-width:1300px) {
        font-size: .9rem!important;
    }

    .notibanner-hours{
        .hour-button{
            font-size: .7em;
            border-radius: 99999px;
            font-weight: 700;
            border: solid 2px;
            height: 30px;
            min-width: 30px;
            &.current{
                font-weight: 700;
            }
        }
    }
}