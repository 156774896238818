/*=====================================================
                    HOME
 ====================================================== */
.additionalIcon {
  position: absolute;
  border-radius: 0 !important;
  width: 40px !important;
  bottom: 0;
  right: 0;
  box-shadow: none !important;
}

.highlight-container {
  font-size: 1em;
  font-family: $secondary-font;
  h3 {
    color: var(--onbackground-primary);
    line-height: 1.2;
    font-weight: 700;
    font-size: 1.4em;
  }

  h2 {
    color: var(--onbackground-primary);
    line-height: 1.2;
    text-align: center;
  }
  .cover {
    transition: 0.2s ease-in;
    &:hover {
      transform: scale(1.07);
    }
    img {
      width: 100%;
      /*  max-width: 220px; */
      margin: 0 auto;
      display: block;
      border-radius: 10px;
    }

    p {
      font-weight: 700;
      line-height: 1.2;
      font-size: 0.8em;
      overflow: hidden;
      padding: 0 1.15rem;
    }
  }
  a {
    &:hover {
      color: var(--onbackground-primary);
    }
  }
}

.demo--instruction {
  background-color: var(--red-color);
  color: #fff;
  p {
    text-align: center;
    &:nth-child(1) {
      font-weight: 700;
      font-size: 1em;
      line-height: 1.4;
    }
  }
}

.demo--instruction-2 {
  background-color: var(--red-color);
  $themes:(
    "clasico":(
      color: #fff
    ),
    "alto-contraste":(
      color:var(--onbackground-primary),
      border:solid 2px var(--onbackground-primary)
    )
    );
    @include ThemesProperties($themes);

  p {
    text-align: center;
    font-weight: 700;
    font-size: 1.3em;
  }
  button {
    font-size: 1.4em;
  }
}

.demo--instruction-3 {
  background-color: var(--secondary-color);
  color: #fff;
  p {
    text-align: center;
    font-weight: 700;
    font-size: 1.3em;
  }
  button {
    font-size: 1.4em;
    background-color: var(--red-color);
    color: #fff;
    &:hover {
      background-color: var(--primary-color);
      color: var(--onbackground-third);
    }
  }
}

.mm-redimir-terms-2 {
  font-size: 0.9em;
  color: #9b9b9b;
  .links-tyc {
    color: #4a4a4a !important;
  }
}

.home--description {
  background-color: #4e45ba;
  color: #fff;
}

.teacher--description {
  background-color: var(--red-color);
  color: #fff;
}

.access--description {
  background-color: var(--secondary-color);
  color: #fff;
}

.quees-icons-container {
  .img-fluid {
    max-height: 70px;
  }
}

.quees-editoriales {
  background-color: var(--background-color);
}

.simple-container {
  $themes:(
    "clasico":(
      background-color: #f1f1ff,
    ),
    "alto-contraste":(
      background-color: var(--background-color),
      border: 1px solid var(--onbackground-primary)
    )
  );

  @include ThemesProperties($themes);
}

.avatar-container {
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .swiper-container {
    padding-bottom: 3rem !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    cursor: pointer;
    height: 44px !important;
    width: 44px !important;
    top: calc(100% - 44px);
    font-size: 2em;
    position: absolute;
    &::after {
      display: list-item;
      text-align: center;
      margin-top: 3px;
    }
  }

  .swiper-button-next {
    right: 0 !important;
  }

  .swiper-button-prev {
    left: 0 !important;
  }

  .swiper-pagination-bullet {
    color: #fff;
    font-size: 15px;
    height: 25px !important;
    padding-top: 2px;
    text-align: center;
    width: 25px !important;
  }
}

.main-covers {
  width: 100%;
}
 
.swiper-pagination-bullet-active {
  background-color: var(--secondary-color) !important;

  $themes:(
    "clasico":(
      border:none,
      padding-top: 2px,
    ),
    "alto-contraste":(
      border: 1px solid var(--onsecondary-color),
      padding-top: 0,
    )
  );

 @include ThemesProperties($themes);
} 

.interactiveCovers-container {
  background-color: var(--primary-color);
  .cover {
    img {
      width: 100%;
    }
  }
}
.ilustratedCovers-container {
  $themes:(
    "clasico":(
      background-color: #d2d9f7
    ),
    "alto-contraste":(
      background-color: var(--surface-color)
    )
  );
  @include ThemesProperties($themes);
}

.home-banners {
  .lazy-image{
    align-items: center;
    img {    
      border-radius: 10px;
    }
  }
  // margin-top: 118px;
  background-color: var(--surface-color);
  .swiper-slide {
    width: 80%;
  }
 
}

.home-stories {
  .swiper-slide {
    max-width: 150px;
    width: 80%;
  }
  img {
    width: 100%;
  }
}

.main-covers-2 {
  .swiper-slide {
    max-width: 200px;
    width: 80%;
  }
}

.highlight-container-2 {
  .cover {
    img {
      width: 100%;
      max-width: initial;
      margin: 0 auto;
      display: block;
      border-radius: 0px;
    }
  }
}

.contenedor-caracteristicas {
  a{
    &:hover {
      transition: all .5s;
      transform: scale(1.1);
    }  
  }

  p {
    color: var(--onbackground-primary);
    font-size: 0.8em;
  }
  img {
    max-width: 80px;
  }
  .skeleton {
    width: 90%;
    height: 60px;
    margin: auto;
  }
}

.home-collections {
  .swiper-slide {
    width: 50%;
  }
  img {
    width: 100%;
  }
}

.cookies-popup {
  opacity: 0;
  border-radius: 5px 5px 0px 0px;
  background-color: white;
  box-shadow: 0 1px 15px #9f9f9f;
  color: black;
  z-index: 999;
  bottom: 0;
  right: 0;
  position: fixed;
  font-size: 0.85em;
}

.download-app-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  margin: 0;
  border-radius: 0 !important;
  padding: 0 !important;

  text-align: center;
  color: #fff;
  z-index: 100;
  transition: transform 0.3s;

  $themes:(
    "alto-contraste":(
      border:solid 2px var(--onbackground-primary),
      color:var(--onbackground-primary),
    )
  );
  @include ThemesProperties($themes);

  img {
    max-width: 60px;
    margin: 0.5rem;
    border: solid 3px #fff;
    border-radius: 99999px;
  }
  p {
    font-weight: 700;
    line-height: 1.1em;
    font-size: 0.8em;
    padding: 0.5rem;
  }
  &:hover {
    color: #fff;
    transform: scale(1.05);
  }
}

.label {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

@media only screen and (min-width: 526px) {
  .home-banners {
    .swiper-slide {
      width: 70%;
    }
  }
  .home-collections {
    .swiper-slide {
      width: 55%;
    }
  }
}

@media only screen and (min-width: 576px) {
  .interactiveCovers-container {
    .cover {
      img {
        /*max-width: 220px;*/
      }
    }
  }
  .download-app-button {
    width: fit-content;
    margin: 0.5rem;
    padding: 1rem !important;
    border-radius: 1rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .home-collections {
    .swiper-slide {
      width: 25%;
    }
  }
  .home-banners {
    .swiper-slide {
      width: 55%;
    }
  }
}

@media only screen and (min-width: 992px) {
  // .home-banners {
  //   margin-top: 104px;
  // }

  .demo--instruction {
    p {
      text-align: left;
    }
  }

  .contenedor-caracteristicas {
    p {
      font-size: 1em;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .home-collections {
    .swiper-slide {
      width: 25%;
    }
  }
  .home-banners {
    .swiper-slide {
      width: 45%;
    }
  }
}

@media only screen and (min-width: 1366px) {
  .home-banners {
    .swiper-slide {
      width: 35%;
    }
  }
}

@media only screen and (min-width: 1500px) {
  .home-collections {
    .swiper-slide {
      width: 20%;
    }
    .cover {
      img {
        max-width: initial;
      }
    }
  }

  .home-stories {
    .swiper-slide {
      max-width: 250px;
    }
  }

  .highlight-container {
    .cover {
      img {
        max-width: initial;
      }
    }
  }
}

// new home

.home-swiper-navigation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--onbackground-primary);
  color: var(--surface-color);
  cursor: pointer;
  transition: color .3s, background-color .3s;

  &:hover {
    background-color: var(--secondary-inverse);
    color: var(--onsecondary-inverse);
  }

  &:disabled{
    background-color: var(--disabled-color);
    color: var(--ondisabled-color);
    border: solid 2px var(--disabled-color);
    cursor:auto;
    opacity: .5;
  }
}

.home-row{
  &-yellow{
    background-color: var(--primary-color);
  }
  &-purple{
    background-color: var(--secondary-light-color);
  }
}

.home-collection{
  transition: transform .3s;
  :hover{
    transform: translateY(-2.5%);
  }
}
