/*===========================================================================================
  plector-home

  En este archivo se encuentran algunas clases que se usan en el login y en la pagina de barra-compra

  >**NOTA:** se debe revisar la mayoría de clases ya que aparentemente no se usan. 
 ============================================================================================ */

.pl-home {
  background: rgba(102, 102, 204, 0.9) url("/img/background.png") center/ cover fixed;
  background-blend-mode: multiply;
  font-family: $secondary-font;
  .title {
    background: url("/img/logo-makemake-plector.png") center/ contain;
    width: 212px;
    height: 179px;
    text-indent: -10000px;
  }
  .section-title {
    font-size: 2em;
    color: #fff;
  }
  .nav-menu {
    li {
      background-color: var(--onbackground-fourth);
      border: none;
    }
  }
  .nav-menu--active {
    background-color: var(--primary-color) !important;
    color: var(--onbackground-primary) !important;
  }
  .nav-menu--responsive {
    background-color: var(--primary-color) !important;
    color: var(--onbackground-primary) !important;
  }
}

.pl-selectuser {
  background-color: var(--surface-color);
  border-radius: 5px;
  .list-users {
    li {
      cursor: pointer;
      border-radius: 5px;
      transition: 0.2s ease-in;
      border: 0px solid var(--background-color);
      &:hover {
        border: 2px solid var(--background-color);
      }
      p {
        background-size: 70px 70px;
        background-repeat: no-repeat;
        background-position-y: center;
        font-size: 1.2em;
        font-weight: 600;
        padding: 16px 0px 16px 84px;
      }
      &:nth-child(1) {
        p {
          background-image: url("/img/avatares/avatar11.png");
        }
      }
      &:nth-child(2) {
        p {
          background-image: url("/img/avatares/avatar2.png");
        }
      }
      &:nth-child(3) {
        p {
          background-image: url("/img/avatares/avatar1.png");
        }
      }
    }
  }
}

.login-users {
  overflow: hidden;
  white-space: nowrap;
  .login-section {
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
  p,
  h2 {
    white-space: normal;
  }
}

.pl-login-users-container {
  position: relative;
  left: 0;
  transition: 0.2s ease-out;
  left: 0;
}

.header-div {
  width: 100%;
  height: 60px;
}

.secondary-link {
  font-size: 0.88em;
  font-weight: 600;
  a {
    color: var(--onbackground-secondary);
    border-bottom: 1px solid var(--onbackground-fourth);
  }
}

.text-align-right {
  text-align: right;
}

.form-codigo-estudiante {
  .tooltip {
    position: absolute;
    left: -5%;
    bottom: 140%;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease-in;
    // background: transparentize($color: #000000, $amount: 0.1);
    border-radius: 5px;
    color: #fff;
    max-width: 300px;
    p {
      white-space: normal;
      font-size: 0.8em;
      line-height: 1.2;
    }
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 10%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #000 transparent transparent transparent;
    }
  }
}

.question-sign {
  cursor: pointer;
  font-weight: 600;
  top: -10px;
  right: -10px;
  &:hover {
    + .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

// .question-container{
//   &:hover{
//     + .tooltip{
//       visibility: visible;
//       opacity: 1;
//     }
//   }
// }

.white-space {
  width: 100%;
  height: 57px;
}

.dashboard-profesor {
  padding-top: 1rem !important;
}

@media only screen and (min-width: 768px) {
  .pl-home {
    .title {
      width: 271px;
      height: 227px;
    }
  }
}

@media only screen and (min-height: 720px) {
  .pl-home {
    min-height: 900px;
  }
}

@media only screen and (min-height: 1200px) {
  .pl-home {
    min-height: 1300px;
  }
}
