/*=====================================================
  DEMO
  En este archivo se encuentran los estilos de las 
  diferentes pantallas de demo.

  >**NOTA:** las clases no documentadas de este archivo
   se deben revisar para ver si se usan en el proyecto.
  De no usarse, se deben borrar.
 ====================================================== */

.mm-demo-comprar {
  background-color: #6867c4;
  $themes: (
    "alto-contraste":(background-color:var(--surface-color),
    )
  );
@include ThemesProperties($themes);
}

.mm-demo-comprar1 {
  background-color: #492f92;
  $themes: (
    "alto-contraste":(background-color:var(--surface-color),
    )
  );
@include ThemesProperties($themes);
}

.comprar-ficha {
  font-size: 1.13em;

  h2 {
    color: #000;
    border-bottom: none;
    letter-spacing: 1px;
  }

  .pl-content-container {
    $themes: (
      "alto-contraste":(color:var(--onbackground-primary),
        background-color:var(--surface-color),
        border: solid 2px var(--onbackground-primary),
      )
    );
  @include ThemesProperties($themes);
}

.academia-logo {
  width: 60%;
  $themes: (
    "alto-contraste":(background-color:var(--onbackground-primary),
    )
  );
@include ThemesProperties($themes);
}
}

.comprar-info {
  background-color: var(--background-color);
}

.demo-comprar--header {
  position: relative;
  /*  display: flex; */
  // background: transparentize($color: var(--primary-color), $amount: 0.3);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 0;

  img {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100%;
    background-color: var(--primary-color);
    opacity: 0.7;
    z-index: -1;
  }
}

.btn-compra {
  background-color: var(--green-color);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  text-align: center;

  p {
    text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 1.3em;
    $themes: (
      "alto-contraste":(color:var(--onbackground-primary),
        border:solid 2px var(--onbackground-primary),
      )
    );
  @include ThemesProperties($themes);
}
}

.mm-comprar-list {
  padding-left: 20px;

  li {
    text-indent: -7px;
  }

  a {
    font-weight: 700;
    text-decoration: underline;
  }
}

.text-exclusivo {
  text-align: center;
  color: #fff;
}

.mas-info {
  width: 100%;
  font-family: $font-family-sans-serif;
  color: #fff;
  font-size: 0.9em;

  .info-semibold {
    font-weight: 500;
  }
}

.caja-mas-info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  $themes: (
    "clasico":(background-color: var(--secondary-inverse))
  );
@include ThemesProperties($themes);

.info {
  width: 130px;
  height: 50px;
}
}

.select-plans-prices {
  font-weight: 600;

  .selected {
    background-color: #c2e1a9;
    /* transform: scale(1.02); */
    position: relative;

    &::before {
      content: "\f058";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      right: 5px;
      font-size: 1.4em;
      top: 5px;
      color: var(--red-color);
      $themes: (
        "alto-contraste":(color: var(--onbackground-primary),
        )
      );
    @include ThemesProperties($themes);
  }
}
}

.info-item {
  font-size: 0.8em;
}

.select-price {
  background-color: #e0f0d4;
  transition: 0.2s ease-in;
  cursor: pointer;
  $themes: (
    "alto-contraste":(background-color:var(--surface-color),
      color: var(--onbackground-primary),
      border:solid 2px var(--onbackground-primary),
    )
  );
@include ThemesProperties($themes);

&:hover {
  transform: scale(1.02);
}
}

.select-price-blue {
  .select-price {
    background-color: #cae5f9;
  }

  .selected {
    background-color: #9fd4fa;
  }
}

.btn-compra-blue {
  background-color: #3fa9f5;
}

.btn-compra-red {
  background-color: var(--red-color);
}

.select-price-red {

  .select-price,
  .info {
    background-color: #ffcdd1;
  }

  .selected {
    background-color: #ff9ca4 !important;
  }
}

.select-price-orange {

  .select-price,
  .info {
    background-color: #ffcccc;
  }

  .selected {
    background-color: #ff9999 !important;
  }
}

.btn-compra-orange {
  background-color: #ff6633;
}

.compra-yolanda {
  .main-content {
    max-width: 1000px;
  }


  .btn-compra {
    width: 100%;
    border-radius: 10px;
  }
}

.select-price-big-box {
  font-weight: 200;

  .select-price {
    width: 100%;
  }

  .text-descripcion {
    font-size: 0.8em;
  }
}

.transaccion-status {
  border: 4px solid var(--green-color);
  border-radius: 5px;
  // background-color: transparentize($color: var(--green-color), $amount: 0.6);
  font-weight: 600;
}

.transaccion-rejected {
  border: 4px solid var(--red-color);
  // background-color: transparentize($color: var(--red-color), $amount: 0.6);
}

.transaccion-pending {
  border: 4px solid var(--primary-color);
  // background-color: transparentize($color: var(--primary-color), $amount: 0.6);
}

.sm-link {
  text-transform: uppercase;
  font-size: 0.8em;
}

.btn-escoger-comprar {
  font-size: 0.8em;
  width: 235px;
  font-weight: 600;
  color: #fff;
  text-align: left;
  background-color: var(--green-color);

  &:hover {
    color: #fff;
  }
}

.comprar-container {
  display: inline-block;
  transition: 0.2s ease-in;

  &:hover {
    transform: scale(1.02);
  }
}

.btn-escoger-institucional {
  background-color: var(--secondary-color);
}

.img-comprar {
  width: 100px;
  position: absolute;
  top: -30px;
  right: -50px;
}

.planes {
  // display: none;
}

.compra-informacion-institucional-form {
  $themes: (
    "alto-contraste":(background-color:var(--surface-color))
  );
@include ThemesProperties($themes);

.input-error {
  border-color: var(--red-inverse) !important;
  border-width: 2px !important;
}


.validacion-campo-error {
  color: var(--onsecondary-color);
  font-size: 0.8em;
  font-weight: 900;
  padding-top: 0.3rem;
  line-height: 0.9em !important;
  font-family: var(--secondary-font);
}

.btn-solicitar{
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 30px;
  color: var(--onprimary-inverse);
  background-color: var(--primary-inverse);
  border-radius: 5px;
  font-family: var(--primary-font);
  font-weight: 600;

  &:hover{
      transition: all .2s;
      background-color: var(--onsecondary-color);
      color: var(--onprimary-inverse);
      border: 1px solid var(--onprimary-inverse);
  }

  &:disabled{
    cursor: auto;
    background: var(--secondary-light-inverse);
    color: var(--onsecondary-light-inverse);

    $themes:(
      "alto-contraste": (
       background-color: var(--disabled-color),
       color: var(--ondisabled-color)
      )
    );
    @include ThemesProperties($themes);

    &:hover{
      background-color: var(--secondary-light-inverse);
      color: var(--onsecondary-light-inverse);
      border: none !important;
    }
   
  }
}

input,
select {
  font-size: .9em;
  font-weight: 600;

  &::placeholder {
    color: #6A6969;
  }

  $themes: (
    "alto-contraste":(background-color:var(--surface-color),
      border:solid 2px var(--onbackground-primary),
      color: var(--onbackground-primary))
  );
@include ThemesProperties($themes);
}
}

.notificacion-form {
  color: var(--red-inverse);
  font-weight: bold;
  line-height: 0.5 !important;
  font-size: 0.8em;
}


@media only screen and (min-width: 526px) {
  .btn-escoger-comprar {
    font-size: 1em;
    width: 280px;
  }
}

@media only screen and (min-width: 992px) {
  .compra-yolanda {
    .btn-compra {
      width: 30%;
    }
  }

  .select-plans-prices {
    .selected {
      transform: scale(1.02);
    }
  }

  .select-price-big-box {
    .select-price {

      &:nth-child(1),
      &:nth-child(2) {
        width: 48%;
        margin-bottom: 25px;
      }
    }
  }

  .mas-info {
    width: 67%;
  }

  .caja-mas-info {
    width: 38%;
    border-right: 10px solid #fff;
  }

  .academia-logo {
    width: 100%;
  }

  .demo-comprar--header {
    max-height: 365px;
  }
}

.contenedor-altura-minima {
  min-height: 800px;
}