/*OpenDyslexic-alta-Regular*/
@font-face {
  font-family: 'OpenDyslexic';
  font-style: normal;
  font-weight: 300;
  src: local(''), 
    url('/fonts/openDyslexic/OpenDyslexicAlta-Regular.otf') format('opentype');
}
/*OpenDyslexic-Regular*/
@font-face {
  font-family: 'OpenDyslexic';
  font-style: normal;
  font-weight: 400;
  src: local(''), 
    url('/fonts/openDyslexic/OpenDyslexic-Regular.otf') format('opentype');
}
/*OpenDyslexic-Bold*/
@font-face {
  font-family: 'OpenDyslexic';
  font-style: normal;
  font-weight: 700;
  src: local(''), 
    url('/fonts/openDyslexic/OpenDyslexic-Bold.otf') format('opentype');
}
/*OpenDyslexic-BoldItalic*/
@font-face {
  font-family: 'OpenDyslexic';
  font-style: italic;
  font-weight: 700;
  src: local(''), 
    url('/fonts/openDyslexic/OpenDyslexic-BoldItalic.otf') format('opentype');
}
/*OpenDyslexic-Italic*/
@font-face {
  font-family: 'OpenDyslexic';
  font-style: italic;
  font-weight: 400;
  src:  local(''), 
    url('/fonts/openDyslexic/OpenDyslexic-Italic.otf') format('opentype');
}
